import { DraggableWSComp } from "@/components/DragAndDrop/";
import SampleBadge from "@/components/SampleBadge";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import CompLibraryFolderGroup from "@/views/Components/components/comp-library-folder-group";
import { useDndContext } from "@dnd-kit/core";
import Close from "@mui/icons-material/Close";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import Tooltip from "@shared/frontend/Tooltip";
import {
  RenderIfHasResourcePermission,
  userHasPermission,
  userHasResourcePermission,
} from "@shared/frontend/userPermissionContext";
import { COMPONENT_LIBRARY_COMPONENTS_PER_PAGE } from "@shared/lib/components";
import * as SegmentEvents from "@shared/segment-event-names";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Masonry from "react-masonry-css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ExplainMovie from "../../assets/create-comp-suggestions-plugin-5.mp4";
import spinner from "../../assets/small-spinner.gif";
import { useWorkspaceFeatureEnabled } from "../../flagged-feature";
import useSegment from "../../hooks/useSegment";
import CompLibraryGroup from "../../views/Components/components/comp-library-group";
import { useComponentNotInGroupDroppable } from "../DragAndDrop/lib";
import EditableName from "../EditableName";
import NewComponentFolderModal from "../NewComponentFolderModal";
import { PaginationController } from "../PaginationController";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import ComponentLimitBar from "../account-billing/ComponentLimitBar";
import ApiID from "../api-id";
import ButtonPrimary from "../button/buttonprimary";
import CommonFolderCard from "../card/CommonFolderCard";
import NewGroupCard from "../card/NewGroupCard";
import NewFolderCard from "../card/newFolderCard";
import Comp from "../comp";
import PermissionRequiredComponent from "../permissions/PermissionRequired/PermissionRequiredComponent";
import PermissionRequiredComponentFolder from "../permissions/PermissionRequired/PermissionRequiredComponentFolder";
import SearchAndFilters from "../searchAndFilters/searchAndFilters";
import ModalBase from "../shared/ModalBase";
import { ComponentCount } from "./ComponentCount";
import ComponentsEmptyState from "./ComponentsEmptyState";
import style from "./style.module.css";

// These represent all of the **mutually exclusive** UI states that the component
// library needs to switch between. There are lots of other bits of UI state that
// depend on some of the same variables, but these are the ones that should never
// be true at the same time.
const DISPLAY_STATES = {
  GETTING_STARTED: "GETTING_STARTED",
  NO_SEARCH_RESULTS: "NO_SEARCH_RESULTS",
  LIBRARY: "LIBRARY",
  LOADING: "LOADING",
  EMPTY_FOLDER: "EMPTY_FOLDER",
};

// THIS IS FOR WORKSPACE COMPONENTS
const CompResults = ({
  comps,
  componentsByFolder,
  someComponentsExist,
  displayApiIds,
  nestedComps,
  isSelected,
  onComponentClick,
  selectComp,
  isLoading,
  handleSelectTag,
  handleClearSelectedTags,
  queryDebounce,
  handleQueryChange,
  handleInitialQueryChange,
  handleCreateBlock,
  handleRenameBlock,
  handleDeleteBlock,
  query,
  setQuery,
  assignee,
  handleSetAssignee,
  variantFilter,
  setVariantFilter,
  status,
  chooseStatus,
  setCommentState,
  setPanelState,
  addNewComponentGroup,
  updateNewComponentGroup,
  updateEmptyComponentGroup,
  updateExistingComponentGroup,
  showNewGroupButton,
  componentFolders,
  showComponentFolderModal,
  openComponentFolderModal,
  closeComponentFolderModal,
  handleCreateComponentFolder,
  onFolderClick,
  showDeleteFolderModal,
  selectedFolder,
  handleRenameComponentFolder,
  handleUpdateComponentFolderApiId,
  devIDFilter,
  setDevIDFilter,
  isSearching,
  openWsCompImportModal,
  openDraftingModal,
  selectedComps,
  tagState,
  pageNumber,
  totalPages,
  totalItems,
  onPaginationPageChange,
}) => {
  const componentCountLowerBound = pageNumber * COMPONENT_LIBRARY_COMPONENTS_PER_PAGE + 1;
  const componentCountUpperBound = pageNumber * COMPONENT_LIBRARY_COMPONENTS_PER_PAGE + comps.length;
  const componentCountTotal = totalItems;

  const paginationContainerRef = useRef(null);
  const segment = useSegment();

  const masonryBreakpoints = {
    default: 4,
    2250: 3,
    1650: 2,
    1250: 1,
  };
  const [allGroupNames, setAllGroupNames] = useState([]);
  const [newGroupBeingCreated, setNewComponentGroupBeingCreated] = useState(null);
  const [notInGroupComponents, setNotInGroupComponents] = useState(null);
  const [editingFolderName, setEditingFolderName] = useState(false);
  const [showExplainModal, setShowExplainModal] = useState(false);
  const { user } = useAuthenticatedAuth();

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const isRootPath = location.pathname === "/components";

  const inFolder = selectedFolder !== null && selectedFolder !== undefined;
  const someComponentsVisible = comps.length > 0 || Object.values(componentsByFolder).some((v) => v.length > 0);

  const InvalidPermissionsComponent =
    params.id && !params.folder_id ? PermissionRequiredComponent : PermissionRequiredComponentFolder;

  const isEditEnabled = userHasResourcePermission("component_folder:edit");

  function getDisplayState() {
    if (isLoading) {
      return DISPLAY_STATES.LOADING;
    }

    if (comps && !someComponentsVisible && isSearching) {
      return DISPLAY_STATES.NO_SEARCH_RESULTS;
    }

    if (inFolder && !someComponentsVisible && !isSearching) {
      return DISPLAY_STATES.EMPTY_FOLDER;
    }

    if (someComponentsVisible || componentFolders.filter((f) => !f.isSample).length) {
      return DISPLAY_STATES.LIBRARY;
    }

    return DISPLAY_STATES.GETTING_STARTED;
  }
  const displayState = getDisplayState();

  const folderNames = useMemo(() => {
    return componentFolders.map((folder) => folder.name);
  }, [componentFolders]);

  const componentFoldersAllowed = useWorkspaceFeatureEnabled("component-folders");

  const canCreateFolders = userHasPermission("component_folder:create");

  const showCreateFolderButton = canCreateFolders && !isSearching;
  const createFolderButtonEnabled = componentFoldersAllowed;
  const showFolderSection = !inFolder && !!(componentFoldersAllowed || componentFolders.length);

  const openSampleComponentFolder = () => {
    const sampleFolder = componentFolders.find((f) => f.isSample);
    if (sampleFolder) {
      onFolderClick(sampleFolder._id);
    }
  };

  useEffect(() => {
    if (nestedComps) {
      const newCompGroup = nestedComps.find((c) => c.new_group === true);
      if (newCompGroup) {
        setNewComponentGroupBeingCreated(newCompGroup);
      } else {
        setNewComponentGroupBeingCreated(null);
      }
      const allNames = nestedComps
        .filter((nestedComp) => !nestedComp.new_group)
        .map((nestedComp) => nestedComp.group_name);
      setAllGroupNames(allNames);

      const notInGroup = nestedComps.find((nested) => nested.group_name === "_NOT_IN_GROUP");
      if (notInGroup) {
        setNotInGroupComponents(notInGroup);
      }
    }
  }, [nestedComps]);

  const { isOver, setNodeRef } = useComponentNotInGroupDroppable();

  // we only want to highlight the out-of-group area if the component we are dragging
  // is already in a group or a block.
  const { active, over } = useDndContext();
  const draggingCompIsInGroup = active?.data.current?.isInGroup;

  return (
    <div data-testid="ws-compresults" className={style.libraryContainer}>
      <div className={style.searchFilters}>
        <SearchAndFilters
          query={query}
          setQuery={setQuery}
          devID={devIDFilter}
          setDevID={setDevIDFilter}
          assignee={assignee}
          setAssignee={handleSetAssignee}
          onChange={handleQueryChange}
          onInitialChange={handleInitialQueryChange}
          variantFilter={variantFilter}
          variantFilterConfig={{
            type: "components",
            isSampleFolder: inFolder && selectedFolder.isSample,
          }}
          setVariantFilter={setVariantFilter}
          name={"your workspace"}
          loading={false}
          docSearch={false}
          tagState={tagState}
          status={status}
          chooseStatus={chooseStatus}
          clearSelectedTags={handleClearSelectedTags}
          selectTag={handleSelectTag}
          fullWidth={false}
          inheritWidth={true}
          customInputs={<></>}
          debounce={queryDebounce}
        />
      </div>
      {inFolder && (
        <FolderName
          isEditEnabled={isEditEnabled}
          displayApiIds={displayApiIds}
          editingFolderName={editingFolderName}
          setEditingFolderName={setEditingFolderName}
          selectedFolder={selectedFolder}
          folderNames={folderNames}
          handleRenameComponentFolder={handleRenameComponentFolder}
          handleUpdateComponentFolderApiId={handleUpdateComponentFolderApiId}
          showDeleteFolderModal={showDeleteFolderModal}
        />
      )}
      {displayState === DISPLAY_STATES.LOADING && (
        <div className={style.loading}>
          <SkeletonLoader format="page" />
        </div>
      )}
      <RenderIfHasResourcePermission
        permission={"component_folder:comment"}
        NoPermissionComponent={InvalidPermissionsComponent}
        override={isRootPath || selectedFolder?.isSample}
      >
        {displayState === DISPLAY_STATES.GETTING_STARTED && (
          <ComponentsEmptyState
            openWsCompImportModal={openWsCompImportModal}
            openDraftingModal={openDraftingModal}
            openExplainComponentsModal={() => setShowExplainModal(true)}
            openSampleComponentFolder={openSampleComponentFolder}
          />
        )}

        {displayState === DISPLAY_STATES.LIBRARY && (
          <div
            className={classnames(style.results, {
              [style.isOver]: isOver && draggingCompIsInGroup,
            })}
            ref={setNodeRef}
            id="projectContainer"
          >
            <div className={style.contentContainer}>
              <div className={classnames("container", style.container)}>
                {!selectedFolder?.isSample && <ComponentLimitBar />}
                <div className={classnames("row", style.row)}>
                  <div
                    className="col-12"
                    style={{
                      paddingBottom: totalPages > 1 ? "70px" : 0,
                    }}
                  >
                    {showFolderSection && (
                      <>
                        <div className={style.folderSection}>
                          {!isSearching && <h2>Folders</h2>}
                          {isSearching && Object.keys(componentsByFolder).length > 0 && <h2>Folders</h2>}
                          <div className={classnames("row", style.allProjects)}>
                            {showCreateFolderButton && (
                              <NewFolderCard
                                responsiveSize={"large"}
                                disabled={!createFolderButtonEnabled}
                                openModal={openComponentFolderModal}
                                learnMoreLink="https://www.dittowords.com/docs/component-folders"
                                onUpsellClicked={() =>
                                  segment.track({
                                    event: SegmentEvents.UPSELL_CLICKED,
                                    properties: {
                                      type: "Folders",
                                      location: "Component Library",
                                      workspaceId: user?.workspaceId,
                                    },
                                  })
                                }
                              />
                            )}
                            {!isSearching &&
                              componentFolders.map((folder, index) => (
                                <CommonFolderCard
                                  responsiveSize={"large"}
                                  key={index}
                                  folder={folder}
                                  count={folder.component_ids.length}
                                  content="component"
                                  onClick={() => onFolderClick(folder._id)}
                                />
                              ))}
                            {isSearching &&
                              Object.keys(componentsByFolder).map((folder_id, index) => {
                                const folder = componentFolders.find((folder) => folder._id === folder_id);
                                const components = componentsByFolder[folder_id];
                                if (components.length !== 0 && folder) {
                                  return (
                                    <CompLibraryFolderGroup
                                      key={index}
                                      folder={folder}
                                      components={components}
                                      selectComponent={selectComp}
                                      isComponentSelected={isSelected}
                                      setCommentState={setCommentState}
                                      setPanelState={setPanelState}
                                      displayApiIds={displayApiIds}
                                    />
                                  );
                                }
                              })}
                          </div>
                        </div>
                        {someComponentsVisible && (
                          <div className={style.componentHeader}>
                            <h2>Components</h2>
                            <div className={style.separator} />
                            <ComponentCount
                              className={style.count}
                              lowerBound={componentCountLowerBound}
                              upperBound={componentCountUpperBound}
                              total={componentCountTotal}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {inFolder && selectedFolder?.isSample && (
                      <div className={style.sampleBanner}>
                        <div className={style.sampleBannerTitle}>
                          You’re viewing <SampleBadge className={style.sampleBadge}>sample</SampleBadge> Ditto
                          components.
                        </div>
                        <div className={style.sampleBannerText}>
                          You won’t be able to use any of these components in your projects, but when you’re ready, you
                          can create your own.{" "}
                          <a
                            className={style.sampleBannerLink}
                            href="https://dittowords.com/docs/components-in-ditto"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn more about components
                          </a>
                        </div>
                      </div>
                    )}

                    {isEditEnabled && someComponentsVisible && showNewGroupButton && !newGroupBeingCreated && (
                      <NewGroupCard text="Create new component group" action={() => addNewComponentGroup()} />
                    )}

                    {newGroupBeingCreated && (
                      <CompLibraryGroup
                        hasTagsSelected={tagState.selected.size > 0}
                        key={9999999}
                        index={9999999}
                        displayApiIds={displayApiIds}
                        group={newGroupBeingCreated}
                        isSelected={isSelected}
                        handleCreateBlock={handleCreateBlock}
                        handleRenameBlock={handleRenameBlock}
                        handleDeleteBlock={handleDeleteBlock}
                        query={query}
                        updateNewComponentGroup={updateNewComponentGroup}
                        updateEmptyComponentGroup={updateEmptyComponentGroup}
                        allGroupNames={allGroupNames}
                        updateExistingComponentGroup={updateExistingComponentGroup}
                        selectedComps={selectedComps}
                      />
                    )}

                    {nestedComps &&
                      nestedComps.length > 0 &&
                      nestedComps.map((group, index) => {
                        if (
                          group.group_name !== "_NOT_IN_GROUP" &&
                          (group.blocks.length > 0 || group.other_text.length > 0)
                        ) {
                          return (
                            <CompLibraryGroup
                              key={newGroupBeingCreated ? index - 1 : index}
                              index={newGroupBeingCreated ? index - 1 : index}
                              displayApiIds={displayApiIds}
                              group={group}
                              onComponentClick={onComponentClick}
                              selectComp={selectComp}
                              isSelected={isSelected}
                              handleCreateBlock={handleCreateBlock}
                              handleRenameBlock={handleRenameBlock}
                              handleDeleteBlock={handleDeleteBlock}
                              query={query}
                              hasTagsSelected={tagState.selected.size > 0}
                              setCommentState={setCommentState}
                              setPanelState={setPanelState}
                              allGroupNames={allGroupNames}
                              updateNewComponentGroup={updateNewComponentGroup}
                              updateEmptyComponentGroup={updateEmptyComponentGroup}
                              updateExistingComponentGroup={updateExistingComponentGroup}
                              selectedComps={selectedComps}
                            />
                          );
                        }
                      })}

                    {comps?.length > 0 && (
                      <Masonry
                        breakpointCols={masonryBreakpoints}
                        className={style.masonryGrid}
                        columnClassName={style.masonryGridCol}
                      >
                        {notInGroupComponents &&
                          notInGroupComponents.other_text.map((component, compIndex) => {
                            if (component.instances.length > 0 && component.instances[0]) {
                              component.instances[0].comment_threads = component.comment_threads;
                              return (
                                <DraggableWSComp
                                  id={component._id}
                                  key={component._id}
                                  name={component.name}
                                  selectedComps={selectedComps}
                                >
                                  <Comp
                                    apiID={component.apiID}
                                    displayApiIds={displayApiIds}
                                    isDraft={component.isDraft}
                                    component={component.instances[0]}
                                    compType={component.type}
                                    is_collapsed={false}
                                    onClick={onComponentClick}
                                    selectComp={() => selectComp(component)}
                                    ws_comp_id={component._id}
                                    is_selected={isSelected(component._id)}
                                    is_ws_comp={true}
                                    compName={component.name}
                                    setCommentState={setCommentState}
                                    setPanelState={setPanelState}
                                    showAllStatuses
                                  />
                                </DraggableWSComp>
                              );
                            }
                          })}
                      </Masonry>
                    )}

                    {!nestedComps && (
                      <div className={style.loadMore}>
                        <div>Fetching</div>
                        <img className={style.loading} src={spinner} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {displayState === DISPLAY_STATES.NO_SEARCH_RESULTS && (
          <>
            {!selectedFolder?.isSample && <ComponentLimitBar />}
            <div className={style.noResults}>
              {
                "Sorry, we couldn't find any results for your search! Try adjusting your search, loosening your filters, or searching inside your component folders."
              }
            </div>
          </>
        )}

        {displayState === DISPLAY_STATES.EMPTY_FOLDER && (
          <div className={style.container}>
            <div className={style.helpText}>
              <h2>No components in folder</h2>
              <p>
                Storing your components in{" "}
                <a href="https://www.dittowords.com/docs/component-folders" target="_blank">
                  folders
                </a>{" "}
                can help keep your component library organized and ensure you're able to quickly find the text you need.
              </p>
              <br />
              <p>You can add components to this folder by:</p>
              <ul>
                <li>Clicking the "New Component" button on the top right</li>
                <li>Dragging and dropping components from the library into this folder</li>
                <li>Selecting components and clicking the "Move to folder" button</li>
              </ul>
            </div>
          </div>
        )}

        {showComponentFolderModal && (
          <NewComponentFolderModal
            onHide={closeComponentFolderModal}
            folderNames={componentFolders.map((folder) => folder.name)}
            onCreateNewFolder={handleCreateComponentFolder}
          />
        )}

        {showExplainModal && (
          <ModalBase onHide={() => setShowExplainModal(false)}>
            <ModalHeader>
              <ModalTitle className={style.explainModalTitle}>
                Create components from text in your designs{" "}
                <Close className={style.closeIcon} onClick={() => setShowExplainModal(false)} />
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div className={style.explainModalBody}>
                <video className={style.explainerGif} src={ExplainMovie} autoPlay loop />
                <p>Easily turn the text in your mockups into reusable components.</p>
                <p>In any project, Ditto will automatically identify and suggest text for you to componentize!</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className={style.bottomRow}>
                <p>
                  <a href="https://www.dittowords.com/docs/component-suggestions" target="_blank">
                    Learn more
                  </a>
                </p>
                <ButtonPrimary text="Go to projects ->" onClick={() => history.push("/projects")} />
              </div>
            </ModalFooter>
          </ModalBase>
        )}
      </RenderIfHasResourcePermission>
      <PaginationController
        style={{
          marginLeft: paginationContainerRef.current ? -(paginationContainerRef.current.offsetWidth / 2) : 0,
        }}
        className={style.paginationController}
        containerRef={paginationContainerRef}
        pageCount={totalPages}
        pageIndex={pageNumber ?? 0}
        onPageChange={onPaginationPageChange}
        disabled={isLoading}
      />
    </div>
  );
};

export default CompResults;

CompResults.propTypes = {
  comps: PropTypes.arrayOf(PropTypes.object),
  displayApiIds: PropTypes.bool,
  nestedComps: PropTypes.arrayOf(PropTypes.object),
  isSelected: PropTypes.func,
  selectComp: PropTypes.func,
  handleSelectTag: PropTypes.func,
  handleClearSelectedTags: PropTypes.func,
  handleQueryChange: PropTypes.func,
  handleCreateBlock: PropTypes.func,
  handleRenameBlock: PropTypes.func,
  handleDeleteBlock: PropTypes.func,
  handleDragEnd: PropTypes.func,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  setCommentState: PropTypes.func,
  setPanelState: PropTypes.func,
  addNewComponentGroup: PropTypes.func,
  updateNewComponentGroup: PropTypes.func,
  updateEmptyComponentGroup: PropTypes.func,
  updateExistingComponentGroup: PropTypes.func,
  hideComponentHelp: PropTypes.bool,
  setHideComponentHelp: PropTypes.func,
};

const FolderName = ({
  isEditEnabled,
  displayApiIds,
  editingFolderName,
  setEditingFolderName,
  selectedFolder,
  folderNames,
  handleRenameComponentFolder,
  handleUpdateComponentFolderApiId,
  showDeleteFolderModal,
}) => {
  return (
    <div className={style.componentFolderTitle} data-testid="component-folder-title">
      <FolderOpenOutlined className={style.icon} />
      <EditableName
        isEditEnabled={isEditEnabled && !selectedFolder?.isSample}
        className={style.folderName}
        hoverWrapperClassName={style.folderNameHoverWrapper}
        value={selectedFolder.name}
        onSave={(name) => {
          handleRenameComponentFolder(name, selectedFolder._id);
          setEditingFolderName(false);
        }}
        editModeEnabled={[editingFolderName, setEditingFolderName]}
        uniqueNames={folderNames}
      />
      {displayApiIds && (
        <ApiID
          id={selectedFolder.apiID}
          canEdit={isEditEnabled}
          onEdit={(value) => handleUpdateComponentFolderApiId(selectedFolder._id, value)}
          projectLevel={false}
        />
      )}
      {isEditEnabled && (
        <Dropdown>
          <Dropdown.Toggle>
            <MoreVert className={style.more_icon} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Tooltip
              hideOnClick={false}
              disabled={!selectedFolder.isSample}
              className={style.tooltip}
              content={<div className={style.body}>Renaming is disabled for sample data.</div>}
              placement="top"
              theme="dark"
            >
              <div>
                <Dropdown.Item disabled={selectedFolder.isSample} onClick={() => setEditingFolderName(true)}>
                  Rename Folder
                </Dropdown.Item>
              </div>
            </Tooltip>
            <Tooltip
              hideOnClick={false}
              disabled={!selectedFolder.isSample}
              className={style.tooltip}
              theme="dark"
              content={<div className={style.body}>Deleting is disabled for sample data.</div>}
              placement="top"
            >
              <div>
                <Dropdown.Item disabled={selectedFolder.isSample} onClick={showDeleteFolderModal}>
                  Delete Folder
                </Dropdown.Item>
              </div>
            </Tooltip>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};
