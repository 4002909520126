import { handleLibraryComponentFolderCreatedActionAtom } from "@/stores/ComponentFolders";
import { handleLibraryComponentCreatedActionAtom, handleLibraryComponentsUpdatedActionAtom } from "@/stores/Components";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { useSetAtom } from "jotai";

/**
 * This should be rendered near the root level of the web app since library component / component folder
 * information is shared across the entire front-end.
 */
export default function LibraryDittoEventsHandler() {
  const handleLibraryComponentsUpdated = useSetAtom(handleLibraryComponentsUpdatedActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentsUpdated, async function handleLibraryComponentUpdated(data) {
    handleLibraryComponentsUpdated(data);
  });

  const handleLibraryComponentCreatedAction = useSetAtom(handleLibraryComponentCreatedActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentCreated, async function handleLibraryComponentCreated(data) {
    handleLibraryComponentCreatedAction(data);
  });

  const handleLibraryComponentFolderCreated = useSetAtom(handleLibraryComponentFolderCreatedActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentFolderCreated, async function handleLibraryComponentCreated(data) {
    handleLibraryComponentFolderCreated({ newFolderId: data.folderId, newFolderParentId: data.parentFolderId });
  });

  return <></>;
}
