import { libraryComponentFoldersListAtom } from "@/stores/ComponentFolders";
import { PublishToLibrary, usePublishToLibrary } from "@ds/organisms/PublishToLibrary";
import React from "react";

import {
  componentNameAtom,
  publishComponentActionAtom,
  regenerateComponentNameActionAtom,
} from "@/stores/ComponentLinkingFlow";
import { clearLibraryInteractionViewActionAtom, derivedOnlySelectedTextItemAtom } from "@/stores/ProjectSelection";
import { useAtomValue, useSetAtom } from "jotai";
import style from "./style.module.css";

export default function PublishToLibraryWrapper() {
  const clearLibraryInteractionView = useSetAtom(clearLibraryInteractionViewActionAtom);
  const publishComponent = useSetAtom(publishComponentActionAtom);
  const textItem = useAtomValue(derivedOnlySelectedTextItemAtom);
  const regenerateComponentName = useSetAtom(regenerateComponentNameActionAtom);

  if (!textItem) {
    throw new Error("Cannot publish to library without a single text item selected");
  }

  const onCancel = () => {
    clearLibraryInteractionView();
  };

  const onPublish = async (data: { name: string; folderId: string | null }) => {
    publishComponent({
      name: data.name,
      textItemId: textItem._id,
      folderId: data.folderId ?? undefined,
    });

    clearLibraryInteractionView();
  };

  const onRegenerateComponentName = () => {
    regenerateComponentName(null);
  };

  const publishToLibraryProps = usePublishToLibrary({
    onCancel,
    onPublish,
    onRegenerateComponentName,
    textItem: {
      tags: textItem.tags,
      status: textItem.status,
      defaultValue: textItem.rich_text,
      notes: textItem.notes,
    },
    foldersAtom: libraryComponentFoldersListAtom,
    componentNameAtom,
  });

  return (
    <div className={style.wrapper}>
      <PublishToLibrary {...publishToLibraryProps} />
    </div>
  );
}
