import React from "react";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export function LibraryComponentLinkIcon(props: IProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <g fill="currentColor">
        <path
          d="M12.375 13.625C11.9688 13.625 11.6042 13.5078 11.2812 13.2734C10.9583 13.0391 10.7344 12.7396 10.6094 12.375H7.375C6.6875 12.375 6.09896 12.1302 5.60938 11.6406C5.11979 11.151 4.875 10.5625 4.875 9.875C4.875 9.1875 5.11979 8.59896 5.60938 8.10938C6.09896 7.61979 6.6875 7.375 7.375 7.375H8.625C8.96875 7.375 9.26302 7.2526 9.50781 7.00781C9.7526 6.76302 9.875 6.46875 9.875 6.125C9.875 5.78125 9.7526 5.48698 9.50781 5.24219C9.26302 4.9974 8.96875 4.875 8.625 4.875H5.39063C5.25521 5.23958 5.02865 5.53906 4.71094 5.77344C4.39323 6.00781 4.03125 6.125 3.625 6.125C3.10417 6.125 2.66146 5.94271 2.29688 5.57812C1.93229 5.21354 1.75 4.77083 1.75 4.25C1.75 3.72917 1.93229 3.28646 2.29688 2.92188C2.66146 2.55729 3.10417 2.375 3.625 2.375C4.03125 2.375 4.39323 2.49219 4.71094 2.72656C5.02865 2.96094 5.25521 3.26042 5.39063 3.625H8.625C9.3125 3.625 9.90104 3.86979 10.3906 4.35938C10.8802 4.84896 11.125 5.4375 11.125 6.125C11.125 6.8125 10.8802 7.40104 10.3906 7.89062C9.90104 8.38021 9.3125 8.625 8.625 8.625H7.375C7.03125 8.625 6.73698 8.7474 6.49219 8.99219C6.2474 9.23698 6.125 9.53125 6.125 9.875C6.125 10.2188 6.2474 10.513 6.49219 10.7578C6.73698 11.0026 7.03125 11.125 7.375 11.125H10.6094C10.7448 10.7604 10.9714 10.4609 11.2891 10.2266C11.6068 9.99219 11.9688 9.875 12.375 9.875C12.8958 9.875 13.3385 10.0573 13.7031 10.4219C14.0677 10.7865 14.25 11.2292 14.25 11.75C14.25 12.2708 14.0677 12.7135 13.7031 13.0781C13.3385 13.4427 12.8958 13.625 12.375 13.625ZM3.625 4.875C3.80208 4.875 3.95052 4.8151 4.07031 4.69531C4.1901 4.57552 4.25 4.42708 4.25 4.25C4.25 4.07292 4.1901 3.92448 4.07031 3.80469C3.95052 3.6849 3.80208 3.625 3.625 3.625C3.44792 3.625 3.29948 3.6849 3.17969 3.80469C3.0599 3.92448 3 4.07292 3 4.25C3 4.42708 3.0599 4.57552 3.17969 4.69531C3.29948 4.8151 3.44792 4.875 3.625 4.875Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default LibraryComponentLinkIcon;
