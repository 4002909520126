import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ITextItem } from "@shared/types/TextItem";
import classNames from "classnames";
import { atom } from "jotai";
import React, { Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import TextInput from "../../atoms/TextInput";
import ComponentFolderNavButton from "../../molecules/ComponentFolderNavButton";
import Scrollbar from "../../molecules/Scrollbar";
import TagSearchSuggestion from "../../molecules/TagSearchSuggestion";
import ComponentTextMatchSuggestions from "../ComponentTextMatchSuggestions";
import { ComponentList } from "./ComponentList";
import FiltersArea from "./FiltersArea";
import style from "./index.module.css";
import { IProps, useCompactTextList } from "./lib";

/**
 * Intended to be used alongside the `useCompactTextList` hook.
 *
 * Pass in all the atoms you've created for the component, spread the props, and it will
 * handle the rest.
 */
export function CompactLibraryComponentList(props: IProps<ILibraryComponent | ITextItem>) {
  return (
    <div
      style={props.style}
      className={classNames(style.compactLibraryComponentListWrapper, props.className)}
      data-testid="compact-library-component-list"
    >
      <TextInput
        inputRef={props.searchInputRef}
        autoFocus
        className={style.searchInput}
        placeholder="Search in the library..."
        value={props.searchQuery}
        onChange={props.onSearchQueryChange}
      />

      {props.queryTagMatches.length > 0 && (
        <div className={style.tagSuggestionWrapper}>
          <TagSearchSuggestion tags={props.queryTagMatches} onTagClick={props.handleTagClick} maxSuggestions={1} />
        </div>
      )}

      {props.suggestedComponentIds.length > 0 && props.listItemFamilyAtom && (
        <div className={style.suggestedComponentsWrapper}>
          <ComponentTextMatchSuggestions
            libraryComponentFamilyAtom={props.libraryComponentFamilyAtom}
            componentIds={props.suggestedComponentIds}
            listItemFamilyAtom={props.listItemFamilyAtom}
            usersByIdAtom={props.usersByIdAtom}
            selectedComponentId={props.selectedSuggestedComponentId}
            onComponentSelect={props.handleSuggestedComponentSelect}
            onComponentDeselect={props.handleSuggestedComponentDeselect}
            onComponentActionClick={props.onComponentActionClick}
          />
        </div>
      )}

      <FiltersArea
        enabledFilters={props.enabledFilters}
        selectedTags={props.selectedTags}
        setSelectedTags={props.setSelectedTags}
        tagsAtom={props.tagsAtom}
        selectedStatuses={props.selectedStatuses}
        setSelectedStatuses={props.setSelectedStatuses}
        selectedProjectId={props.selectedProjectId}
        setSelectedProjectId={props.setSelectedProjectId}
        projectsAtom={props.projectsAtom}
        inFolder={props.inFolder}
        selectedFilters={props.selectedFilters}
        handleClearFilters={props.handleClearFilters}
        handleBackFolderClick={props.handleBackFolderClick}
        headerText={props.headerText}
        formattedSelectedFilters={props.formattedSelectedFilters}
        handleSetSelectedFilters={props.handleSetSelectedFilters}
        handleRemoveFilter={props.handleRemoveFilter}
        title={props.title}
        handleTitleBackClick={props.handleTitleBackClick}
      />

      <Scrollbar className={style.scrollArea}>
        <div className={style.scrollContent}>
          {!props.inFolder && !props.isSearching && (
            <div className={style.folderList}>
              {props.folders.map((folder) => (
                <ComponentFolderNavButton
                  key={folder._id}
                  name={folder.name ?? "Folder"}
                  onClick={() => props.handleFolderClick(folder)}
                />
              ))}
            </div>
          )}
          <Suspense
            fallback={
              <div className={style.componentList}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
              </div>
            }
          >
            <ComponentList
              libraryComponentFamilyAtom={props.libraryComponentFamilyAtom}
              dragAndDropEnabled={props.dragAndDropEnabled}
              libraryComponentFoldersAtom={props.foldersListAtom}
              onSearchQueryChange={props.onSearchQueryChange}
              listItemFamilyAtom={props.listItemFamilyAtom}
              usersByIdAtom={props.usersByIdAtom}
              filteredComponentIdsAtom={props.filteredComponentIdsAtom}
              searchQuery={props.searchQuery}
              selectedComponentId={props.selectedComponentId}
              onComponentSelect={props.handleComponentSelect}
              onComponentDeselect={props.handleComponentDeselect}
              actionText={props.actionText}
              onComponentActionClick={props.onComponentActionClick}
              onLibraryLinkClick={props.handleLibraryLinkClick}
              isSearching={props.isSearching}
              isFiltering={props.isFiltering}
              handleClearFilters={props.handleClearFilters}
              inFolder={props.inFolder}
              showFolderLabels={props.showFolderLabels}
              originalRichText={props.originalRichText}
            />
          </Suspense>
        </div>
      </Scrollbar>
    </div>
  );
}

const fallbackAtoms = {
  projectsAtom: atom([]),
  tagsAtom: atom([]),
};

function CompactLibraryComponentListFallback() {
  return (
    <div className={style.compactLibraryComponentListWrapper}>
      <TextInput className={style.searchInput} placeholder="Search in the library..." value={""} onChange={() => {}} />

      <FiltersArea
        enabledFilters={["status", "tags", "usedInProject"]}
        inFolder={false}
        selectedFilters={[]}
        handleClearFilters={() => {}}
        handleBackFolderClick={() => {}}
        headerText={""}
        formattedSelectedFilters={[]}
        handleSetSelectedFilters={() => {}}
        selectedTags={[]}
        setSelectedTags={() => {}}
        selectedStatuses={[]}
        setSelectedStatuses={() => {}}
        selectedProjectId={null}
        setSelectedProjectId={() => {}}
        projectsAtom={fallbackAtoms.projectsAtom}
        tagsAtom={fallbackAtoms.tagsAtom}
        handleRemoveFilter={() => {}}
      />

      <div className={style.scrollArea}>
        <div className={style.componentList}>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </div>
      </div>
    </div>
  );
}

// re-export the external hook from the index
export { useCompactTextList };

CompactLibraryComponentList.Fallback = CompactLibraryComponentListFallback;

export default CompactLibraryComponentList;
