import { libraryCreateComponentModalIsOpenAtom, libraryItemsCountAtom } from "@/stores/Library";
import Button from "@ds/atoms/Button";
import Dot from "@ds/atoms/Dot";
import Text from "@ds/atoms/Text";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

function LibraryItemsHeader(props: { isScrolled: boolean }) {
  const setLibraryCreateComponentModalIsOpen = useSetAtom(libraryCreateComponentModalIsOpenAtom);

  const openCreateComponentModal = useCallback(() => {
    setLibraryCreateComponentModalIsOpen(true);
  }, [setLibraryCreateComponentModalIsOpen]);

  return (
    <div className={classNames(style.libraryItemsHeaderContainer, { [style.isScrolled]: props.isScrolled })}>
      <Text weight="medium">Components</Text>
      <Dot size={2} color="icon-secondary" />
      <Suspense fallback={<Skeleton />}>
        <LibraryItemsCount />
      </Suspense>
      <div className={style.grow}></div>
      <Button level="outline" size="small" onClick={openCreateComponentModal}>
        New component
      </Button>
    </div>
  );
}

function LibraryItemsCount() {
  const libraryItemsCount = useAtomValue(libraryItemsCountAtom);

  return <Text color="tertiary">{libraryItemsCount}</Text>;
}

export default LibraryItemsHeader;
