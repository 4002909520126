import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Virtualizer } from "@tanstack/react-virtual";
import classNames from "classnames";
import { PrimitiveAtom } from "jotai";
import React, { useCallback, useRef } from "react";
import { DragPreview, DragPreviewRenderer } from "react-aria";
import DragAndDroppable, { IDragAndDroppableProps } from "../../atoms/DragAndDroppable";
import LoadingIcon from "../../atoms/LoadingIcon";
import NavItemNestedIndicator from "../../atoms/NavItemNestedIndicator";
import Text, { ITextProps } from "../../atoms/Text";
import VirtualizedList from "../../atoms/VirtualizedList";
import style from "./index.module.css";

interface BaseProps<DraggableDataType, DroppableDataType> {
  /**
   * Unique identifier for the item.
   */
  id: string;

  /**
   * The icon to display for the item.
   */
  icon?: React.ReactNode;

  /**
   * The label to display for the item.
   */
  label: string;

  /**
   * The options to pass to the label component.
   */
  labelOptions?: Partial<ITextProps>;
  /**
   * Whether the item is selected.
   */
  selected?: boolean;

  /**
   * Whether the parent group is selected.
   */
  parentSelected?: boolean;

  /**
   * Whether the item is disabled
   */
  disabled?: boolean;

  /**
   * Whether the item is hidden. An example would be if the item is a child of a collapsed group.
   */
  hidden?: boolean;

  /**
   * Whether the item is nested within a group.
   */
  isNested?: boolean;

  /**
   * Props for enabling drag and drop functionality. See `DragAndDroppable` for more details.
   */
  dragAndDrop:
    | Omit<IDragAndDroppableProps<DraggableDataType, DroppableDataType>, "children">
    | { isDragDisabled: true };

  /**
   * Callback for when the item is clicked.
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /**
   * Callback for when the group is toggled.
   */
  toggleCollapse?: () => void;

  /**
   * Atom used to store all virtualizers in this virtual list. It is primarily used for scrolling an element into view.
   */
  virtualizerAtom?: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>;

  className?: string;
  style?: React.CSSProperties;
}

interface GroupItemProps<DraggableDataType, DroppableDataType> extends BaseProps<DraggableDataType, DroppableDataType> {
  type: "group";
  items: INavItemProps<DraggableDataType, DroppableDataType>[];
  collapse: boolean;
  virtualizerAtom: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>;
}

export interface TextNavItemProps<DraggableDataType, DroppableDataType>
  extends BaseProps<DraggableDataType, DroppableDataType> {
  type: "item";
}

export type INavItemProps<DraggableDataType, DroppableDataType> =
  | TextNavItemProps<DraggableDataType, DroppableDataType>
  | GroupItemProps<DraggableDataType, DroppableDataType>;

export function NavItem<DraggableDataType extends unknown, DroppableDataType extends unknown>(
  props: INavItemProps<DraggableDataType, DroppableDataType>
) {
  const dragPreviewRef = useRef<DragPreviewRenderer>(null);
  const { toggleCollapse, onClick } = props;

  const handleOnClick = useCallback(
    function _handleOnClick(e: React.MouseEvent<HTMLElement>) {
      toggleCollapse?.();
      onClick?.(e);
    },
    [onClick, toggleCollapse]
  );

  return (
    <div
      style={props.style}
      className={classNames(style.NavItemWrapper, props.className, { [style.hidden]: props.hidden })}
      data-testid="nav-item"
    >
      <div className={style.navItemContainer}>
        {props.isNested && <NavItemNestedIndicator />}
        <DragAndDroppable
          className={style.dragAndDropWrapper}
          selectionType="text"
          getDraggableItems={() => []}
          allowedItemKeys={{}}
          {...props.dragAndDrop}
          onDrop={() => {}}
          preview={dragPreviewRef}
          showDraggedContent
        >
          {(dragProps) => {
            return (
              <>
                <div className={style.navItemSpacer}>
                  <div
                    onClick={handleOnClick}
                    className={classNames(style.navItemContent, {
                      [style.selected]: props.selected,
                      [style.parentSelected]: props.parentSelected,
                      [style.dragged]: dragProps.isDragging,
                      [style.disabledContent]: props.disabled,
                    })}
                  >
                    {props.type === "group" && (
                      <KeyboardArrowDownIcon
                        onClick={props.toggleCollapse}
                        className={classNames(style.groupDropDownIndicator, { [style.collapse]: props.collapse })}
                      />
                    )}
                    {props.icon}
                    <Text
                      truncate
                      size="small"
                      color="primary"
                      weight={props.type === "group" ? "base" : "light"}
                      {...props.labelOptions}
                    >
                      {props.label}
                    </Text>
                  </div>
                  <DragPreview ref={dragPreviewRef}>
                    {(items) => {
                      if (items.length > 1) {
                        return (
                          <>
                            <div className={style.multiDragWrapper}>
                              <div
                                className={classNames(style.navItemContent, {
                                  [style.selected]: props.selected,
                                  [style.dragPreview]: true,
                                  [style.parentSelected]: props.parentSelected,
                                })}
                              >
                                {props.icon}
                                <Text
                                  truncate
                                  size="small"
                                  color={props.disabled ? "secondary" : "primary"}
                                  weight={props.type === "group" ? "base" : "light"}
                                >
                                  {props.label}
                                </Text>
                                <div className={style.dragIcon}></div>
                              </div>
                              <div
                                className={classNames(style.navItemContent, {
                                  [style.selected]: props.selected,
                                  [style.dragPreview]: true,
                                  [style.parentSelected]: props.parentSelected,
                                })}
                              ></div>
                              <div
                                className={classNames(style.navItemContent, {
                                  [style.selected]: props.selected,
                                  [style.dragPreview]: true,
                                  [style.parentSelected]: props.parentSelected,
                                })}
                              ></div>
                            </div>
                            <div className={style.countBubble}>
                              <Text weight="strong" color="invert">
                                {items.length}
                              </Text>
                            </div>
                          </>
                        );
                      }
                      return (
                        <div
                          className={classNames(style.navItemContent, {
                            [style.selected]: props.selected,
                            [style.dragPreview]: true,
                            [style.parentSelected]: props.parentSelected,
                          })}
                        >
                          {props.icon}
                          <Text
                            truncate
                            size="small"
                            color="primary"
                            weight={props.type === "group" ? "base" : "light"}
                          >
                            {props.label}
                          </Text>
                        </div>
                      );
                    }}
                  </DragPreview>
                </div>
                {props.type === "group" && (
                  <div className={classNames(style.groupChildren, { [style.collapse]: props.collapse })}>
                    <VirtualizedList
                      id={props.id}
                      items={props.items}
                      children={({ item }) => (
                        <NavItem
                          {...item}
                          isNested
                          parentSelected={props.selected}
                          className={style.groupItems}
                          disabled={props.disabled || dragProps.isDragging}
                        />
                      )}
                      isNested
                      virtualizeOptions={{
                        estimateSize: (index) => 500,
                        overscan: 0,
                        getItemKey: (index) => props.items[index].id,
                      }}
                      virtualizerAtom={props.virtualizerAtom!}
                    />
                  </div>
                )}
              </>
            );
          }}
        </DragAndDroppable>
      </div>
    </div>
  );
}

NavItem.Fallback = function NavItemFallback() {
  return (
    <NavItem
      id={crypto.randomUUID()}
      type="item"
      label="Loading…"
      icon={<LoadingIcon size="xxs" />}
      dragAndDrop={{
        selectionType: "none",
        getDraggableItems: () => [],
        allowedItemKeys: {},
      }}
    />
  );
};

export default NavItem;
