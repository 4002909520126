import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as RDropdownMenu from "@radix-ui/react-dropdown-menu";
import { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu";
import React, { useCallback } from "react";
import DropdownMenuItem, { MenuOption } from "../../atoms/DropdownMenuItem";
import DropdownMenuLabel from "../../atoms/DropdownMenuLabel";
import DropdownMenuSeparator from "../../atoms/DropdownMenuSeparator";
import DropdownTrigger, { IProps as ITriggerProps } from "../../atoms/DropdownTrigger";
import Text from "../../atoms/Text";
import style from "./index.module.css";

type MenuLabel = {
  type: "label";
  label: string;
};

type MenuSeparator = {
  type: "separator";
};

type SubMenu = {
  type: "subMenu";
  label: string;
  items: DropdownMenuItemType[];
};

export type DropdownMenuItemType = MenuOption | MenuLabel | MenuSeparator | SubMenu;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  items: DropdownMenuItemType[];
  RDropdownMenuContentProps?: DropdownMenuContentProps & React.RefAttributes<HTMLDivElement>;
  triggerProps?: ITriggerProps;
  DropdownTrigger?: React.ReactNode;
}

export function DropdownMenuRouter(props: { item: DropdownMenuItemType }) {
  if (props.item.type === "option") return <MenuItem item={props.item} />;
  if (props.item.type === "label")
    return (
      <RDropdownMenu.Label asChild>
        <DropdownMenuLabel>{props.item.label}</DropdownMenuLabel>
      </RDropdownMenu.Label>
    );

  if (props.item.type === "separator")
    return (
      <RDropdownMenu.Separator asChild>
        <DropdownMenuSeparator />
      </RDropdownMenu.Separator>
    );
  if (props.item.type === "subMenu")
    return (
      <RDropdownMenu.Sub>
        <RDropdownMenu.SubTrigger className={style.dropdownMenuSubTrigger}>
          <Text>{props.item.label}</Text>
          <ChevronRightIcon className={style.chevron} />
        </RDropdownMenu.SubTrigger>
        <RDropdownMenu.Portal>
          <RDropdownMenu.SubContent className={style.dropdownMenuContent} sideOffset={2} alignOffset={-5}>
            {props.item.items.map((item, index) => (
              <DropdownMenuRouter key={index} item={item} />
            ))}
          </RDropdownMenu.SubContent>
        </RDropdownMenu.Portal>
      </RDropdownMenu.Sub>
    );
}

export function DropdownMenu(props: IProps) {
  return (
    <RDropdownMenu.Root>
      <RDropdownMenu.Trigger asChild>
        {props.DropdownTrigger || <DropdownTrigger {...props.triggerProps} />}
      </RDropdownMenu.Trigger>

      <RDropdownMenu.Portal>
        <RDropdownMenu.Content className={style.dropdownMenuContent} {...props.RDropdownMenuContentProps}>
          {props.items.map((item, index) => (
            <DropdownMenuRouter key={index} item={item} />
          ))}
        </RDropdownMenu.Content>
      </RDropdownMenu.Portal>
    </RDropdownMenu.Root>
  );
}

function MenuItem(props: { item: MenuOption }) {
  const { type, onClick, ...passThroughProps } = props.item;

  const handleOnClick = useCallback(
    function _handleOnClick() {
      // We have to add a quick setTimeout here to ensure that we give the Radix component one more event loop iteration to properly close itself and remove any event listeners. This prevents issues we ran into where trying to present a dialog modal would prevent the event listeners from being removed, making the entire app feel non interactive.
      // We specifically use setTimeout instead of setImmediate here as the plugin doesn't support setImmediate without a polyfill.
      if (onClick) setTimeout(() => setTimeout(() => onClick(), 0), 0);
    },
    [onClick]
  );

  return (
    <RDropdownMenu.Item className={style.dropdownMenuItemContainer}>
      <DropdownMenuItem onClick={handleOnClick} {...passThroughProps} />
    </RDropdownMenu.Item>
  );
}

export default DropdownMenu;
