import { atom, useAtom, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import React, { useCallback, useMemo, useRef } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { Modal } from "../Modal";
import style from "./index.module.css";

/** A simple modal with headline text, body text, and two buttons - action and cancel. */

interface IDialogueModalProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The headline text of the modal.
   */
  headline: string;

  /**
   * The body text of the modal.
   */
  content: string;

  /**
   * Flag for whether modal is showing.
   */
  open: boolean;

  /**
   * Handler that's called when the open state changes.
   */
  onOpenChange: (open: boolean) => void;

  /**
   * Handler for when action button is clicked.
   */
  onAction: () => void;

  /**
   * Handler for when cancel button is clicked.
   */
  onCancel?: () => void;

  /**
   * Style variants for the action button.
   */
  type?: "default" | "danger";

  /**
   * Text for action button, defaults to "Submit".
   */
  actionText?: string;

  /**
   * Text for cancel button, defaults to "Cancel".
   */
  cancelText?: string;

  /**
   * Additional z index to to add to the modal; useful for scenarios when modals are layered
   */
  additionalZIndex?: number;
}

export function DialogueModal({
  headline,
  content,
  open,
  onOpenChange,
  onAction,
  onCancel,
  type = "default",
  actionText = "Submit",
  cancelText = "Cancel",
  additionalZIndex,
}: IDialogueModalProps) {
  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      onOpenChange(isOpen);

      // We specifically use setTimeout instead of setImmediate here
      // because the plugin doesn't have setImmediate polyfilled.
      if (!isOpen && onCancel) {
        setTimeout(() => {
          onCancel();
        }, 0);
      }
    },
    [onCancel, onOpenChange]
  );

  const handleCancel = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  return (
    <Modal
      headline={headline}
      description={content}
      className={style.content}
      open={open}
      onOpenChange={handleOpenChange}
      additionalZIndex={additionalZIndex}
    >
      <Text color="secondary">{content}</Text>
      <div className={style.footer}>
        <Button level="outline" onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button level={type === "danger" ? "danger" : "primary"} onClick={onAction} autoFocus>
          {actionText}
        </Button>
      </div>
    </Modal>
  );
}

export function useConfirmationModal(props: Pick<IDialogueModalProps, "headline" | "content" | "actionText">) {
  const { current: openAtom } = useRef(atom<boolean | Promise<boolean>>(false));
  const [open, setOpen] = useAtom(openAtom);

  const { current: confirmationAtom } = useRef(atom<boolean | Promise<boolean>>(false));
  const setConfirmationAtom = useSetAtom(confirmationAtom);

  const onAction = useCallback(() => {
    setConfirmationAtom(true);
    setOpen(false);
  }, [setConfirmationAtom, setOpen]);

  const onOpenChange = useCallback(
    (open: boolean) => {
      setConfirmationAtom(open);
      setOpen(open);
    },
    [setConfirmationAtom, setOpen]
  );

  const { headline, content, actionText } = props;
  const modalProps: IDialogueModalProps = useMemo(
    () => ({
      headline,
      content,
      open,
      onAction,
      onOpenChange,
      actionText,
    }),
    [headline, content, open, actionText, onAction, onOpenChange]
  );

  const confirm = useAtomCallback(
    useCallback(
      async (get, set) => {
        // set the pendingConfirmationAtom to a promise which never resolves
        set(confirmationAtom, new Promise(() => null));

        // open the modal
        set(openAtom, true);

        // await the confirmationAtom:
        // it will be set to true or false by the modal actions
        const confirmed = await get(confirmationAtom);

        // return whether or not the action was confirmed
        return confirmed;
      },
      [confirmationAtom, openAtom]
    )
  );

  return [confirm, modalProps] as const;
}

export function useUnsavedChangesModal(content: string) {
  return useConfirmationModal({
    headline: "Discard unsaved changes?",
    content,
    actionText: "Discard",
  });
}

Object.assign(DialogueModal, { useConfirmationModal, useUnsavedChangesModal });

export default DialogueModal as typeof DialogueModal & {
  useConfirmationModal: typeof useConfirmationModal;
  useUnsavedChangesModal: typeof useUnsavedChangesModal;
};
