import { Suspense } from "react";
import LibraryCreateComponentFolderModal from "../LibraryCreateComponentFolderModal";
import LibraryCreateComponentModal from "../LibraryCreateComponentModal";
import LibraryDetailsPanel from "../LibraryDetailsPanel";
import LibraryItemsList from "../LibraryItemsList";
import LibraryLeftSidebar from "../LibraryLeftSidebar";
import style from "./style.module.css";

function LibraryContent() {
  return (
    <div className={style.libraryContentContainer}>
      <Suspense fallback={<div>Loading...</div>}>
        <LibraryLeftSidebar />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <LibraryItemsList />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <LibraryDetailsPanel />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <LibraryCreateComponentModal />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <LibraryCreateComponentFolderModal />
      </Suspense>
    </div>
  );
}

export default LibraryContent;
