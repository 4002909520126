import CloseIcon from "@mui/icons-material/Close";
import * as Dialog from "@radix-ui/react-dialog";
import classnames from "classnames";
import React, { useCallback } from "react";
import { VisuallyHidden } from "react-aria";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { zIndexModal } from "../../tokens/zIndex";
import style from "./index.module.css";

const BASE_Z_INDEX = zIndexModal;

interface IModalProps extends React.PropsWithChildren<{}> {
  /**
   * The headline text of the modal.
   */
  headline: string;

  /**
   * An optional accessible description to be announced when the dialog is opened. This will not be rendered visibly.
   */
  description?: string;

  /**
   * If true, will render a close button in the top right corner of the modal.
   */
  showCloseButton?: boolean;

  /**
   * Flag for whether modal is showing.
   */
  open: boolean;

  /**
   * Handler that's called when the open state changes.
   */
  onOpenChange: (open: boolean) => void;

  /**
   * Additional z index to to add to the modal; useful for scenarios when modals are layered
   */
  additionalZIndex?: number;

  modal?: boolean;

  className?: string;
}

export function Modal({
  headline,
  description,
  children,
  showCloseButton,
  open,
  onOpenChange,
  modal,
  additionalZIndex = 0,
  className,
}: IModalProps) {
  const onClose = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  // Close the modal when the escape key is pressed
  const onEscapeKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const zIndexOverlay = BASE_Z_INDEX + additionalZIndex;
  const zIndexContent = zIndexOverlay + 1;

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} data-testid="modal" modal={modal}>
      <Dialog.Portal>
        <Dialog.Overlay className={style.DialogOverlay} style={{ zIndex: zIndexOverlay }} />
        <Dialog.Content
          className={classnames(style.DialogContent, className)}
          style={{ zIndex: zIndexContent }}
          onEscapeKeyDown={onEscapeKeyDown}
        >
          <Dialog.Title asChild>
            <div className={style.headline}>
              <Text size="large" weight="strong">
                {headline}
              </Text>
              {showCloseButton && (
                <Dialog.Close asChild>
                  <Button type="icon" level="secondary" onClick={onClose} className={style.closeButton}>
                    <CloseIcon />
                  </Button>
                </Dialog.Close>
              )}
            </div>
          </Dialog.Title>
          <VisuallyHidden>
            <Dialog.Description>{description || ""}</Dialog.Description>
          </VisuallyHidden>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default Modal;
