import { derivedOnlySelectedComponentAtom } from "@/stores/Library";
import DeveloperId from "@/views/NS/components/DeveloperId";
import DialogueModal from "@ds/molecules/DialogueModal";
import InstancesBanner from "@ds/molecules/InstancesBanner";
import { useAtomValue, useSetAtom } from "jotai";
import React, { Suspense } from "react";
import { deleteLibraryComponentStructureAtom } from "../../../../../stores/Components";
import DeleteItem from "../../../components/DeleteItem";
import LibraryMetadataPanel from "../LibraryComponentMetadata";

function LibraryEditEditPanel() {
  const instanceCount = 24;
  const derivedOnlySelectedComponent = useAtomValue(derivedOnlySelectedComponentAtom);
  const deleteLibraryComponentAction = useSetAtom(deleteLibraryComponentStructureAtom);
  const [confirmShouldDeleteComponent, deleteComponentModalProps] = DialogueModal.useConfirmationModal({
    content: "This component will be deleted.",
    headline: "Are you sure?",
    actionText: "Delete",
  });

  const previewBannerClick = () => {};
  const onDelete = async () => {
    if (!derivedOnlySelectedComponent) return;

    const shouldDelete = await confirmShouldDeleteComponent();

    if (shouldDelete) {
      await deleteLibraryComponentAction({ _id: derivedOnlySelectedComponent._id });
    }
  };

  return (
    <>
      <DialogueModal {...deleteComponentModalProps} type="danger" />
      {instanceCount > 0 && (
        <Suspense fallback={<InstancesBanner content={`Loading instances...`} />}>
          {/* {!designPreviewToggled && ( */}
          <InstancesBanner
            content={`${instanceCount} instances across 4 projects`}
            actionText="Preview"
            onActionClick={previewBannerClick}
          />
          {/* )} */}
        </Suspense>
      )}
      <Suspense fallback={<div>Loading Metadata...</div>}>
        <LibraryMetadataPanel />
      </Suspense>

      {/* These elements should only be rendered if exactly one text item is selected */}
      {!!derivedOnlySelectedComponent && (
        <>
          {/* <Suspense fallback={<></>}>
            <Comments />
          </Suspense> */}
          <DeveloperId />
          <DeleteItem onClick={onDelete} text="Delete component" />
        </>
      )}
    </>
  );
}

export default LibraryEditEditPanel;
