import { ITextItem } from "@shared/types/TextItem";

/**
 * If all sets of tags are the same, return that set of tags. Otherwise, return a 'mixed tags' object.
 */
export function getMergedTags(textItems: ITextItem[]): string[] | { mixed: true } {
  if (textItems.length === 0) {
    return [];
  }

  const referenceTags = textItems[0].tags.slice().sort();

  // Check if every item's tags matches the reference
  const allTagsMatch = textItems.every((textItem) => {
    const currentSorted = textItem.tags.slice().sort();
    return (
      currentSorted.length === referenceTags.length && currentSorted.every((tag, index) => tag === referenceTags[index])
    );
  });

  return allTagsMatch ? referenceTags : { mixed: true };
}
