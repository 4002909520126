import { useWorkspace } from "@/store/workspaceContext";
import { VARIANT_STATUSES_ENABLED } from "@/utils/featureFlags";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CodeIcon from "@mui/icons-material/Code";
import EditIcon from "@mui/icons-material/Edit";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { default as InfoOutlined, default as InfoOutlinedIcon } from "@mui/icons-material/InfoOutlined";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotesIcon from "@mui/icons-material/Notes";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import TollIcon from "@mui/icons-material/Toll";
import Tooltip from "@shared/frontend/Tooltip";
import { TemplateCompName } from "@shared/frontend/templates/TemplateCompName";
import { useComponentTypeState } from "@shared/frontend/templates/useComponentTypeState";
import { userHasResourcePermission, userHasSomeResourceAccess } from "@shared/frontend/userPermissionContext";
import { isRichTextStyled } from "@shared/utils/richText";
import classnames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import ReactTooltip from "react-tooltip";
import { isSuperset } from "../../../shared/utils/array";
import { getInviteOnlyFoldersEnabled } from "../../../util/folder";
import DashboardCustomize from "../../assets/DashboardCustomize";
import { useHasProjectFeatureFlag } from "../../hooks/useHasProjectFeatureFlag";
import { useHasTextChanges } from "../../hooks/useHasTextChanges";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { INTERCOM_ATTR } from "../../utils/constants";
import { parsePluralInput } from "../../utils/pluralization";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import CompactLabel from "../CompactLabel";
import CompactLabelWithEditMode, { useEditModeState } from "../CompactLabel/CompactLabelWithEditMode";
import FigmaComponentTextNodeLabel from "../FigmaComponentTextNodeLabel";
import OutlineButton from "../OutlineButton";
import StatusSelect from "../StatusSelect";
import TextItemTextInputs from "../TextItemTextInputs/TextItemTextInputs";
import UserSelect from "../UserSelect";
import ApiID from "../api-id";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import ComponentModal from "../componentmodal/componentmodal";
import EditCompNameModal from "../editcompnamemodal/editcompnamemodal";
import { GrayWarning } from "../shared/GrayWarning";
import Spinner from "../spinner/spinner";
import TagInput from "../tag-input/tag-input";
import VariablesPanel from "../variable-interpolations/VariablesPanel";
import VariantIcon from "../variant-icon/VariantIcon";
import { CompName } from "./components";
import style from "./style.module.css";

const BASE_VARIANT_ID = "__base__";

const EditWSComp = ({
  commentEditor,
  isDraftComp,
  selectedId,
  selectedVariant,
  selectedCompName,
  selectedApiID,
  origComp,
  handleDocUpdate,
  handleHistoryUpdate,
  fetchCompInfo,
  fetchAllComps,
  doc_ID,
  setOrigComp,
  tagSuggestions,
  getWorkspaceTags,
  fetchHistory,
  refreshLibraryHistory,
  frameVariants,
  selectedGroupId,
  handleMoveToFolder,
  deleteComp,
  detachAllAndDelete,
  mergeComponents,
  allComponentsCache = { current: [] },
  onSaveSuggestion,
  isLockedProject,
  isMergedBranch,
  componentType = "standard",
  instance,
  postSaveCallback,
  postRenameCallback,
  // optional prop to get notified when a component is detached
  onDetach,
  // optional prop to get notified when a component is attached to a single text item
  onSingleAttach,
  // optional prop to get notified when a component is attached to multiple text items
  onMultiAttach,
}) => {
  // if instance information is passed into the edit panel, it's safe to assume
  // that the edit panel is being rendered on the project page
  const isProjView = Boolean(instance);

  const [status, setStatus] = useState("");
  const [text, setText] = useState("");
  const [notes, setNotes] = useState("");
  const [tags, setTags] = useState([]);
  const [assigneeId, setAssigneeId] = useState(null);
  const [compName, setCompName] = useState("");

  const compApiID = origComp.ws_comp?.apiID;
  const [shouldResetComp, setShouldResetComp] = useState(false);
  const [isComponentNameLoading, setIsComponentNameLoading] = useState(true);
  const { projectId, doc } = useProjectContext();
  const isRichTextFlagOn = useHasProjectFeatureFlag("rich_text");

  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [componentModalOpen, setComponentModalOpen] = useState(false);
  const [editNameModalOpen, setEditNameModalOpen] = useState(false);
  const [isVariant, setIsVariant] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showPlurals, setShowPlurals] = useState(false);
  const [showVariantPlurals, setShowVariantPlurals] = useState(false);
  const [textChanged, setTextChanged] = useState(false);
  const [showSlackModal, setShowSlackModal] = useState(false);
  const [characterLimit, setCharacterLimit] = useState(null);

  const isComponentEditEnabled = userHasResourcePermission("component_folder:edit");
  const isComponentCommentEnabled = userHasResourcePermission("component_folder:comment");
  const userHasAccessToAnyComponent = userHasSomeResourceAccess("component_folder");

  const isProjectEditEnabled = userHasResourcePermission("project_folder:edit");

  const isInSampleProject = doc && doc[0].isSample;

  const [editMode, setEditMode] = useEditModeState();
  const onModeChange = async (newMode) => {
    if (newMode !== editMode && newMode === "suggesting") {
      // custom unsaved changes modal text for saving a suggestion
      setModalParams({
        modalText: {
          body: "Before switching to suggestion mode, would you like to save your edits?",
        },
      });

      // ensure that "sync edits to duplicates" is off
      await new Promise((resolve) => checkDetailPanelChanges(resolve));

      // switch the unsaved changes modal back to default text
      setModalParams({ modalText: undefined });
    }
    setEditMode(newMode);
  };
  useEffect(
    function resetEditMode() {
      setEditMode("editing");
    },
    [selectedId]
  );

  const { workspaceInfo, users } = useWorkspace();
  const formattedUserOptions = useMemo(() => {
    if (!users) return [];
    return users.map((user) => ({
      id: user._id,
      name: user.name,
    }));
  }, [users]);

  let manualApiIdEditsAllowed;
  if (isProjView) {
    manualApiIdEditsAllowed = isProjectEditEnabled && !workspaceInfo?.config?.projects?.apiIdPreventManualEdits;
  } else {
    manualApiIdEditsAllowed = isComponentEditEnabled && !workspaceInfo?.config?.components?.apiIdPreventManualEdits;
  }

  // { text, variables }
  const valueBeingEdited = useRef();

  const {
    canSaveEdits: [canSaveEdits, setCanSaveEdits],
    checkDetailPanelChanges,
    setModalParams,
  } = useContext(UnsavedChangesContext);

  const variantInstance = useMemo(() => {
    if (selectedVariant?.id && selectedVariant?.id !== "__base__" && origComp?.variants) {
      for (var variant of origComp.variants) {
        if (variant?.variantId === selectedVariant?.id) {
          return variant;
        }
      }
      return {
        variantId: selectedVariant.id,
        text: "",
        lastSync: null,
        variables: [],
        plurals: [],
        status: origComp.status,
      };
    }
    return null;
  }, [origComp, selectedVariant]);

  const hasUnsavedChanges = useMemo(() => {
    if (origComp) {
      const assigneeChanged = assigneeId !== (origComp.assignee || null);
      let statusChanged = status !== "" && status !== origComp.status;

      if (isVariant && variantInstance && VARIANT_STATUSES_ENABLED) {
        statusChanged = status !== variantInstance?.status;
      }

      const notesChanged = notes !== origComp.notes && !(notes === "" && !origComp.notes);

      const tagNames = tags.map((tag) => tag.name);
      const tagsChanged = tagNames.sort().join("") !== origComp.tags.sort().join("");

      const characterLimitChanged = characterLimit && characterLimit !== origComp.characterLimit;

      const result =
        textChanged || assigneeChanged || statusChanged || notesChanged || tagsChanged || characterLimitChanged;
      return result;
    } else return false;
  }, [origComp, textChanged, status, notes, tags, assigneeId, characterLimit]);

  useEffect(() => {
    setCanSaveEdits(hasUnsavedChanges);
    return () => setCanSaveEdits(false);
  }, [hasUnsavedChanges]);

  const templateComponentState = useComponentTypeState(componentType, hasUnsavedChanges);

  const isTemplateComponentInstance = Boolean(templateComponentState?.type === "template" && isProjView);

  const isTemplateComponentMain = Boolean(templateComponentState?.type === "template" && !isProjView);

  // Submit button props
  const onSubmitButtonClick = () => {
    if (isVariant) {
      saveVariantEdits();
      return;
    }

    if (editMode === "suggesting") {
      saveSuggestion();
      return;
    }

    saveEdits();
  };
  const submitButtonDisabled = !canSaveEdits || isSaving;
  const submitButtonClass = classnames({
    [style.suggestEditsSubmitButton]: editMode === "suggesting",
  });
  const submitButtonText = useMemo(() => {
    if (isSaving) {
      return "Saving...";
    }
    if (editMode === "editing") {
      return "Save Edits";
    }
    if (editMode === "suggesting") {
      return "Save Suggestion";
    }

    throw new Error(`Invalid edit mode: ${editMode}`);
  }, [isSaving, editMode]);

  // Cancel button props
  const onCancelButtonClick = () => resetChanges();
  const cancelButtonClass = classnames({
    [style.suggestEditsCancelButton]: editMode === "suggesting",
  });
  const cancelButtonDisabled = !canSaveEdits || isSaving;
  const metadataDisabled = editMode === "suggesting";

  const goToComp = (comp, newTab = false) => {
    if (!isProjView) {
      return;
    }

    let url = `/components/`;
    if (comp.ws_comp.folder_id) url += `folder/${comp.ws_comp.folder_id}/`;
    url += comp.ws_comp._id;

    if (newTab) {
      window.open(url, "_blank");
      return;
    }

    history.push(url);
  };

  const getCompLink = (comp) => {
    if (!isProjView) {
      return;
    }

    let url = `/components/`;
    if (comp.ws_comp.folder_id) url += `folder/${comp.ws_comp.folder_id}/`;
    url += comp.ws_comp._id;

    return url;
  };

  const fetchData = async () => {
    if (!origComp) {
      return;
    }

    setAssigneeId(origComp.assignee || null);
    variantInstance && VARIANT_STATUSES_ENABLED ? setStatus(variantInstance.status) : setStatus(origComp.status);
    setText(origComp.text);
    setNotes(origComp.notes);
    setTags(
      (origComp.tags || []).map((tag, index) => {
        return {
          id: index,
          name: tag,
        };
      })
    );

    setIsComponentNameLoading(false);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };
  const onDeleteTag = (i) => {
    const curr_tags = tags.slice(0);
    curr_tags.splice(i, 1);
    setTags(curr_tags);
  };
  const onAddTag = (tag) => {
    const tagName = tag.name;
    // case insensitive search because we don't want duplicates with different casing
    const match = tags.filter((existing) => existing.name.toUpperCase() === tagName.toUpperCase());
    if (match.length === 0) {
      const curr_tags = [...tags, { name: tagName }];
      setTags(curr_tags);
    }
  };

  const resetChanges = () => {
    fetchData();
    setShouldResetComp(true);
    setTimeout(() => {
      setShouldResetComp(false);
    }, 100);
  };

  const saveVariantEdits = async () => {
    if (isTemplateComponentInstance) {
      return saveTemplateVariantEdits();
    }

    try {
      setIsSaving(true);
      const { allVariables, baseText, baseRichText, plurals } = parsePluralInput(
        valueBeingEdited,
        variantInstance?.text || ""
      );

      const { url, body } = API.ws_comp.patch.variant;
      const { data: response } = await http.patch(
        url(selectedId),
        body({
          variantId: selectedVariant.id,
          updatedVariantInfo: {
            text: baseText,
            rich_text: baseRichText,
            variables: allVariables,
            plurals,
            status: VARIANT_STATUSES_ENABLED ? status : undefined,
          },
        })
      );
      await saveCharacterLimit();
      setIsSaving(false);
      if (isProjView) {
        handleDocUpdate(response.instances.map((comp) => comp._id));
        handleHistoryUpdate();
        fetchCompInfo();
      } else {
        refreshLibraryHistory();
        fetchAllComps();
        fetchHistory();
      }
    } catch (error) {
      setIsSaving(false);
      console.error("Error in editing component variant text: ", error.message);
    }
  };

  const saveCharacterLimit = useCallback(async () => {
    if (characterLimit !== origComp.characterLimit) {
      const { url: updateLimitURL, body: updateLimitBody } = API.comp.post.updateCharacterLimit;
      const updateURL = updateLimitURL(origComp._id);
      await http.post(
        updateURL,
        updateLimitBody({
          characterLimit,
        })
      );
      if (isProjView) {
        setOrigComp({
          ...origComp,
          characterLimit,
        });
      }
    }
  }, [characterLimit, origComp]);

  const saveCharacterLimitValue = useCallback(
    async (newCharacterLimit) => {
      if (origComp?.characterLimit !== newCharacterLimit) {
        const { url, body } = API.comp.post.updateCharacterLimit;
        const updateURL = url(origComp._id);
        const { data: res } = await http.post(
          updateURL,
          body({
            characterLimit: newCharacterLimit,
          })
        );
        if (isProjView) {
          setOrigComp({
            ...origComp,
            characterLimit: newCharacterLimit,
          });
        }

        setCharacterLimit(newCharacterLimit);

        // update the frontend components with refreshed data
        if (postSaveCallback) {
          postSaveCallback(res);
        }
      }
    },
    [origComp]
  );

  const saveEdits = async () => {
    if (isTemplateComponentInstance) {
      return saveTemplateEdits();
    }

    const latest_tags = tags.map((tag) => tag.name);
    try {
      setIsSaving(true);
      const { url, body } = API.ws_comp.post.edit;

      const { allVariables, baseText, baseRichText, plurals } = parsePluralInput(valueBeingEdited, origComp.text);

      const newCompInfo = {
        assignee: assigneeId,
        status,
        text: baseText,
        rich_text: baseRichText,
        variables: allVariables,
        plurals,
        notes,
        tags: latest_tags,
      };

      if (isComponentEditEnabled) await saveCharacterLimit();

      const { data: response } = await http.post(
        url,
        body({
          ws_comp_id: selectedId,
          doc_id: doc_ID,
          newCompInfo,
          from: "web_app",
        })
      );

      setIsSaving(false);
      setShowNotes(false);
      setShowTags(false);

      // new property that allows the parent component to handle refreshing
      // component data
      if (postSaveCallback) {
        postSaveCallback(response);
      } else if (isProjView) {
        handleDocUpdate(response.instances);
        handleHistoryUpdate();
        fetchCompInfo();
      } else {
        refreshLibraryHistory();
        fetchAllComps();
        if (response.changeCreated) {
          fetchHistory();
        }
      }
      if (
        !isSuperset(
          latest_tags,
          tagSuggestions.map((item) => item.name)
        )
      ) {
        getWorkspaceTags();
      }
    } catch (error) {
      setIsSaving(false);
      console.error("in editwscomp.jsx: ", error);
    }
  };

  const handleCharacterLimitChange = async (newCharacterLimit) => {
    setCharacterLimit(newCharacterLimit);
  };

  // directly saves updated character limit value
  const handleCharacterLimitSubmit = async (newCharacterLimit) => {
    if (editMode === "suggesting" || newCharacterLimit === origComp.characterLimit) return;

    try {
      setIsSaving(true);
      await saveCharacterLimitValue(newCharacterLimit);
    } catch (error) {
      setIsSaving(false);
      console.error("in editcomp.jsx: ", error);
    }

    setIsSaving(false);
  };

  const saveTemplateEdits = async () => {
    if (!instance) {
      throw new Error(`Cannot save template edits without an instance`);
    }

    const detachResult = API.ws_comp.actions.detach(origComp._id, selectedId);

    const latest_tags = tags.map((tag) => tag.name);

    let updateURL, reqBody;

    if (isComponentEditEnabled) {
      const { url, body } = API.comp.post.update;
      updateURL = url(instance._id);

      const { allVariables, baseText, baseRichText, plurals } = parsePluralInput(valueBeingEdited, origComp.text);

      reqBody = body({
        newCompInfo: {
          assignee: assigneeId,
          status,
          text: baseText,
          rich_text: baseRichText,
          variables: allVariables,
          plurals,
          notes,
          tags: latest_tags,
        },
        originalText: origComp.text,
        editAll: false,
        changeInfo: {
          doc_name: instance.doc_name,
          doc_id: doc_ID,
        },
      });
    } else {
      const { url, body } = API.comp.post.updateStatusById;
      updateURL = url(instance._id);
      reqBody = body({
        newCompInfo: { status: origComp.status },
        editAll: false,
      });
    }

    try {
      setIsSaving(true);
      await Promise.all([http.post(updateURL, reqBody), detachResult]);
      await Promise.all([
        handleHistoryUpdate(),
        fetchCompInfo(),
        !isSuperset(
          latest_tags,
          tagSuggestions.map((item) => item.name.toUpperCase())
        ) && getWorkspaceTags(),
      ]);
    } catch (error) {
      setIsSaving(false);
      console.error(`Error saving template edits: ${error}`);
    }
  };

  const saveTemplateVariantEdits = async () => {
    try {
      if (!instance) {
        throw new Error(`Cannot save template edits without an instance`);
      }

      setIsSaving(true);

      const detachResult = API.ws_comp.actions.detach(origComp._id, selectedId);

      const { allVariables, baseText, baseRichText, plurals } = parsePluralInput(
        valueBeingEdited,
        variantInstance?.text || ""
      );

      const { url, body } = API.variant.post.editVariantTextForComp;
      await Promise.all([
        detachResult,
        http.post(
          url(instance._id, selectedVariant.id),
          body({
            updatedVariantInfo: {
              text: baseText,
              rich_text: baseRichText,
              variables: allVariables,
              plurals,
              status,
            },
            fromFigma: false,
          })
        ),
      ]);

      await Promise.all([handleHistoryUpdate(), fetchCompInfo()]);
    } catch (error) {
      setIsSaving(false);
    }
  };

  const saveSuggestion = async () => {
    if (!isComponentEditEnabled) {
      return;
    }

    setIsSaving(true);

    const [
      {
        value: { text, richText, variables },
      },
    ] = valueBeingEdited.current;

    await onSaveSuggestion({ text, richText, variables });

    resetChanges();
    setIsSaving(false);
    setEditMode("editing");
  };

  const detachInstance = async () => {
    try {
      await API.ws_comp.actions.detach(origComp._id, selectedId);
      handleDocUpdate([origComp._id]);
      handleHistoryUpdate();
      fetchCompInfo();
      if (onDetach) onDetach(origComp._id, selectedId);
    } catch (error) {
      console.error("in editwscomp.jsx: ", error);
    }
  };

  const swapCompAndCloseModal = async (newWSComp) => {
    try {
      const { url, body } = API.ws_comp.post.swapComp;
      await http.post(
        url,
        body({
          ws_comp_id: newWSComp._id,
          assigned_comp: newWSComp.instances[0],
          old_ws_comp_id: selectedId,
          comp_id: origComp._id,
        })
      );
      handleDocUpdate([origComp._id]);
      fetchCompInfo();
      toggleComponentModal();
    } catch (error) {
      console.error("in editwscomp.jsx: ", error);
    }
  };

  const getTextHasChanged = useHasTextChanges(origComp, true);

  const handleStatusChange = (status) => {
    setStatus(status);
  };
  const handleTextChange = (value) => {
    if (shouldResetComp || !value.length) return;
    setTextChanged(getTextHasChanged(value, selectedVariant?.id || BASE_VARIANT_ID));
    valueBeingEdited.current = value;
  };

  const saveTextIdEdit = async (newApiId) => {
    try {
      const { url: wsCompUrl, body } = API.ws_comp.post.editApiId;
      const URL = isProjView ? `/comp/editApiId/${origComp._id}` : wsCompUrl(selectedId);
      const { data: comp } = await http.post(
        URL,
        body({
          doc_ID,
          newApiId: newApiId,
          from: "web_app",
        })
      );
      if (isProjView) {
        setOrigComp(comp);
        handleDocUpdate([origComp._id]);
        handleHistoryUpdate();
      } else {
        fetchAllComps();
        if (comp.changeCreated) {
          fetchHistory();
        }
      }

      return "success";
    } catch (error) {
      console.error("Error in editing text apiID: ", error.message);
      if (error.response?.data?.message) {
        return error.response?.data?.message;
      }

      return "Unable to save edit";
    }
  };

  useEffect(() => {
    if (origComp) {
      fetchData();
    }
  }, [origComp]);

  useEffect(() => {
    setIsVariant(selectedVariant && selectedVariant.id !== "__base__");

    if (selectedVariant && variantInstance) {
      setStatus(variantInstance.status);
    }

    resetChanges();
  }, [selectedVariant]);

  const toggleComponentModal = () => {
    setComponentModalOpen(!componentModalOpen);
  };

  const toggleEditNameModal = () => {
    setEditNameModalOpen(!editNameModalOpen);
  };

  useEffect(() => {
    const saveEditsFunc = isVariant ? saveVariantEdits : saveEdits;

    setModalParams({
      saveCallback: async () => {
        setCanSaveEdits(false);
        await saveEditsFunc();
      },
      discardCallback: async () => {
        setCanSaveEdits(false);
        resetChanges();
      },
    });
  }, [setModalParams, setCanSaveEdits, saveEdits, saveVariantEdits, isVariant, resetChanges, templateComponentState]);

  return (
    <div data-testid="wscomp-edit-panel" className={style.container}>
      {origComp && (
        <div
          data-testid="ws-comp-edit-panel"
          className={classnames(style.editComp, "edit-component")}
          data-tour={INTERCOM_ATTR.WS_COMP_EDIT_PANEL}
        >
          {!isComponentEditEnabled && (
            <>
              <div className={style.noCompEditMessage}>You don't have edit access to this component.</div>
            </>
          )}
          {origComp.isSample && (
            <div className={style.sampleInfoContainer}>
              <div className={style.sampleInfo}>
                <div className={style.sampleInfoIcon}>
                  <InfoOutlined fontSize="inherit" />
                </div>
                &nbsp;This is a sample component.
              </div>
            </div>
          )}
          {!(isProjView && templateComponentState.type === "template" && !templateComponentState.attached) && (
            <div
              className={classnames({
                [style.compNameContainer]: true,
              })}
            >
              <a
                href={getCompLink(origComp)}
                className={classnames({
                  [style.compName]: true,
                  [style.compNameTemplate]: isTemplateComponentInstance,
                  [style.projViewCompName]: isProjView && isComponentCommentEnabled,
                })}
                data-tour={INTERCOM_ATTR.EDIT_PANEL_COMPONENT_NAME}
              >
                {isComponentNameLoading ? (
                  <Spinner size={20} marginTop={0} centered={false} />
                ) : (
                  <>
                    {isTemplateComponentInstance ? (
                      <TemplateCompName
                        name={selectedCompName || compName}
                        className={style.name}
                        classNameIcon={style.templateIcon}
                        classNameText={style.templateKeyword}
                      />
                    ) : (
                      <CompName name={selectedCompName || compName} />
                    )}
                  </>
                )}
                {isProjView && isComponentCommentEnabled ? (
                  <div onClick={(e) => goToComp(origComp, e.metaKey || e.ctrlKey)}>
                    {templateComponentState.type === "standard" && <ImportContactsIcon className={style.icon} />}
                  </div>
                ) : (
                  isComponentEditEnabled && (
                    <div
                      onClick={() => {
                        checkDetailPanelChanges(() => {
                          toggleEditNameModal();
                        });
                      }}
                    >
                      <EditIcon className={style.icon} />
                    </div>
                  )
                )}
              </a>
              {isProjectEditEnabled &&
                !isVariant &&
                ((isProjView && templateComponentState.type === "standard") || isDraftComp) && (
                  <>
                    {isProjView && isProjectEditEnabled && userHasAccessToAnyComponent && (
                      <div onClick={() => toggleComponentModal()}>
                        <SwapCallsIcon data-tip data-for="swap" className={style.icon} />
                        <ReactTooltip id="swap" place="bottom" effect="solid">
                          Swap component
                        </ReactTooltip>
                      </div>
                    )}
                    {instance && isProjectEditEnabled && (
                      <div onClick={() => detachInstance()}>
                        <LinkOffIcon data-tip data-for="detach" className={style.icon} />
                        <ReactTooltip id="detach" place="bottom" effect="solid">
                          Detach instance
                        </ReactTooltip>
                      </div>
                    )}
                  </>
                )}
              {isComponentEditEnabled && !isProjView && (
                <Dropdown>
                  <Dropdown.Toggle>
                    <MoreVertIcon className={style.more_icon} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {getInviteOnlyFoldersEnabled(workspaceInfo) && (
                      <Tooltip
                        hideOnClick={false}
                        disabled={!origComp.isSample}
                        className={style.tooltip}
                        content={<div className={style.body}>Sample data cannot be moved to other folders.</div>}
                        theme="dark"
                        placement="top"
                      >
                        <div>
                          <Dropdown.Item disabled={origComp?.isSample} onClick={() => handleMoveToFolder(selectedId)}>
                            Move to folder
                          </Dropdown.Item>
                        </div>
                      </Tooltip>
                    )}
                    {isDraftComp ? (
                      <Tooltip
                        hideOnClick={false}
                        disabled={!origComp.isSample}
                        className={style.tooltip}
                        content={<div className={style.body}>Deleting is disabled for sample data.</div>}
                        theme="dark"
                      >
                        <div>
                          <Dropdown.Item
                            disabled={origComp?.isSample}
                            className={style.danger}
                            onClick={() => deleteComp()}
                          >
                            Delete {isTemplateComponentMain ? "template component" : "component"}
                          </Dropdown.Item>
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        hideOnClick={false}
                        disabled={!origComp.isSample}
                        className={style.tooltip}
                        content={<div className={style.body}>Deleting is disabled for sample data.</div>}
                        theme="dark"
                      >
                        <div>
                          <Dropdown.Item
                            disabled={origComp?.isSample}
                            className={style.danger}
                            onClick={detachAllAndDelete}
                          >
                            Detach from all instances and delete {isTemplateComponentMain ? "template" : "component"}
                          </Dropdown.Item>
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip
                      hideOnClick={false}
                      disabled={!origComp.isSample}
                      className={style.tooltip}
                      content={<div className={style.body}>Sample components cannot be merged.</div>}
                      theme="dark"
                      placement="top"
                    >
                      <div>
                        <Dropdown.Item disabled={origComp?.isSample} onClick={mergeComponents}>
                          Merge Components
                        </Dropdown.Item>
                      </div>
                    </Tooltip>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
          <div className={style.form}>
            {isDraftComp && templateComponentState.type === "standard" && (
              <div className={style.draft}>Component not currently attached in a project.</div>
            )}
            {templateComponentState.type === "template" && !isProjView && (
              <div className={style.templateLabel}>
                <DashboardCustomize />
                This is a template component.{" "}
                <a href="https://www.dittowords.com/docs/templates" target="_blank">
                  Learn more
                </a>
              </div>
            )}
            {canSaveEdits && (
              <div className={style.warning}>
                {isTemplateComponentInstance && (
                  <span>
                    You have unsaved changes! Remember that editing will detach this from the template component.
                  </span>
                )}
                {!isTemplateComponentInstance && (
                  <span>
                    You have unsaved changes! Remember that editing a{" "}
                    {templateComponentState?.type === "template" && "template "}
                    component also edits all of its instances.
                  </span>
                )}
              </div>
            )}
            <div>
              {isProjView && <FigmaComponentTextNodeLabel type={origComp?.integrations?.figma?.type} />}
              <StatusSelect
                status={status}
                handleStatusChange={handleStatusChange}
                disabled={
                  // remove variant condition entirely when removing feature flag
                  (!VARIANT_STATUSES_ENABLED && isVariant) ||
                  metadataDisabled ||
                  isLockedProject ||
                  !isComponentCommentEnabled
                }
              />
              <TextItemTextInputs
                highlightBrackets={templateComponentState?.type === "template"}
                shouldShowRichText={true}
                textItem={shouldResetComp ? { ...origComp, text: "", variables: [], plurals: [] } : { ...origComp }}
                legacyHandleTextChange={(value) => (!isVariant ? handleTextChange(value) : () => {})}
                readonly={!isComponentEditEnabled || isVariant || isLockedProject}
                isBaseText={true}
                isVariant={isVariant}
                shouldShowPlurals={[showPlurals, setShowPlurals]}
                textLabelLeft={
                  <>
                    {(isVariant || isLockedProject) && <CompactLabel text="Text" Icon={NotesIcon} />}
                    {!(isVariant || isLockedProject) && (
                      <CompactLabelWithEditMode
                        text="Text"
                        Icon={NotesIcon}
                        mode={editMode}
                        onModeChange={onModeChange}
                        disabled={!isComponentEditEnabled}
                      />
                    )}
                  </>
                }
                textInputClassName={classnames({
                  [style.textInputSuggestionMode]: editMode === "suggesting",
                })}
                pluralInputsDisabled={editMode === "suggesting"}
                handleCharacterLimitChange={handleCharacterLimitChange}
                handleCharacterLimitSubmit={handleCharacterLimitSubmit}
                inSampleProject={origComp.isSample}
              />
              {isRichTextStyled(origComp.rich_text) && projectId && !isRichTextFlagOn && (
                <GrayWarning>
                  Rich text in this component won't sync back to your designs unless you enable rich text for this
                  project. <a href="https://www.dittowords.com/docs/rich-text">Learn more</a>
                </GrayWarning>
              )}
              {isVariant && selectedVariant && (
                <div className={style.variantTextWrapper}>
                  <TextItemTextInputs
                    highlightBrackets={templateComponentState?.type === "template"}
                    shouldShowRichText={true}
                    placeholder={"No variant text."}
                    isVariant={true}
                    readonly={!isComponentEditEnabled || isLockedProject}
                    isBaseText={false}
                    showContentLength={false}
                    textItem={
                      shouldResetComp
                        ? {
                            text: "",
                            variables: [],
                            plurals: [],
                            reset: true,
                            characterLimit: characterLimit,
                          }
                        : {
                            text: variantInstance?.text || "",
                            rich_text: variantInstance?.rich_text,
                            variables: variantInstance?.variables || [],
                            plurals: variantInstance?.plurals || [],
                            reset: false,
                            characterLimit: characterLimit,
                          }
                    }
                    legacyHandleTextChange={handleTextChange}
                    textLabelLeft={<CompactLabel text="Variant Text" Icon={VariantIcon} />}
                    shouldShowPlurals={[showVariantPlurals, setShowVariantPlurals]}
                    handleCharacterLimitChange={handleCharacterLimitChange}
                    handleCharacterLimitSubmit={handleCharacterLimitSubmit}
                    inSampleProject={origComp.isSample}
                  />
                </div>
              )}
              <div className={style.textAreaButtons}>
                {!showAssign && !origComp.assignee && !isVariant && (
                  <OutlineButton
                    Icon={AccountCircleIcon}
                    text="Assign"
                    onClick={() => setShowAssign(true)}
                    disabled={metadataDisabled || !isComponentEditEnabled}
                  />
                )}
                {!(notes && notes.length > 0) && !showNotes && !isVariant && (
                  <OutlineButton
                    Icon={InfoOutlinedIcon}
                    text="Notes"
                    onClick={() => setShowNotes(true)}
                    disabled={metadataDisabled || !isComponentEditEnabled}
                  />
                )}
                {origComp.tags.length === 0 && !showTags && !isVariant && (
                  <OutlineButton
                    Icon={LocalOfferIcon}
                    text="Tags"
                    onClick={() => setShowTags(true)}
                    disabled={metadataDisabled || !isComponentEditEnabled}
                  />
                )}
                {!showPlurals && !isVariant && (
                  <OutlineButton
                    Icon={TollIcon}
                    text="Plurals"
                    onClick={() => setShowPlurals(true)}
                    disabled={metadataDisabled || !isComponentEditEnabled}
                  />
                )}
                {!showVariantPlurals && isVariant && (
                  <OutlineButton
                    Icon={TollIcon}
                    text="Plurals"
                    onClick={() => setShowVariantPlurals(true)}
                    disabled={!isComponentEditEnabled || isLockedProject}
                  />
                )}
              </div>
              {(showAssign || origComp.assignee) && (
                <div className={style.assignArea}>
                  <CompactLabel Icon={AccountCircleIcon} text="Assign" />
                  <UserSelect
                    placeholder="No teammate assigned"
                    setSelectedUserId={(option) => setAssigneeId(option)}
                    selectedUserId={assigneeId}
                    users={formattedUserOptions}
                    disabled={!isComponentEditEnabled || isVariant || metadataDisabled}
                  />
                </div>
              )}
              {(showNotes || (notes && notes.length > 0)) && (
                <div className={style.notesArea}>
                  <CompactLabel Icon={InfoOutlinedIcon} text="Notes" />
                  <div className={style.textBoxWrapper}>
                    <TextareaAutosize
                      data-testid="notes-input"
                      placeholder="No notes written."
                      disabled={!isComponentEditEnabled || isVariant || metadataDisabled}
                      value={notes ? notes : ""}
                      onChange={handleNotesChange}
                      maxRows={8}
                    />
                  </div>
                </div>
              )}
            </div>
            {(showTags || origComp.tags.length > 0) && (
              <div className={style.tagsArea}>
                <CompactLabel Icon={LocalOfferIcon} text="Tags" />
                <TagInput
                  tags={tags}
                  disabled={!isComponentEditEnabled || isVariant || metadataDisabled}
                  inputAttributes={{
                    disabled: !isComponentEditEnabled || isVariant,
                  }}
                  onDeleteTag={isComponentEditEnabled ? onDeleteTag : () => {}}
                  onAddTag={onAddTag}
                  tagSuggestions={tagSuggestions}
                />
              </div>
            )}
            <div className={style.buttons}>
              <ButtonSecondary
                text="Cancel"
                onClick={onCancelButtonClick}
                disabled={cancelButtonDisabled}
                className={cancelButtonClass}
              />
              <ButtonPrimary
                data-testid="save-button"
                text={submitButtonText}
                onClick={onSubmitButtonClick}
                disabled={submitButtonDisabled}
                className={submitButtonClass}
              />
            </div>
          </div>
          {Boolean(commentEditor) && !isMergedBranch && <>{commentEditor}</>}
        </div>
      )}
      <VariablesPanel
        isVariant={isVariant}
        variables={isVariant ? variantInstance?.variables || [] : origComp?.variables || []}
      />
      {((origComp && origComp.apiID) || selectedApiID) && (
        <div>
          <div className={style.idSection} data-tour={INTERCOM_ATTR.COMPONENT_ID}>
            <CompactLabel
              text={isProjView ? "Text Item ID" : "Component ID"}
              Icon={CodeIcon}
              className={style.idLabel}
            />
            <ApiID
              id={isProjView ? origComp.apiID : selectedApiID}
              canEdit={manualApiIdEditsAllowed}
              onEdit={saveTextIdEdit}
              isDuplicateId={(text) => allComponentsCache.current.some((comp) => comp.apiID === text)}
              projectLevel={false}
            />
          </div>
          {isProjView && (
            <div className={style.idSection}>
              <CompactLabel text="Component ID" Icon={ImportContactsIcon} className={style.compIdLabel} />
              {isComponentNameLoading ? (
                <Spinner size={20} marginTop={0} centered={false} />
              ) : (
                <ApiID
                  id={compApiID}
                  canEdit={false}
                  onEdit={saveTextIdEdit}
                  isDuplicateId={(text) => [].includes(text)}
                  projectLevel={false}
                />
              )}
            </div>
          )}
        </div>
      )}
      {componentModalOpen && (
        <ComponentModal
          onHide={toggleComponentModal}
          handleDocUpdate={handleDocUpdate}
          onSubmit={swapCompAndCloseModal}
          fetchCompInfo={fetchCompInfo}
          comp={origComp}
          isSwap
          frameVariants={frameVariants}
          selectedGroupId={selectedGroupId}
          shouldComponentizeDuplicates={false}
          duplicateComps={[]}
          onSingleAttach={onSingleAttach}
          onMultiAttach={onMultiAttach}
        />
      )}
      {editNameModalOpen && (
        <EditCompNameModal
          compId={selectedId}
          onHide={toggleEditNameModal}
          fetchHistory={fetchHistory}
          fetchAllComps={fetchAllComps}
          name={selectedCompName ? selectedCompName : compName}
          componentType={componentType}
          postRenameCallback={postRenameCallback}
        />
      )}
    </div>
  );
};

export default EditWSComp;
