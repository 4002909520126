import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

interface Props {
  text: string;

  className?: string;
}

const BetaBadge = ({ text, className }: Props) => (
  <span className={classNames(style.betaBadge, className)}>{text}</span>
);

export default BetaBadge;
