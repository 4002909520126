import { isNonEmptyArray } from "@shared/utils/array";
import { atom } from "jotai";
import { soon, soonAll } from "jotai-derive";
import { buildDittoClient } from "../../client/buildDittoClient";
import { ILibraryComponentFolder } from "../../types/LibraryComponentFolder";
import asyncMutableDerivedAtom from "./asyncMutableDerivedAtom";
import batchedAsyncAtomFamily from "./batchedAsyncAtomFamily";

export function createLibraryComponentFoldersStore(client: ReturnType<typeof buildDittoClient>) {
  /**
   * The source of truth for all library components.
   */
  const { familyAtom: libraryComponentFolderFamilyAtom, resetAtom: resetLibraryComponentFolderFamilyActionAtom } =
    batchedAsyncAtomFamily<ILibraryComponentFolder>({
      asyncFetchRequest: async (get, ids) => {
        const data = await client.libraryComponentFolder.getLibraryComponentFolders({ folderIds: ids });
        return data.folders;
      },
      getId: (item) => item._id.toString(),
      debugPrefix: "Library Component",
      throttleOptions: {
        leading: true,
      },
    });

  const { valueAtom: _libraryComponentFoldersListAtom, refreshAtom: refreshLibraryComponentFoldersListAtom } =
    asyncMutableDerivedAtom<string[]>({
      loadData: async () => {
        const data = await client.libraryComponentFolder.getLibraryComponentFolders({ fields: "_id" });
        return data.folders.map((folder) => folder._id);
      },
    });

  const libraryComponentFoldersListAtom = atom<
    ILibraryComponentFolder[] | Promise<ILibraryComponentFolder[]>,
    [string[] | Promise<string[]> | ((prev: string[]) => string[] | Promise<string[]>)],
    void
  >(
    (get) => {
      return soon(get(_libraryComponentFoldersListAtom), (ids) => {
        const promises = ids.map((id) => get(libraryComponentFolderFamilyAtom(id)));
        if (!isNonEmptyArray(promises)) return [];
        return soon(soonAll(promises), (folders) => folders);
      });
    },
    async (get, set, ids: string[] | Promise<string[]>) => {
      set(_libraryComponentFoldersListAtom, ids);
    }
  );

  const handleLibraryComponentFolderCreatedActionAtom = atom(
    null,
    async (get, set, args: { newFolderId: string; newFolderParentId: string | null }) => {
      const { newFolderId } = args;
      const folderIds = await get(_libraryComponentFoldersListAtom);
      if (folderIds.some((folderId) => folderId === newFolderId)) {
        return;
      }
      set(_libraryComponentFoldersListAtom, [...folderIds, newFolderId]);
    }
  );

  return {
    libraryComponentFolderFamilyAtom,
    resetLibraryComponentFolderFamilyActionAtom,
    libraryComponentFoldersListAtom,
    refreshLibraryComponentFoldersListAtom,
    handleLibraryComponentFolderCreatedActionAtom,
  };
}
