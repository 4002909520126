import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

import SeparatorDot from "../../atoms/SeparatorDot";
import SeparatorLine from "../../atoms/SeparatorLine";
import Text from "../../atoms/Text";
import { useNativeProps } from "../../useNativeProps";

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  action?: string;
  onClickBody?: () => void;
  onClickAction?: () => void;
  onClickClose?: () => void;
}

export function ToastBase(props: IProps) {
  const { action, onClickBody, onClickAction, onClickClose, ...rest } = props;

  const nativeProps = useNativeProps<HTMLDivElement, typeof rest>(props, {
    action: true,
    onClickBody: true,
    onClickAction: true,
    onClickClose: true,
    showCloseButton: true,
  });

  const _onClickAction = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClickAction) {
      e.stopPropagation();
      onClickAction();
    }
  };

  const _onClickClose = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    if (onClickClose) {
      e.stopPropagation();
      onClickClose();
    }
  };

  return (
    <div {...nativeProps} className={classNames(style.ToastBaseWrapper, props.className)} data-testid="toast-base">
      <div className={classNames(style.body, { [style.clickable]: !!props.onClickBody })} onClick={props.onClickBody}>
        {props.children}
        {props.action && (
          <>
            <SeparatorDot spacePx={6} />
            <Text
              className={classNames(style.action, { [style.clickable]: !!props.onClickAction })}
              onClick={_onClickAction}
              weight="strong"
              color="invert"
            >
              {props.action}
            </Text>
          </>
        )}
        {props.onClickClose && (
          <Text className={classNames(style.close, style.clickable)}>
            <SeparatorLine color="border-invert" spacePx={8} />{" "}
            <CloseIcon onClick={_onClickClose} className={classNames(style.closeIcon, style.clickable)} />
          </Text>
        )}
      </div>
    </div>
  );
}

export default ToastBase;
