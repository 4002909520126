import { detailsPanelProjectStateAtom } from "@/stores/ProjectSelection";
import TabGroup from "@ds/molecules/TabGroup";
import { useAtom } from "jotai";
import { Suspense, useMemo } from "react";
import ActivityPanel from "../ActivityPanel";
import CommentsPanel from "../CommentsPanel";
import style from "./style.module.css";

const tabs = [
  {
    id: "ACTIVITY",
    label: "Activity",
    Content: ActivityPanel,
  },
  {
    id: "COMMENTS",
    label: "Comments",
    Content: CommentsPanel,
  },
];

function ProjectDetailsPanel() {
  const [selectedTab, setSelectedTab] = useAtom(detailsPanelProjectStateAtom);

  const selectedTabValue = useMemo(() => {
    let matchingTab = tabs.find((tab) => tab.id === selectedTab);
    if (matchingTab) {
      return matchingTab.id;
    }
    return "EDIT";
  }, [selectedTab]);

  return (
    <div className={style.wrapper}>
      <Suspense fallback={<></>}>
        <TabGroup
          value={selectedTabValue}
          onChange={setSelectedTab}
          tabs={tabs}
          className={style.tabRoot}
          triggerClassName={style.tabTrigger}
          size="small"
        />
      </Suspense>
    </div>
  );
}

export default ProjectDetailsPanel;
