import { ALL_FILTER_KEYS, FilterKey } from "@ds/organisms/FilterBar";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { atom } from "jotai";
import { atomWithLocation } from "jotai-location";
import { atomFamily } from "jotai/utils";

const SEARCH_PARAM = "search";
const DESIGN_PREVIEW_PARAM = "designPreview";

export const locationAtom = atomWithLocation();

/**
 * Atom that represents the search query in the URL.
 */
export const searchAtom = atomWithURLStorage(SEARCH_PARAM, locationAtom, { isString: true });

/**
 * Atom that represents whether the user is currently searching.
 */
export const isSearchingAtom = atom((get) => get(searchAtom)?.length || 0 > 0);

/**
 * Atom that represents the design preview URL in the URL.
 */
export const designPreviewURLAtom = atomWithURLStorage(DESIGN_PREVIEW_PARAM, locationAtom, { isString: true });

/**
 * Atom that represents whether the design preview is toggled.
 */
export const designPreviewToggledAtom = atom(
  (get) => {
    const urlValue = get(designPreviewURLAtom);
    return urlValue === "true";
  },
  (_get, set, newValue: boolean) => {
    if (newValue) {
      set(designPreviewURLAtom, "true");
    } else {
      set(designPreviewURLAtom, null);
    }
  }
);

// MARK: Filtering

// Atom family for storing the values of the filters in the URL. This lets us abstract away the logic of storing
// and syncing the values of the filters in the URL.
export const selectedFiltersValuesAtomFamily = atomFamily((key: FilterKey) => {
  const urlStorageAtom = atomWithURLStorage(key, locationAtom);
  urlStorageAtom.debugLabel = `Selected Filters (${key})`;

  return urlStorageAtom;
});

// Atom which reduces the selected filters into a list of FilterKey values, and allows filters to be added or removed
export const selectedFiltersAtom = atom(
  // The getter loops over all our possible filter keys and checks if each one has a value stored in the atom family
  // (i.e., if the key has any value in the URL)
  (get) => {
    const selectedFilterKeys: FilterKey[] = [];
    for (const key of ALL_FILTER_KEYS) {
      const filterAtom = selectedFiltersValuesAtomFamily(key);
      const filterValue = get(filterAtom);

      if (filterValue !== null && filterValue !== undefined) {
        selectedFilterKeys.push(key);
      }
    }
    return selectedFilterKeys;
  },
  // The setter takes an array of filter keys. We loop over *all* possible filter keys -- if the setter has the key
  // present, we make sure that the atom family at least has a default value. If the setter doesn't have the key
  // present, we remove the key from the atom family (removing it from the URL)
  (get, set, newSelectedFilterKeys: FilterKey[]) => {
    const selectedFilterKeysSet = new Set(newSelectedFilterKeys);

    for (const key of ALL_FILTER_KEYS) {
      const filterAtom = selectedFiltersValuesAtomFamily(key);

      if (!selectedFilterKeysSet.has(key)) {
        set(filterAtom, null);
      }

      const filterValue = get(filterAtom);
      if (selectedFilterKeysSet.has(key) && (filterValue === null || filterValue === undefined)) {
        set(filterAtom, []);
      }
    }
  }
);
