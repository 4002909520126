import { libraryItemsAtom, libraryItemsCountAtom, libraryItemsVirtualizerAtom } from "@/stores/Library";
import VirtualizedList from "@ds/atoms/VirtualizedList";
import logger from "@shared/utils/logger";
import { defaultRangeExtractor } from "@tanstack/react-virtual";
import { atom, useAtom, useAtomValue } from "jotai";
import { Suspense, useCallback, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import LibraryComponentListItem, { LibraryComponentListItemFallback } from "../LibraryComponentListItem";
import LibraryItemsHeader from "../LibraryItemsHeader";
import LibraryItemsListEmptyState from "../LibraryItemsListEmptyState";
import style from "./style.module.css";

function LibraryItemsList() {
  return (
    <div className={style.libraryItemsListContainer}>
      <Suspense fallback={<LibraryItemsListFallback />}>
        <LibraryListItemsContainer />
      </Suspense>
    </div>
  );
}

function LibraryItemsListFallback() {
  return (
    <div className={style.libraryListFallback}>
      <Skeleton count={20} height={80} borderRadius={8} />
    </div>
  );
}

function LibraryListItemsContainer() {
  const libraryItemsCount = useAtomValue(libraryItemsCountAtom);

  if (libraryItemsCount === 0) {
    return <LibraryItemsListEmptyState />;
  } else {
    return (
      <>
        <Suspense fallback={<LibraryItemsListFallback />}>
          <LibraryItemsListContent />
        </Suspense>
      </>
    );
  }
}

function LibraryItemsListContent() {
  const _libraryItems = useAtomValue(libraryItemsAtom);
  const isScrolledAtomRef = useRef(atom(false));

  const [isScrolled, setIsScrolled] = useAtom(isScrolledAtomRef.current);

  const libraryItemsWithHeader = useMemo(() => {
    return [
      {
        type: "header",
        _id: "header",
        label: "Components",
      } as const,
      ..._libraryItems,
    ];
  }, [_libraryItems]);

  /**
   * This function is used to determine which indexes should be rendered in the virtualized list.
   * It is primarily used to support sticky indexes that should always be rendered even after scrolling away.
   */
  const rangeExtractor = useCallback((range) => {
    const next = new Set([0, ...defaultRangeExtractor(range)]);
    let result = [...next].sort((a, b) => a - b);
    return result;
  }, []);

  return (
    <VirtualizedList
      className={style.libraryVirtualList}
      itemClassName={style.virtualItem}
      id={"LIBRARY_CONTENT"}
      items={libraryItemsWithHeader}
      virtualizerAtom={libraryItemsVirtualizerAtom}
      virtualizeOptions={{
        onChange: (instance) => {
          setIsScrolled((instance.scrollElement?.scrollTop || 0) > 0);
        },
        estimateSize: () => 300,
        overscan: 10,
        paddingEnd: 24,
        getItemKey: (index) => libraryItemsWithHeader[index]._id,
        rangeExtractor,
      }}
      getIsSticky={(index) => index === 0}
    >
      {({ item, isScrolling }) => {
        switch (item.type) {
          case "header":
            return <LibraryItemsHeader isScrolled={isScrolled} />;
          case "component":
            return (
              <Suspense fallback={<LibraryComponentListItemFallback />}>
                <LibraryComponentListItem item={item} isScrolling={isScrolling} />
              </Suspense>
            );
          default:
            logger.error("Unknown library item type", { context: { item } }, new Error("Unknown library item type"));
            return null;
        }
      }}
    </VirtualizedList>
  );
}

export default LibraryItemsList;
