import classnames from "classnames";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Text from "../Text";
import style from "./index.module.css";

export interface IBreadcrumbLinkProps {
  className?: string;
  style?: React.CSSProperties;
  label: string;
  value: string;
}

export interface IBreadcrumbLinkTextProps extends Omit<IBreadcrumbLinkProps, "value"> {
  handleClick: () => void;
}

export function BreadcrumbLinkText(props: IBreadcrumbLinkTextProps) {
  return (
    <Text
      color="secondary"
      className={classnames(style.BreadcrumbWrapper, props.className)}
      onClick={props.handleClick}
    >
      {props.label}
    </Text>
  );
}

function BreadcrumbLinkDefault(props: IBreadcrumbLinkProps) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(props.value);
  }, [props.value, history]);

  return <BreadcrumbLinkText {...props} handleClick={handleClick} />;
}

export default BreadcrumbLinkDefault;
