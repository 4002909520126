import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IInstancesBannerBaseProps {
  className?: string;
  content: string | React.ReactNode;
}

interface IInstancesBannerActionButtonProps extends IInstancesBannerBaseProps {
  actionText: string;
  onActionClick: () => void;
  actionDisabled?: boolean;
}

type IInstancesBannerProps = IInstancesBannerBaseProps | IInstancesBannerActionButtonProps;

const hasActionButton = (props: IInstancesBannerProps): props is IInstancesBannerActionButtonProps => {
  const { actionText, onActionClick } = props as IInstancesBannerActionButtonProps;
  return !!actionText && !!onActionClick;
};

export function InstancesBanner(props: IInstancesBannerProps) {
  const renderButton = hasActionButton(props);

  return (
    <div className={classNames(style.InstancesBannerWrapper, props.className)}>
      {typeof props.content === "string" ? (
        <Text color="secondary" weight="medium" size="small">
          {props.content}
        </Text>
      ) : (
        props.content
      )}

      {renderButton && (
        <Button level="secondary" onClick={props.onActionClick} size="micro" disabled={props.actionDisabled}>
          <Text color="action" size="micro">
            {props.actionText}
          </Text>
        </Button>
      )}
    </div>
  );
}

export default InstancesBanner;
