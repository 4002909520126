import { ITipTapRichText } from "@shared/types/TextItem";

export const WEBSOCKET_EVENTS = {
  NEW_CONNECTION: "NewConnection",
  NEW_DOC_SUBSCRIPTION: "NewDocSubscription",
  NEW_WS_COMPS_SUBSCRIPTION: "NewWsCompsSubscription",
  GROUP_UPDATED: "GroupUpdated",
  COMPONENT_IMPORT: "ComponentImport",
  TEXT_ITEMS_UPDATED: "TextItemsUpdated",
  COMPONENT_COUNT_UPDATED: "ComponentCountUpdated",
  UPSERT_DOC_COMMENT: "UpsertDocComment",
  UPSERT_WS_COMPS_COMMENT: "UpsertWsCompsComment",
  FIGMA_AUTH_SUBSCRIPTION: "FigmaAuthSubscription",
  FIGMA_AUTH_REQUEST: "FigmaAuthRequest",
  NOTIFICATION: "Notification",
  FIGMA_FILE_LINKED: "FigmaFileLinked",
  DRAFT_GROUP_UPDATED: "DraftGroupUpdated",
  ONBOARDING_WORKSPACE_CHECKLIST_UPDATED: "OnboardingWorkspaceChecklistUpdated",
  SUGGESTIONS: "suggestions",
  PING: "Ping",
  CONNECT_PROJECT_TO_SLACK: "ConnectProjectToSlack",
  CONNECT_LIBRARY_TO_SLACK: "ConnectLibraryToSlack",
  RESYNC_PROGRESS: "ResyncProgress",
  NEW_APP_VERSION: "NewAppVersion",
  CONCURRENT_USER_CONNECTED: "ConcurrentUserConnected",
  CONCURRENT_USER_DISCONNECTED: "ConcurrentUserDisconnected",
  CONCURRENT_USERS_UPDATED: "ConcurrentUsersUpdated",
  CONCURRENT_USERS_KEEP_ALIVE: "ConcurrentUsersKeepAlive",
};

// events that don't require authentication
export const WEBSOCKET_EVENTS_UNPROTECTED = [WEBSOCKET_EVENTS.FIGMA_AUTH_SUBSCRIPTION];

export const REDIS_CHANNELS = {
  TEXT_ITEM_UPDATE_CHANNEL: "text-item-update-channel",
  GROUP_UPDATE_CHANNEL: "group-update-channel",
  UPSERT_DOC_COMMENT_CHANNEL: "upsert-doc-comment-channel",
  UPSERT_WS_COMPS_COMMENT_CHANNEL: "upsert-ws-comps-comment-channel",
  COMPONENT_COUNT_CHANNEL: "component-count-channel",
  COMPONENT_IMPORT_CHANNEL: "component-import-channel",
  // Used to notify that a figma file has been linked to a ditto project (doc)
  FIGMA_FILE_LINKED_CHANNEL: "figma-file-linked-channel",
  DRAFT_GROUP_UPDATED: "draft-group-updated",
  NOTIFICATION_CHANNEL: "notification",
  ONBOARDING_WORKSPACE_CHECKLIST_CHANNEL: "onboarding-workspace-checklist-channel",
  PROJECT_GROUP_SUGGESTIONS_CHANNEL: "project-group-suggestions-channel",
  CONNECT_PROJECT_TO_SLACK: "connect-project-to-slack",
  CONNECT_LIBRARY_TO_SLACK: "connect-library-to-slack",
  RESYNC_PROGRESS_CHANNEL: "resync-progress-channel",
  NEW_APP_VERSION_CHANNEL: "new-app-version-channel",
};

export const DEFAULT_RICH_TEXT: ITipTapRichText = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "",
        },
      ],
    },
  ],
};

export const NS_BETA_URL_PATH_NAME = "projects-beta";

/**
 * This value is used when unsetting plugin data on a node.
 * We can't simply unset the plugin data because if the text layer is an instance of a Figma Component,
 * the plugin data will be copied from the main component.
 * Instead, we set the plugin data to a value that we can check for.
 *
 * NOTE: You need to check for this value anywhere you check for plugin data in the plugin or during figma cache updates.
 * It should be treated as if the plugin data is unset.
 */
export const UNDEFINED_PLUGIN_DATA = "DITTO_UNDEFINED_PLUGIN_DATA";

export const COMPONENT_JSON_MIME_TYPE = "ditto/component-json";
export const TEXT_ITEM_JSON_MIME_TYPE = "ditto/text-item-json";

/**
 * The key used to store the text item _id in the plugin data of a text node.
 */
export const PLUGIN_KEY_TEXT_ITEM_ID = "textItemId";
/**
 * The key used to store the library component _id in the plugin data of a text node.
 */
export const PLUGIN_KEY_LIBRARY_COMPONENT_ID = "libraryComponentId";
