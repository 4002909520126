import BetaBadge from "@/components/BetaBadge";
import { libraryCreateComponentModalIsOpenAtom, libraryCreateFolderModalIsOpenAtom } from "@/stores/Library";
import Text from "@ds/atoms/Text";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import classNames from "classnames";
import { useSetAtom } from "jotai";
import { useCallback } from "react";
import style from "./style.module.css";

function LibraryItemsListEmptyState() {
  const setLibraryCreateComponentModalIsOpen = useSetAtom(libraryCreateComponentModalIsOpenAtom);
  const setLibraryCreateFolderModalIsOpen = useSetAtom(libraryCreateFolderModalIsOpenAtom);

  const onCreateComponent = useCallback(() => {
    setLibraryCreateComponentModalIsOpen(true);
  }, [setLibraryCreateComponentModalIsOpen]);

  const onCreateFolder = useCallback(() => {
    setLibraryCreateFolderModalIsOpen(true);
  }, [setLibraryCreateFolderModalIsOpen]);

  return (
    <div className={style.libraryItemsListEmptyStateContainer}>
      <div className={style.titleContainer}>
        <Text weight="strong" size="large">
          Create your first components
        </Text>
        <Text color="secondary" inline className={style.textCentered}>
          Build a library of text you can reuse across design projects and in development. Work faster and scale your
          content. <a href={LEARN_MORE_URL.LIBRARY}>Learn more</a>
        </Text>
      </div>
      <div className={style.actionsContainer}>
        <div className={style.actionCard} onClick={onCreateComponent}>
          <div className={style.actionCardContent}>
            <Text weight="medium">Draft a new component</Text>
            <Text color="secondary" size="small">
              Create a new text component from scratch
            </Text>
          </div>
        </div>
        <div className={style.actionCard}>
          <div className={style.actionCardContent} onClick={onCreateFolder}>
            <Text weight="medium">Create a folder</Text>
            <Text color="secondary" size="small">
              Organize components into folders
            </Text>
          </div>
        </div>
        <div className={classNames(style.actionCard, style.disabled)}>
          <div className={style.actionCardContent}>
            <Text weight="medium" color="secondary">
              Import a string file&nbsp;
              <BetaBadge className={style.comingSoonButton} text="Coming soon" />
            </Text>
            <Text color="secondary" size="small">
              Generate components from a spreadsheet or string file
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LibraryItemsListEmptyState;
