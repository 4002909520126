import { PartialKeys, useVirtualizer, Virtualizer, VirtualizerOptions } from "@tanstack/react-virtual";
import classNames from "classnames";
import { Getter, PrimitiveAtom, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import React, { useCallback, useEffect, useState } from "react";
import Scrollbar from "../../molecules/Scrollbar";
import style from "./index.module.css";

interface IProps<T> {
  /**
   * The unique identifier for the component. Needed to handle scrolling and for complex scroll scenarios.
   */
  id: string;

  /**
   * The data items to render in the list.
   */
  items: T[];

  /**
   * The component that renders each item in the list.
   */
  children: (props: {
    isScrolling: boolean;
    item: T;
    index: number;
    options: Pick<VirtualizerOptions<Element, Element>, "getScrollElement" | "initialOffset" | "scrollMargin">;
    virtualizerAtom: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>;
  }) => React.ReactNode;

  /**
   * When the list is nested inside another virtualized list, this prop should be set to true.
   */
  isNested?: boolean;

  /**
   * A function that returns whether the item should be sticky.
   */
  getIsSticky?: (index: number) => boolean;

  /**
   * The atom that stores the virtualizer for this list. Used to store nested virtualizer instances as well.
   */
  virtualizerAtom: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>;

  /**
   *
   */
  virtualizeOptions: PartialKeys<
    VirtualizerOptions<Element, Element>,
    "observeElementRect" | "observeElementOffset" | "scrollToFn" | "count" | "getScrollElement"
  >;

  itemClassName?: string;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Used to calculate the easing for the scroll animation in and out.
 * A description and example of this easing in/out function can be found here: https://easings.net/#easeInOutQuint
 * You can see some examples of other easing functions here: https://easings.net/
 */
function easeInOutQuint(t: number) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

// The default duration for the scroll animation. Currently not configurable per instance, but can be changed here if needed. NOTE: Will effect all instances.
const DEFAULT_SCROLL_DURATION = 1000;

/**
 * A custom scroll function that uses a custom easing function to animate the scroll.
 * This function is needed as smooth scrolling is not supported when using dynamic heights in the virtualizer.
 *
 */
function useCustomScrollFunction() {
  const scrollingRef = React.useRef<number>();
  const previousScrollOffsetRef = React.useRef<number>(-1);
  const [isScrolling, setIsScrolling] = useState(false);

  const scrollToFn: VirtualizerOptions<any, any>["scrollToFn"] = React.useCallback((offset, canSmooth, instance) => {
    if (
      scrollingRef.current &&
      Date.now() < scrollingRef.current + DEFAULT_SCROLL_DURATION &&
      offset === previousScrollOffsetRef.current
    )
      return;

    previousScrollOffsetRef.current = offset;

    if (scrollingRef.current && Date.now() < scrollingRef.current + DEFAULT_SCROLL_DURATION) return;

    const offsetWithAdjustments = offset + (canSmooth.adjustments || 0);
    if (offsetWithAdjustments === instance.scrollOffset) return;

    // Set the current scrollingRef to the current time if it is not already set.
    if (!scrollingRef.current) scrollingRef.current = Date.now();

    // The scrollElement could be null, in which case we want to return early.
    if (!instance.scrollElement) return;

    const start = instance.scrollElement.scrollTop;
    const startTime = (scrollingRef.current = Date.now());
    setIsScrolling(true);

    /**
     * The run function that animates the scroll. Runs recursively until the scroll is complete.
     * Uses requestAnimationFrame to animate the scroll smoothly.
     */
    function run() {
      if (scrollingRef.current !== startTime) return;
      const now = Date.now();
      const elapsed = now - startTime;
      const timeProgress = easeInOutQuint(Math.min(elapsed / DEFAULT_SCROLL_DURATION, 1));
      const interpolated = start + (previousScrollOffsetRef.current - start) * timeProgress;

      if (instance.scrollElement.scrollTop === interpolated) {
        previousScrollOffsetRef.current = -1;
        setIsScrolling(false);
        return;
      }

      // This line is what actually causes the scroll to happen.
      (instance.scrollElement as HTMLDivElement).scrollTo({
        top: interpolated,
        behavior: "instant",
      });

      if (elapsed < DEFAULT_SCROLL_DURATION) {
        requestAnimationFrame(run);
      } else {
        // Jump to final location if the scroll is finished
        (instance.scrollElement as HTMLDivElement).scrollTo({
          top: previousScrollOffsetRef.current,
          behavior: "instant",
        });
        previousScrollOffsetRef.current = -1;
        setIsScrolling(false);
      }
    }

    requestAnimationFrame(run);
  }, []);

  return {
    scrollToFn,
    isScrolling,
  };
}

export const VirtualizedList = function VirtualizedList<T>(props: IProps<T>) {
  const listRef = React.useRef<HTMLDivElement>(null);

  const setAtomToVirtualizer = useAtomCallback(
    useCallback(
      (get: Getter, set: Setter, virtualizer: Virtualizer<Element, Element>) => {
        set(props.virtualizerAtom, (prev) => {
          return { ...prev, [props.id]: virtualizer };
        });
      },
      [props.id, props.virtualizerAtom]
    )
  );

  const scrollProps = useCustomScrollFunction();

  const virtualizer = useVirtualizer({
    ...props.virtualizeOptions,
    count: props.items.length,
    getScrollElement: props.virtualizeOptions.getScrollElement ?? (() => listRef.current),
    scrollToFn: scrollProps.scrollToFn,
  });

  useEffect(
    function keepVirtualizerInSync() {
      setAtomToVirtualizer(virtualizer);
    },
    [setAtomToVirtualizer, virtualizer]
  );

  const virtualItems = virtualizer.getVirtualItems();

  let translateY = virtualItems[0]?.start ?? 0;

  if (props.isNested) {
    translateY -= virtualizer.options?.scrollMargin ?? 0;
  }

  return (
    <Scrollbar
      scrollContentRef={listRef}
      style={props.style}
      className={classNames(style.VirtualizedListWrapper, props.className)}
      data-testid="virtualized-list"
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
          overflowAnchor: "none",
        }}
      >
        {virtualItems.map((virtualRow) => (
          <div
            key={virtualRow.key}
            data-index={virtualRow.index}
            ref={virtualizer.measureElement}
            className={props.itemClassName}
            style={{
              position: props.getIsSticky?.(virtualRow.index) ? "sticky" : "absolute",
              ...(props.getIsSticky?.(virtualRow.index) ? { zIndex: 2 } : {}),
              top: 0,
              left: 0,
              right: 0,
              width: "100%",
              transform: `translateY(${virtualRow.start}px)`,
              overflowAnchor: "none",
            }}
          >
            {props.children({
              isScrolling: scrollProps.isScrolling,
              item: props.items[virtualRow.index],
              virtualizerAtom: props.virtualizerAtom,
              options: {
                getScrollElement: virtualizer.options.getScrollElement,
                initialOffset: virtualizer.scrollOffset ?? 0,
                scrollMargin: virtualRow.start,
              },
              index: virtualRow.index,
            })}
          </div>
        ))}
      </div>
    </Scrollbar>
  );
};

export default VirtualizedList;
