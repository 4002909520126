import useSegment from "@/hooks/useSegment";
import {
  deleteTextItemsActionAtom,
  editableHasChangesAtom,
  resetTextItemVariantChangesActionAtom,
  stopInlineEditingActionAtom,
} from "@/stores/Editing";
import { designPreviewToggledAtom } from "@/stores/Location";
import { modalAtom } from "@/stores/Modals";
import { projectIdAtom } from "@/stores/Project";
import {
  derivedOnlySelectedTextItemAtom,
  resetCommentEditsActionAtom,
  selectedTextItemIdsAtom,
  selectedTextItemsCountAtom,
  setSelectedTextIdsActionAtom,
  singleSelectedComponentAtom,
} from "@/stores/ProjectSelection";
import DeleteTextItem from "@/views/NS/components/DeleteItem";
import DeveloperId from "@/views/NS/components/DeveloperId";
import Comments from "@/views/NS/ProjectNS/components/DetailsPanel/Comments";
import Library from "@/views/NS/ProjectNS/components/DetailsPanel/Library";
import MetadataPanel from "@/views/NS/ProjectNS/components/DetailsPanel/Metadata";
import InstancesBanner from "@ds/molecules/InstancesBanner";
import * as SegmentEvents from "@shared/segment-event-names";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { Suspense } from "react";

interface TextItemDetailsProps {
  className?: string;
}

const TextItemDetails = (props: TextItemDetailsProps) => {
  const { track } = useSegment();
  const [designPreviewToggled, setDesignPreviewToggled] = useAtom(designPreviewToggledAtom);
  const setSelectedTextItemIds = useSetAtom(setSelectedTextIdsActionAtom);
  const setEditableHasChanges = useSetAtom(editableHasChangesAtom);
  const resetTextItemVariantChanges = useSetAtom(resetTextItemVariantChangesActionAtom);
  const resetCommentEdits = useSetAtom(resetCommentEditsActionAtom);
  const stopInlineEditing = useSetAtom(stopInlineEditingActionAtom);
  const onlySelectedTextItem = useAtomValue(derivedOnlySelectedTextItemAtom);
  const component = useAtomValue(singleSelectedComponentAtom);
  const selectedIds = useAtomValue(selectedTextItemIdsAtom);
  const selectedTextItemsCount = useAtomValue(selectedTextItemsCountAtom);

  const projectId = useAtomValue(projectIdAtom);
  const deleteTextItemAction = useSetAtom(deleteTextItemsActionAtom);

  const setModal = useSetAtom(modalAtom);

  const instanceCount = onlySelectedTextItem?.integrations?.figmaV2?.instances?.length ?? 0;

  function showDeleteTextItemModal() {
    const isBulkDelete = selectedTextItemsCount > 1;
    setModal({
      headline: isBulkDelete ? `Delete ${selectedTextItemsCount} text items?` : "Delete text item?",
      content: isBulkDelete
        ? `All selected text items and their data will be deleted, and linked text layers
          will be unlinked. This can't be undone.`
        : `The text item and all of its data will be deleted, and linked text layers
          will be unlinked. This can't be undone.`,
      actionText: "Delete",
      onAction: deleteTextItem,
      onOpenChange: (open) => {
        if (open) return;
        setModal(null);
      },
      type: "danger",
    });
  }

  const deleteTextItem = async () => {
    if (projectId === null || selectedTextItemsCount === 0) {
      setModal(null);
      return;
    }

    deleteTextItemAction(selectedIds);

    /**
     * Discard all in-progress edits to the deleted text items
     */
    setEditableHasChanges(false);
    resetTextItemVariantChanges();
    resetCommentEdits();
    stopInlineEditing({ skipConfirmation: true });
    setSelectedTextItemIds([]);
    setModal(null);
  };

  const previewBannerClick = () => {
    setDesignPreviewToggled(true);

    track({ event: SegmentEvents.DESIGN_PREVIEW_OPENED, properties: { from: "banner", version: "NS" } });
  };

  return (
    <>
      {instanceCount > 0 && (
        <Suspense fallback={<div>Loading Instances...</div>}>
          {!designPreviewToggled && (
            <InstancesBanner
              content={`${instanceCount} instance${instanceCount === 1 ? "" : "s"} in design`}
              actionText="Preview"
              onActionClick={previewBannerClick}
            />
          )}
        </Suspense>
      )}
      <Suspense fallback={<div>Loading Metadata...</div>}>
        <MetadataPanel />
      </Suspense>

      {/* This element should only be rendered if at least one text item is selected  */}
      {selectedTextItemsCount > 0 && (
        <>
          {!component && <Library />}
          <Suspense fallback={<></>}>
            <Comments />
          </Suspense>
          <DeveloperId />
          <DeleteTextItem
            onClick={showDeleteTextItemModal}
            text={selectedTextItemsCount > 1 ? `Delete ${selectedTextItemsCount} text items` : "Delete text item"}
          />
        </>
      )}
    </>
  );
};

export default TextItemDetails;
