import { UserInvitation } from "@/components/permissions/InviteUsersInput/types";
import { IDittoProject, IDittoProjectBlock } from "@shared/types/DittoProject";
import { IFigmaGroup } from "@shared/types/FigmaGroup";
import {
  GetSyncedFigmaPagesResponse,
  ICreateDittoProject,
  ICreateTextItemsFromWebAppProps,
  IDittoProjectData,
  IGetFramePreviewsData,
  IMoveTextItemsAction,
  ISyncedTextNodesMapData,
  ITextItemsMapData,
} from "@shared/types/http/DittoProject";
import { IFigmaSyncRequestBody } from "@shared/types/http/figma";
import { IObjectId } from "@shared/types/lib";
import { ITextItem } from "@shared/types/TextItem";
import { InviteToken } from "../../schema/inviteToken.types";
import { createHttpRequest } from "./lib/createHttpRequest";

export const getProject = createHttpRequest<
  {
    projectId: string;
    projectContentSearchQuery?: string;
    statuses?: string[];
    tags?: string[];
    assignees?: string[];
    pages?: string[];
  },
  IDittoProjectData
>({
  method: "get",
  getConfig({ projectContentSearchQuery, statuses, tags, assignees, pages }) {
    return {
      params: {
        projectContentSearchQuery,
        statuses,
        tags,
        assignees,
        pages,
      },
    };
  },
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
});

export const updateProject = createHttpRequest<
  { projectId: string; projectData: Partial<IDittoProjectData> },
  IDittoProjectData
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
  getConfig({ projectData }) {
    return {
      data: { projectData },
    };
  },
});

export const deleteProject = createHttpRequest<{ projectId: string }, { success: boolean }>({
  method: "delete",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
});

export const inviteUsersToProject = createHttpRequest<
  { projectId: string; invitations: UserInvitation[]; message: string },
  { invites: Pick<InviteToken, "email" | "role" | "permissionGroups" | "data">[] }
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/invite`;
  },
  getConfig({ invitations, message }) {
    return {
      data: {
        invitations,
        message,
      },
    };
  },
});

export const getTextItems = createHttpRequest<{ ids: string[]; projectId: string }, ITextItem[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const createTextItems = createHttpRequest<
  { projectId: string } & ICreateTextItemsFromWebAppProps,
  { textItems: ITextItem[]; block?: IDittoProjectBlock }
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ textItems, addToStart }) {
    return {
      data: {
        textItems,
        addToStart,
        source: "web_app",
      },
    };
  },
});

export const deleteTextItems = createHttpRequest<{ projectId: string; textItemIds: string[] }, ITextItem[]>({
  method: "delete",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ textItemIds }) {
    return {
      data: { textItemIds },
    };
  },
});

export const reorderTextItems = createHttpRequest<
  { projectId: string; actions: IMoveTextItemsAction[] },
  Record<string, string>
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-text-items`;
  },
  getConfig({ actions }) {
    return {
      data: { actions },
    };
  },
});

export const getAllTags = createHttpRequest<{ projectId: string }, string[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/tags`;
  },
  getConfig({ projectId }) {
    return {
      params: { projectId },
    };
  },
});

export const getActivePreviewsJob = createHttpRequest<{ projectId: string }, { jobId: string | null }>({
  method: "get",
  getUrl({ projectId }) {
    return `/jobs/ditto-project-figma-previews/${projectId}`;
  },
});

export const getTopLevelFramesMap = createHttpRequest<{ projectId: string }, Record<string, IFigmaGroup[]>>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/top-level-frames`;
  },
});

export const getFramePreviewsMap = createHttpRequest<{ projectId: string }, IGetFramePreviewsData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/frame-previews`;
  },
});

export const getTextItemsMap = createHttpRequest<{ projectId: string }, ITextItemsMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items-map`;
  },
});

export const getSyncedTextNodesMap = createHttpRequest<{ projectId: string }, ISyncedTextNodesMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/synced-text-nodes-map`;
  },
});

export const createDittoProject = createHttpRequest<ICreateDittoProject, IDittoProject>({
  method: "post",
  url: "/ditto-project/create",
  getConfig: (args) => ({
    data: args,
  }),
});

export const moveProjectToFolder = createHttpRequest<{ projectId: string; folderId: string | null }, IDittoProject>({
  method: "put",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-to-folder`;
  },
  getConfig({ folderId }) {
    return {
      data: { folderId },
    };
  },
});

export const getSyncedFigmaPages = createHttpRequest<{ projectId: string }, GetSyncedFigmaPagesResponse>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/synced-figma-pages`;
  },
});

export const syncFigma = createHttpRequest<IFigmaSyncRequestBody, { jobId: string }>({
  method: "post",
  url: "/figma/sync",
  getUrl({ projectId }) {
    return `/figma/sync/${projectId}`;
  },
  getConfig(data) {
    return { data };
  },
});

export const removeVariant = createHttpRequest<
  { variantId: IObjectId; projectId: IObjectId; textItemIds: IObjectId[] },
  void
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/remove-variant`;
  },
  getConfig({ variantId, textItemIds }) {
    return {
      data: { variantId, textItemIds },
    };
  },
});
