export const ACTIVITY_FILTER_CHANGED = "Activity Filter Changed";
export const ADD_NEW_FIGMA_FRAMES = "Add New Figma Frames";
export const ADVANCED_BRANCHING_ENABLED = "Advanced Branching Enabled";
export const AI_EDITOR_APPLIED_GENERATION = "AI Editor: applied generation";
export const AI_EDITOR_REJECTED_GENERATION = "AI Editor: rejected generation";
export const API_ID_EDITED = "API ID Edited";
export const API_TOKEN_CREATED = "API Token Created";
export const APPLIED_VARIANT_REMOVED_FROM_FRAME = "Applied Variant Removed from Frame";
export const ATTEMPTED_SAML_LOGIN = "Attempted SAML Login";
export const BLOCK_CREATED = "Block Created";
export const BLOCK_ORDER_CHANGED = "Block Order Changed";
export const BLOCK_REMOVED = "Block Removed";
export const BLOCK_RENAMED = "Block Renamed";
export const BRANCH_DISCARDED = "Branch Discarded";
export const BRANCH_MERGED = "Branch Merged";
export const BULK_IGNORE_COMPONENT_MERGE_SUGGESTIONS = "Bulk Ignore Merge Suggestions";
export const BULK_APPROVE_COMPONENT_MERGE_SUGGESTIONS = "Bulk Approve Merge Suggestions";
export const CHECKED_ONBOARDING_STATE = "Checked onboarding state";
export const CHECKFILEIDEXISTS_ERROR = "/checkFileIDExists error";
export const CHECKINSAMPLEPROJECT = "checkInSampleProject";
export const CHECK_FILE_ID = "Check file ID";
export const CLEAR_CURRENT_SELECTION = "clear-current-selection";
export const CLICKED_CONNECT_TO_FIGMA = "Clicked connect to Figma";
export const CLICKED_GO_TO_PROJECT_OF_IMPORTED_FILE = "Clicked Go to Project of Imported File";
export const COMMENT_FILTER_CHANGED = "Comment Filter Changed";
export const COMMENT_ONLY_INFO_CLICKED = "Comment only info clicked";
export const COMMENT_THREAD_REPLIED = "Comment Thread Replied";
export const COMMENT_THREAD_CREATED = "Comment Thread Created";
export const COMMENT_THREAD_RESOLVED = "Comment Thread Resolved";
export const COMMENT_THREAD_RE_OPENED = "Comment Thread Re-opened";
export const COMMENT_THREAD_SUGGESTION_CREATED = "Comment Thread Suggestion Created";
export const COMMENT_THREAD_SUGGESTION_ACCEPTED = "Comment Thread Suggestion Accepted";
export const COMMENT_THREAD_SUGGESTION_REJECTED = "Comment Thread Suggestion Rejected";
export const COMPONENTIZE_DUPLICATES_CLICKED = "Componentize Duplicates Clicked";
export const COMPONENTS_MOVED_TO_FOLDER = "Components Moved to Folder";
export const COMPONENT_API_IDS_REGENERATED = "Component API IDs Regenerated";
export const COMPONENT_API_ID_SETTINGS_CHANGED = "Component API ID Settings Changed";
/**
 * @deprecated Use TEXT_ITEM_ATTACHED_TO_COMPONENT instead
 */
export const COMPONENT_ATTACHED = "Component Attached";
/**
 * Don't reference this directly, you should use the ditto event instead
 */
export const COMPONENT_ATTACHED_TO_TEXT_ITEMS = "Component Attached to Text Items";
export const COMPONENT_DETACHED = "Component Detached";
export const COMPONENT_CATEGORY_CLICKED = "Component Category Clicked";
export const COMPONENT_CRASH = "Component Crash";
export const COMPONENT_FOLDER_CREATED = "Component Folder Created";
export const COMPONENT_FOLDER_DELETED = "Component Folder Deleted";
export const COMPONENT_FOLDER_UPDATED = "Component Folder Updated";
export const COMPONENT_MERGE_SUGGESTION_ACCEPTED = "Component Merge Suggestion Accepted";
export const COMPONENT_MERGE_SUGGESTION_IGNORED = "Component Merge Suggestion Ignored";
export const COMPONENT_MULTI_EDIT_SAVED = "Component multi-edit Saved";
export const COMPONENT_SELECTED_IN_COMPONENT_DROPDOWN = "Component Selected in Component Dropdown";
export const COMPONENT_STATUS_CHANGED = "Component Status Changed";
export const COMPONENTS_STATUS_CHANGED = "Components Status Changed";
export const COMPONENT_SUGGESTION_ACCEPTED_ = "Component Suggestion Accepted ";
export const COMPONENT_SUGGESTION_SELECTED = "Component Suggestion Selected";
export const COMPONENT_SUGGESTION_SELECTED_IN_COMPONENT_DROPDOWN =
  "Component Suggestion Selected in Component Dropdown";
export const COMPONENT_UPDATED = "Component Updated";
export const COMP_LIBRARY_EMPTY_STATE_CREATE_COMPS_FROM_DESIGN_CLICKED =
  "Comp Library Empty State -- Create comps from design clicked";
export const COMP_LIBRARY_EMPTY_STATE_DRAFT_NEW_COMPONENT_CLICKED =
  "Comp Library Empty State -- Draft new component clicked";
export const COMP_LIBRARY_EMPTY_STATE_EXPLORE_SAMPLE_COMPONENTS_CLICKED =
  "Comp Library Empty State -- Explore sample components clicked";
export const COMP_LIBRARY_EMPTY_STATE_IMPORT_STRING_FILE_CLICKED =
  "Comp Library Empty State -- Import string file clicked";
export const CONNECTION_WAITLISTED = "Connection Waitlisted";
export const CONNECT_AN_EXISTING_DITTO_PROJECT_CLICKED = "Connect an existing Ditto project clicked";
export const CONNECT_DRAFT_GROUP = "Connect Draft Group";
export const CONNECT_FRAME_TO_EXISTING_GROUP_CLICKED = "Connect Frame to Existing Group Clicked";
export const CONNECT_PROJECT = "Connect Project";
export const CONTACT_SALES_CLICKED = "Contact Sales Clicked";
export const COPY_CLI_COMMAND = "Copy CLI command";
export const COPY_SETUP_FROM_ANOTHER_GROUP = "Copy Setup from Another Group";
export const COPY_SETUP_FROM_ANOTHER_GROUP_CANCELED = "Copy Setup from Another Group - Canceled";
export const COPY_SETUP_FROM_ANOTHER_GROUP_GROUP_SELECTED = "Copy Setup from Another Group - Group Selected";
export const COPY_SETUP_FROM_ANOTHER_GROUP_PREVIEW_TOGGLED = "Copy Setup from Another Group - Preview Toggled";
export const CREATED_VARIANT_FOLDER = "Created Variant Folder";
export const CREATE_BLOCK_FROM_SELECTED_BUTTON_CLICKED = "Create Block from Selected Button Clicked";
export const CREATE_FOLDER_MODAL_OPENED = "Create Folder Modal Opened";
export const CROWDIN_INTEGRATION_ = "Crowdin Integration ";
export const CSV_IMPORT_MAPPED = "CSV Import: Mapped";
export const DEBUG_CREATE_FIGMA_VARIABLE_COLLECTION = "debug-create-figma-variable-collection";
export const DEBUG_DELETE_ALL_FIGMA_VARIABLE_COLLECTIONS = "debug-delete-all-figma-variable-collections";
export const DELETED_VARIANT_FOLDER = "Deleted Variant Folder";
export const DEVELOPER_MODE_ENABLED = "Developer Mode Enabled";
export const DEVELOPER_PAGE_IMPORT_TO_COMPONENT_LIBRARY_CLICKED =
  "Developers Page -- Import to component library clicked";
export const DISMISSED_RESYNC_WARNING = "Dismissed Resync Warning";
export const DITTO_OVERVIEW_OPENED = "Ditto Overview Opened";
export const DND_MOVE_OUT_OF_GROUP = "DnD -- Move out of group";
export const DND_MOVE_TO_BLOCK = "DnD -- Move to block";
export const DND_MOVE_TO_FOLDER = "DnD -- Move to folder";
export const DND_MOVE_TO_GROUP = "DnD -- Move to group";
export const DOC_NOTIFICATIONS_MARKED_AS_READ = "Doc Notifications Marked as Read";
export const DRAFT_COMPONENT_ATTACHED = "Draft Component Attached";
export const DRAFT_COMPONENT_CREATED = "Draft Component Created";
export const DRAFT_COMPONENT_DELETED = "Draft Component Deleted";
export const DRAFT_GROUP_CREATED = "Draft Group Created";
export const DRAFT_GROUP_LINKING_ENABLED = "Draft Group Linking Enabled";
export const DUPLICATES_ATTACHED_TO_COMPONENT = "Duplicates Attached to Component";
export const DUPLICATE_BRANCH_FILE_IDENTIFIED = "Duplicate Branch File Identified";
export const EDIT_RESTORED = "Edit Restored";
export const EDIT_SAVED = "Edit Saved";
export const ENABLE_LINKING_BUTTON_CLICKED = "Enable Linking Button Clicked";
export const ERROR_CHECKING_ONBOARDING_STATE = "Error checking onboarding state";
export const ERROR_FETCHING_DOCCOMMENTS = "Error fetching doc/comments/";
export const ERROR_FETCHING_DOCINITIALINFO = "Error fetching doc/initialInfo";
export const ERROR_FETCHING_DOC_AFTER_IMPORT = "Error fetching doc after import";
export const ERROR_FETCHING_NOTIFSUNREADCOMMENTS = "Error fetching /notifs/unreadComments";
export const ERROR_FETCHING_TEXT_HISTORY_AND_COMMENTS = "Error fetching text history and comments";
export const ERROR_FETCHING_TEXT_INFOBYNODE = "Error fetching text infoByNode";
export const ERROR_FETCHING_TEXT_VARIANTS = "Error fetching text variants";
export const ERROR_FETCHING_WORKSPACETAGS = "Error fetching /workspace/tags";
export const ERROR_FETCHING_WS_COMP_NAME = "Error fetching ws comp name";
export const ERROR_GETTING_MULTI_SELECTED_TEXT_INFO = "Error getting multi-selected text info";
export const ERROR_IMPORTING_USING_BACKGROUND_WORKERS = "Error importing using background workers";
export const ERROR_POSTING_COMMENT_REPLY = "Error posting comment reply";
export const ERROR_POSTING_NEW_COMMENT_THREAD = "Error posting new comment thread";
export const ERROR_RESOLVING_COMMENT_THREAD = "Error resolving comment thread";
export const ERROR_RESTORING_EDIT_OF_COMP = "Error restoring edit of comp";
export const ERROR_SAVING_MULTI_TEXT_LAYER_EDITS = "Error saving multi text layer edits";
export const ERROR_SAVING_TEXT_EDITS = "Error saving text edits";
export const ERROR_SAVING_VARIANT_TEXT_EDITS = "Error saving variant text edits";
export const ERROR_SAVING_WS_COMP_EDITS = "Error saving ws comp edits";
export const ERROR_STARTING_TRIAL = "error starting trial";
export const ERROR_SYNCING_NEW_FRAMES = "Error syncing new frames";
export const ERROR_SYNCING_UNSYNCED_NODES = "Error syncing unsynced nodes";
export const ERROR_WHILE_POLLING_FOR_A_RESPONSE = "Error while polling for a response";
export const EXPORT_FILE = "export-file";
export const FAQ_OPENED = "FAQ Opened";
export const FETCHED_COMPONENTS_VIA_API = "Fetched components via API";
export const FETCHED_PROJECTS_VIA_API = "Fetched projects via API";
export const FETCHED_PROJECT_COMPONENTS_VIA_API = "Fetched project components via API";
export const FETCHED_PROJECT_VIA_API = "Fetched project via API";
export const FETCHED_VARIABLES_VIA_API = "Fetched variables via API";
export const FETCHED_VARIANTS_VIA_API = "Fetched variants via API";
export const FIGMA_VARIABLES_INTEGRATION_DISABLED = "Figma Variables Integration Disabled";
export const FIGMA_VARIABLES_INTEGRATION_ENABLED = "Figma Variables Integration Enabled";
export const FIGMA_VARIABLES_INTEGRATION_UPDATED = "Figma Variables Integration Updated";
export const FIGMA_VARIABLES_MODAL_OPENED = "Figma Variables Modal Opened";
export const FILE_IMPORTED = "File Imported";
export const FOLDER_INVITE = "Folder Invite";
export const FOLDER_MADE_INVITE_ONLY = "Folder Made Invite-Only";
export const FRAME_PREVIEW_OPENED = "Frame Preview Opened";
export const FRAME_VARIANT_APPLIED = "Frame Variant Applied";
export const FRAME_VARIANT_SELECTED = "Frame Variant Selected";
export const GETDITTOCOMPONENTKEY = "getDittoComponentKey";
export const GET_PAGES_ERROR_GENERIC = "Get Pages Error (Generic)";
export const GROUP_DELETED = "Group Deleted";
export const GROUP_ORDER_CHANGED = "Group Order Changed";
export const GROUP_RENAMED = "Group Renamed";
export const GROUP_REVERTED_TO_DRAFT = "Group Reverted to Draft";
export const GROUP_UNLINKED = "Group Unlinked";
export const GUIDES_AND_FAQS_LINK_CLICKED = "Guides and FAQs link clicked";
export const HIDE_SELECTED_TEXT_FROM_VIEW_BUTTON_CLICKED = "Hide Selected Text from View Button Clicked";
export const IMAGE_PREVIEW_CLICKED = "Image preview clicked";
export const IMAGE_PREVIEW_HIGHLIGHT_CLICKED = "Image preview highlight clicked";
export const IMPERSONATION = "Impersonation";
export const IMPORTED_COMPONENTS_VIA_API = "Imported components via API";
export const IMPORT_FILE_ERROR = "Import File Error";
export const IMPORT_FILE_STARTED = "Import File Started";
export const IMPORT_FILE_SUCCESS = "Import File Success";
export const COMPONENTS_BULK_IMPORTED = "Components Bulk Imported";
export const IMPORT_MODAL_OPENED = "Import Modal Opened";
export const INIT_DITTO_COMPONENT_KEY_UPDATED = "Init - Ditto Component Key Updated";
export const INSTASYNC_COMPONENT_CONFLICT_OVERRIDDEN = "InstaSync - Component Conflict Overridden";
export const INSTASYNC_CONFLICTS_CREATED = "InstaSync - Conflicts Created";
export const INSTASYNC_CONFLICTS_MODAL_CLOSED = "InstaSync - Conflicts Modal Closed";
export const INSTASYNC_EDITS_MADE = "InstaSync - Edits made";
export const INSTASYNC_OCCURRED_DURING_RESYNC = "InstaSync - Occurred During Resync";
export const INSTASYNC_TEXT_ITEM_CONFLICT_OVERRIDDEN = "InstaSync - Text Item Conflict Overridden";
export const INVITE_MODAL_OPENED = "Invite Modal Opened";
export const INVITE_ONLY_UPSELL_BADGE_CLICKED = "Invite-only Upsell Badge Clicked";
export const INVITE_REVOKED = "Invite Revoked";
export const JSON_IMPORT_FILE_ACCEPTED = "JSON Import: File accepted";
export const JSON_IMPORT_FILE_REJECTED = "JSON Import: File rejected";
export const JSON_IMPORT_SUCCESS = "JSON Import: Success";
export const JSON_IMPORT_UPLOAD_PROCESSED = "JSON Import: Upload processed";
export const LAUNCHDARKLY_CONNECTION_DISABLED = "LaunchDarkly -- Connection Disabled";
export const LOADED_DRAFTED_GROUPS_PAGE = "Loaded Drafted Groups Page";
export const LOADED_FIGMA_PAGE = "Loaded Figma Page";
export const LOGIN_ERROR = "Login error";
export const LOKALISE_INTEGRATION_ = "Lokalise Integration ";
export const LONG_RESYNC_TIME = "Long Resync Time";
export const MOVED_VARIANTS_TO_FOLDER = "Moved Variants to Folder";
export const MULTIPLE_COMPONENTS_ATTACHED_FROM_SUGGESTION = "Multiple Components Attached From Suggestion";
export const MULTIPLE_COMPONENTS_CREATED_FROM_SUGGESTION = "Multiple Components Created From Suggestion";
export const MULTIPLE_COMPS_STATUS_UPDATED = "Multiple Comps Status Updated";
export const MULTIPLE_COMPS_TAGS_UPDATED = "Multiple Comps Tags Updated";
export const MULTIPLE_COMPS_TEXT_UPDATED = "Multiple Comps Text Updated";
export const MULTI_EDIT_SAVED = "Multi-edit Saved";
export const MULTI_SELECT_TOGGLE_CLICKED = "Multi-select Toggle Clicked";
export const MULTI_TEXT_HIDDEN = "Multi Text Hidden";
export const MULTI_TEXT_SELECTED = "Multi Text Selected";
export const NEW_BLOCK_BUTTON_IN_PROJECT_CLICKED = "New Block Button in Project Clicked";
export const NEW_WORKSPACE_CREATED = "New workspace created";
export const NOTIFICATIONS_MARKED_AS_READ = "Notifications Marked as Read";
export const NOTIFICATIONS_OPENED = "Notifications Opened";
export const NOTIFICATION_CLICKED = "Notification Clicked";
export const OPEN_PROJECT_IN_DITTO_CLICKED = "Open project in Ditto clicked";
export const OPEN_WEB_APP_TO_FINISH_ONBOARDING = "Open web app to finish onboarding";
export const PAYMENT_MODAL_CLOSED = "Payment Modal Closed";
export const PLAN_LIMIT_HIT = "Plan limit hit!";
export const PLUGIN_CONFLICT_OVERRIDDEN = "Plugin Conflict Overridden";
export const PLUGIN_CRASH = "Plugin Crash";
export const PLUGIN_PRODUCT_TOUR_COMPLETED = "Plugin Product Tour Completed";
export const PLUGIN_PRODUCT_TOUR_STARTED = "Plugin Product Tour Started";
export const PLURALS_ADDED = "Plurals Added";
export const PLURALS_DISABLED = "Plurals Disabled";
export const PLURALS_ENABLED = "Plurals Enabled";
export const PLURALS_REMOVED = "Plurals Removed";
export const POST_IMPORT_SUGGESTION_CANCEL = "Post-import suggestion - Cancel";
export const POST_IMPORT_SUGGESTION_IGNORE = "Post-import suggestion - Ignore";
export const POST_IMPORT_SUGGESTION_IGNORE_SUGGESTIONS_VIA_BANNER =
  "Post-import suggestion — Ignore suggestions via banner";
export const POST_IMPORT_SUGGESTION_REVIEW_GROUP_SUGGESTIONS = "Post-import suggestion — Review group suggestions";
export const PRODUCT_UPDATES_OPENED = "Product Updates Opened";
export const PROJECT_ADDED_TO_FOLDER = "Project Added to Folder";
export const PROJECT_API_IDS_REGENERATED = "Project API IDs Regenerated";
export const PROJECT_API_ID_SETTINGS_CHANGED = "Project API ID Settings Changed";
export const PROJECT_CREATED = "Project Created";
export const PROJECT_DELETED = "Project Deleted";
export const PROJECT_EXPORT_CLICKED = "Project export clicked";
export const PROJECT_FOLDER_CREATED = "Project Folder Created";
export const PROJECT_FOLDER_DELETED = "Project Folder Deleted";
export const PROJECT_FOLDER_RENAMED = "Project Folder Renamed";
export const PROJECT_INVITE = "Project Invite";
export const PROJECT_OPENED = "Project Opened";
export const PROJECT_REMOVED_FROM_FOLDER = "Project Removed from Folder";
export const PROJECT_RESYNCED_FROM_WEBAPP = "Project Resynced from Webapp";
export const RESYNC_INITIATED = "Resync Initiated";
export const PUBLIC_API_RATE_LIMIT = "Public API rate limit";
export const REMOVE_USER_MODAL_OPENED = "Remove User Modal Opened";
export const RESEND_INVITE = "Resend Invite";
export const RESTORED_EDIT_OF_COMP = "Restored edit of comp";
export const RESYNC_ERROR = "Resync error";
export const RESYNC_FROM_FIGMA = "Resync from Figma";
export const SAMPLE_PROJECT_ACTIVATED = "Sample Project Activated";
export const SAMPLE_PROJECT_VIEW_PRODUCT_TOUR_CLICKED = "Sample Project — View Product Tour Clicked";
export const SAVED_CHANGES_TO_MANGEGROUPSMODAL = "Saved changes to MangeGroupsModal";
export const SAVED_CHANGES_TO_SELECT_PAGES_MODAL = "Saved changes to select pages modal";
export const SAVE_EDITS_BUTTON_CLICKED = "Save edits button clicked";
export const SECONDARY_LOGIN_METHOD_LINKED = "Secondary Login Method Linked";
export const SEND_APP_EMAIL_NOTIFICATION = "Send App Email Notification";
export const SEND_INVITE_IN_SHARE_PROJECT_MODAL_CLICKED = "Send invite in Share Project modal clicked";
export const SENT_DOWNGRADE_FEEDBACK = "Sent Downgrade Feedback";
export const SETTINGS_CLICKED = "Settings clicked";
export const SETUP_BANNER_DISMISS_BANNER_CLICKED = "Setup Banner -- Dismiss banner clicked";
export const SETUP_BANNER_GENERATE_API_KEY_CLICKED = "Setup Banner -- Generate API key clicked";
export const SETUP_BANNER_IMPORT_FIGMA_FILES_CLICKED = "Setup Banner -- Import Figma files clicked";
export const SETUP_BANNER_IMPORT_STRING_FILES_CLICKED = "Setup Banner -- Import string files clicked";
export const SETUP_BANNER_INVITE_DEVELOPER_CLICKED = "Setup Banner -- Invite developer clicked";
export const SETUP_BANNER_VIEW_FULL_GUIDE_CLICKED = "Setup Banner -- View full guide clicked";
export const SHARE_BUTTON_IN_PROJECT_CLICKED = "Share button in project clicked";
export const SHOW_SUGGESTIONS_BANNER_CLICKED = "Show Suggestions Banner Clicked";
export const SIGNED_UP = "Signed up";
export const SIGN_OUT = "Sign out";
export const SINGLE_NOTIFICATION_MARKED_AS_READ = "Single Notification Marked As Read";
export const SPLIT_CONNECTION_DISABLED = "Split -- Connection Disabled";
export const SUBSCRIBED_TO_DEVELOPER_UPDATES = "Subscribed to Developer Updates";
export const TEMPLATE_COMPONENT_DETACHED = "Template Component Detached";
export const TESTEVENT = "TestEvent";
export const TEST_REQUEST = "Test request";
export const TEXT_ADDED_TO_BLOCK = "Text added to Block";
export const TEXT_ON_NEW_FRAME_SYNCED_FROM_FIGMA_PLUGIN = "Text on New Frame Synced from Figma Plugin";
export const TOGGLE_HIDDEN_DRAWER = "Toggle hidden drawer";
export const TOGGLE_VIEW_API_IDS = "Toggle View API IDs";
export const TRIAL_CTA_CLICKED = "Trial CTA Clicked";
export const TRIAL_INFO_BACK_BUTTON_CLICKED = "Trial Info Back Button Clicked";
export const TRIAL_STARTED_IN_WORKSPACE = "Trial started in workspace";
export const UNHIDE_TEXT_IN_DRAWER = "Unhide Text in Drawer";
export const UPDATED_VARIANTS_VIA_API = "Updated variants via API";
export const TEXT_ITEMS_STATUS_CHANGED = "Text Items Status Changed";
export const TEXT_ITEMS_TEXT_EDITED = "Text Items Text Edited";
export const TEXT_PREVIEW_TOGGLED = "Text Preview Toggled";
export const COMPONENT_TEXT_EDITED = "Component Text Edited";
export const UNIVERSAL_SEARCH_OPENED = "Universal Search Opened";
export const UNIVERSAL_SEARCH_RESULT_CLICKED = "Universal Search Result Clicked";
export const UPDATE_FIGMA_VARIABLES_INTEGRATION = "update-figma-variables-integration";
export const UPDATE_TOKENS = "update_tokens";
export const UPGRADE_LINK_CLICKED = "Upgrade link clicked";
export const UPGRADE_PLAN_LINK_CLICKED = "Upgrade Plan link clicked";
export const UPSELL_CLICKED = "Upsell Clicked";
export const USER_SIGNUP_ERROR = "User signup error";
export const USER_WORKSPACE_UPGRADED = "User Workspace Upgraded";
export const VARIABLES_MOVED_TO_FOLDER = "Variables Moved to Folder";
export const VARIABLE_CREATED = "Variable Created";
export const VARIABLE_DELETED = "Variable Deleted";
export const VARIABLE_FOLDER_CREATED = "Variable Folder Created";
export const VARIABLE_FOLDER_DELETED = "Variable Folder Deleted";
export const VARIABLE_FOLDER_UPDATED = "Variable Folder Updated";
export const VARIABLE_INSERTED = "Variable Inserted";
export const VARIABLE_UPDATED = "Variable Updated";
export const VARIANTS_MOVED_TO_FOLDER = "Variants Moved to Folder";
export const VARIANT_ADDED_TO_FRAME = "Variant Added to Frame";
export const VARIANT_APPLIED_TO_FRAME = "Variant Applied to Frame";
export const VARIANT_ATTACHED_TO_DOC_AND_FRAME = "Variant Attached to Doc and Frame";
export const VARIANT_CREATED = "Variant Created";
export const VARIANT_DESCRIPTION_UPDATED = "Variant Description Updated";
export const VARIANT_FOLDER_CREATED = "Variant Folder Created";
export const VARIANT_FOLDER_DELETED = "Variant Folder Deleted";
export const VARIANT_FOLDER_UPDATED = "Variant Folder Updated";
export const VARIANT_NAME_UPDATED = "Variant Name Updated";
export const VARIANT_PREVIEW_TURNED_ON = "Variant Preview Turned On";
export const VARIANT_REMOVED_FROM_FRAME = "Variant Removed from Frame";
export const VARIANT_TEXT_RESTORED = "Variant Text Restored";
export const VARIANT_TEXT_UPDATED = "Variant Text Updated";
export const VIEWED_COMPONENTS_TAB = "Viewed Components Tab";
export const VIEWED_FILE_SETUP_IMPORT_PAGE = "Viewed File Setup: IMPORT Page";
export const VIEWED_FULLSCREEN_MODAL = "Viewed Fullscreen Modal";
export const VIEW_VARIANT = "View Variant";
export const VIEW_WORKSPACE_ADMINS_CTA_CLICKED = "View workspace admins CTA clicked";
export const WEBHOOK_CREATED = "Webhook Created";
export const WEBHOOK_DELETED = "Webhook Deleted";
export const WEBHOOK_TEST_REQUEST_SENT = "Webhook Test Request Sent";
export const WEBHOOK_UPDATED = "Webhook Updated";
export const WELCOME_MODAL_EXPLORE_ON_OWN_SELECTED = "Welcome Modal — Explore on Own Selected";
export const WORKSPACE_API_TOKEN_CREATED = "Workspace API Token Created";
export const WORKSPACE_COMPONENT_CREATED = "Workspace Component Created";
export const WORKSPACE_COMPONENT_CREATED_FROM_DUPLICATES = "Workspace Component Created from Duplicates";
export const WORKSPACE_FEATURE_FLAG_OFF = "Workspace feature flag off";
export const WORKSPACE_FEATURE_FLAG_ON = "Workspace feature flag on";
export const WORKSPACE_INVITE = "workspace-invite";
export const WORKSPACE_SHARE_LINK_COPIED = "Workspace share link copied";
export const WORKSPACE_UPGRADED = "Workspace Upgraded";
export const WORKSPACE_USER_REMOVED = "Workspace User Removed";
export const WORKSPACE_USER_UPDATED = "Workspace User Updated";
export const INVITE_TEAMMATE_CTA = "Invite Teammate CTA";
export const INVITE_DEVELOPER_CLICKED = "Invite a developer clicked";
export const ENABLE_DEV_TOOLS_MODAL_CLICKED = "Enable Dev Tools Modal Clicked";
export const DEV_DOCS_LINK_CLICKED = "Dev Docs Link Clicked";
export const HOMEPAGE_IMPORT_FIGMA_FILE = "Homepage - Import Figma File";
export const HOMEPAGE_IMPORT_STRING_FILE = "Homepage - Import String File";
export const HOMEPAGE_INVITE_DEVELOPER = "Homepage - Invite Developer";
export const HOMEPAGE_ASSIGNED_COMPONENT_CLICKED = "Homepage - Assigned Component Clicked";
export const HOMEPAGE_ASSIGNED_PROJECT_CLICKED = "Homepage - Assigned Project Clicked";
export const HOMEPAGE_DISCUSSION_MARKED_READ = "Homepage - Discussion Marked Read";
export const HOMEPAGE_DISCUSSION_REPLY_CLICKED = "Homepage - Discussion Reply Clicked";
export const HOMEPAGE_SAMPLE_PROJ_CLICKED = "Homepage - Sample Project Clicked";
export const HOMEPAGE_HELP_GUIDES_CLICKED = "Homepage - Help Guides Clicked";
export const HOMEPAGE_CUSTOMER_STORIES_CLICKED = "Homepage - Customer Stories Clicked";
export const HOMEPAGE_VIEW_ALL_PROJECTS_CLICKED = "Homepage - View All Projects Clicked";
export const HOMEPAGE_VIEW_DEVELOPER_INTEGRATIONS_CLICKED = "Homepage - View Developer Integrations Clicked";
export const COMPONENT_ASSIGNED = "Component Assigned";
export const TEXT_ITEMS_ASSIGNED = "Text Items Assigned";
export const COMPONENTS_ASSIGNED = "Components Assigned";

// User "firsts" -- keys are the same as in shared/types/User :: IUserMetrics
export const USER_FIRST = {
  firstCommentedAt: "User: First Comment",
  firstEditedAt: "User: First Edit",
  firstStatusChange: "User: First Status Change",
  firstComponentAction: "User: First Component Action",
  firstAssignment: "User: First Assignment",
};

export const AUTO_SYNC_IMPORT_FRAMES_ENABLED = "Auto Sync - Import Frames Enabled";
export const AUTO_SYNC_IMPORT_FRAMES_CONFIG_CHANGED = "Auto Sync - Import Frames Config Changed";
export const AUTO_SYNC_IMPORT_FRAMES_DISABLED = "Auto Sync - Import Frames Disabled";
export const AUTO_SYNC_ATTACH_COMPONENTS_ENABLED = "Auto Sync - Attach Components Enabled";
export const AUTO_SYNC_ATTACH_COMPONENTS_CONFIG_CHANGED = "Auto Sync - Attach Components Config Changed";
export const AUTO_SYNC_ATTACH_COMPONENTS_DISABLED = "Auto Sync - Attach Components Disabled";
export const AUTO_SYNC_COMPONENTS_AUTO_ATTACHED = "Auto Sync - Components Auto Attached";
export const AUTO_SYNC_FRAMES_AUTO_IMPORTED = "Auto Sync - Frames Auto Imported";
export const AUTO_SYNC_DRAFT_AUTO_CONNECTED = "Auto Sync - Draft Auto Connected";

export const VARIANT_TEXT_STATUS_UPDATED = "Variant Text Statuses Updated";
export const VARIANTS_ATTACHED_TO_TEXT_ITEM = "Variants Attached To Text Item";
export const VARIANTS_REMOVED_FROM_TEXT_ITEM = "Variants Removed From Text Item";
export const FIGMA_SYNC_CONFLICTS_RESOLVED = "Conflicts Resolved";
export const TEXT_SUGGESTION_ACCEPTED = "Text Suggestion Accepted";
export const BLOCK_SUGGESTION_ACCEPTED = "Block Suggestion Accepted";
export const LIBRARY_SUGGESTION_ACCEPTED = "Library Suggestion Accepted";
export const LIBRARY_COMPONENT_FOLDER_CREATED = "Library Component Folder Created";
export const INSTANCES_AUTOMATICALLY_LINKED = "Instances Automatically Linked";
export const FIGMA_TEXT_NODES_UNLINKED = "Figma Text Nodes Unlinked";
export const TEXT_ITEMS_CREATED = "Text Created";
export const TEXT_ITEMS_DELETED = "Text Deleted";
export const PROJECT_BLOCKS_CREATED = "Blocks Created";
export const NS_BETA_INTEREST_GENERAL = "NS Beta Interest Expressed - General";
export const NS_BETA_INTEREST_DEV_ID = "NS Beta Interest Expressed - Dev Integrations";
export const NS_BETA_LIBRARY_INTEREST = "NS Library Interest Expressed";
export const FIGMA_PLUGIN_OPENED = "Figma Plugin Opened";
export const REVIEW_CONFLICTS_SELECTED = "Review Conflicts Selected";
export const TEXT_SUGGESTION_IGNORED = "Text Suggestion Ignored";
export const BLOCK_SUGGESTION_IGNORED = "Block Suggestion Ignored";
export const LIBRARY_SUGGESTION_IGNORED = "Library Suggestion Ignored";
export const DESIGN_PREVIEW_OPENED = "Design Preview Opened";
export const DESIGN_PREVIEW_FRAME_OPENED_IN_FIGMA = "Design Preview - Opened Frame in Figma";
export const DESIGN_PREVIEW_TEXT_ITEM_CLICKED = "Design Preview - Clicked on Text Item";
export const FIELDS_HIDDEN = "Fields Hidden";
export const FIGMA_SYNC_INITIATED = "Figma Sync Initiated";
export const FIGMA_SYNC_FAILED = "Figma Sync Failed";
export const FIGMA_SYNC_COMPLETED = "Figma Sync Completed";
export const PROJECT_CONNECTED_TO_FIGMA = "Project connected to Figma";
export const INSTANCES_UNLINKED = "Instances Unlinked";

export const LIBRARY_COMPONENT_CREATED = "Library Component Created";
export const LIBRARY_COMPONENTS_UPDATED = "Library Components Updated";
export const LIBRARY_COMPONENTS_MOVED = "Library Components Moved";
