import React from "react";
import TimeAgo from "react-timeago";
import { ENTRY_TYPES, IChangeItem } from "../../../../shared/types/ActualChange";
import { getNoSecondsFormatter } from "../../../../shared/utils/timeAgoFormatters";
import Text from "../../atoms/Text";
import Dot from "../Dot";
import style from "./index.module.css";

interface IProps {
  changeItem: IChangeItem;
}

export function ChangeItemHeader({ changeItem }: IProps) {
  return (
    <div className={style.changeItemHeaderWrapper} data-testid="change-item-header">
      <ChangeItemHeaderText changeItem={changeItem} />
      <Dot size={2} color="icon-secondary" />
      <TimeAgo
        className={style.date}
        date={changeItem.createdAt}
        minPeriod={30}
        formatter={getNoSecondsFormatter("Less than a minute ago")}
      />
    </div>
  );
}

export default ChangeItemHeader;

function ChangeItemHeaderText({ changeItem }: IProps) {
  switch (changeItem.entry_type) {
    case ENTRY_TYPES.DITTO_PROJECT_CREATED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} created Ditto project
        </Text>
      );
    case ENTRY_TYPES.DITTO_PROJECT_RENAMED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} renamed Ditto project
        </Text>
      );
    case ENTRY_TYPES.DITTO_PROJECT_MOVED_TO_FOLDER:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user}{" "}
          {changeItem.data.folderIdAfter ? "moved Ditto project into folder" : "removed Ditto project out of folder"}
        </Text>
      );
    case ENTRY_TYPES.DITTO_BLOCK_CREATED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} created block
        </Text>
      );
    case ENTRY_TYPES.DITTO_BLOCK_UPDATED:
      const isNameChange = changeItem.data.before.name !== changeItem.data.after.name;
      const text = isNameChange ? `${changeItem.user} updated block name` : `${changeItem.user} updated block`;
      return (
        <Text color="secondary" size="micro">
          {text}
        </Text>
      );
    case ENTRY_TYPES.DITTO_BLOCK_DELETED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} deleted block
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_CREATED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} created text item
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_DELETED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} deleted text item
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_EDIT:
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_STATUS:
      return (
        <Text color="secondary" size="micro" inline>
          {changeItem.user} edited{" "}
          <Text color="primary" size="micro" weight="medium">
            {changeItem.data.variantName}
          </Text>{" "}
          text item variant
        </Text>
      );
    case ENTRY_TYPES.EDIT:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} edited {changeItem.data.componentId ? "a library component" : "text item"}
        </Text>
      );
    case ENTRY_TYPES.STATUS:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} updated {changeItem.data.componentId ? "a library component" : "text item"} status
        </Text>
      );
    case ENTRY_TYPES.DUPES_STATUS:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} updated text item status
        </Text>
      );
    case ENTRY_TYPES.COMP_ASSIGNED:
      return (
        <Text inline color="secondary" size="micro">
          {changeItem.user} assigned {changeItem.data.componentId ? "a library component" : "text item"} to{" "}
          <Text inline color="primary" size="micro" weight="medium">
            {changeItem.data.assigneeName}
          </Text>
        </Text>
      );
    case ENTRY_TYPES.MULTI_COMP_ASSIGNED:
      return (
        <Text inline color="secondary" size="micro">
          {changeItem.user} assigned {changeItem.dupe_comp_ids.length} text items to{" "}
          <Text inline color="primary" size="micro" weight="medium">
            {changeItem.data.assigneeName}
          </Text>
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE:
      if (changeItem.data.textItemIds.length === 1) {
        return (
          <Text color="secondary" size="micro">
            {changeItem.user} changed character limit on text item
          </Text>
        );
      } else {
        return (
          <Text color="secondary" size="micro">
            {changeItem.user} changed character limit on {changeItem.data.textItemIds.length} text items
          </Text>
        );
      }
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_ATTACHED:
      return (
        <Text color="secondary" size="micro" inline>
          {changeItem.user} added{" "}
          <Text color="primary" size="micro" weight="medium">
            {changeItem.data.variantName}
          </Text>{" "}
          text item variant
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_REMOVED:
      return (
        <Text color="secondary" size="micro" inline>
          {changeItem.user} removed{" "}
          <Text color="primary" size="micro" weight="medium">
            {changeItem.data.variantName}
          </Text>{" "}
          text item variant
        </Text>
      );
    case ENTRY_TYPES.PLURAL_ADDED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} created plural forms for text item
        </Text>
      );
    case ENTRY_TYPES.PLURAL_EDITED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} edited plural forms for text item
        </Text>
      );
    case ENTRY_TYPES.PLURAL_REMOVED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} deleted plural forms for text item
        </Text>
      );
    case ENTRY_TYPES.SYNC_CONFLICT_RESOLVED:
      return (
        <Text color="secondary" size="micro">
          {`${changeItem.user} resolved edit conflict using value from ${
            changeItem.data.selection === "figma" ? "design file" : "Ditto"
          }`}
        </Text>
      );
    case ENTRY_TYPES.VARIANT_CREATED:
      return null;
    case ENTRY_TYPES.LIBRARY_COMPONENT_CREATED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} created a library component
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_LINKED_TO_TEXT_ITEMS:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} linked to library component
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_PUBLISHED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} published a component
          {changeItem.data.projectName ? ` from ${changeItem.data.projectName}` : ""}
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_UNLINKED_FROM_TEXT_ITEMS:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} unlinked from library component
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_FOLDER_CREATED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} created library component folder
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_EDIT:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} edited a component
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_STATUS:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} edited a component
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_ASSIGNED:
      return (
        <Text inline color="secondary" size="micro">
          {changeItem.user} assigned a component to{" "}
          <Text inline color="primary" size="micro" weight="medium">
            {changeItem.data.assigneeName}
          </Text>
        </Text>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_DELETED:
      return (
        <Text color="secondary" size="micro">
          {changeItem.user} deleted component
        </Text>
      );
    default:
      // @ts-expect-error This switch should be exhaustive. If this errors, an entry type is missing.
      const never = changeItem.entry_type;
      return null;
  }
}
