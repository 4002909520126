import { sidebarCollapseStateAtom } from "@/stores/Library";
import LibraryFilterBar from "@ds/organisms/FilterBar";
import { atom } from "jotai";
import LibraryContent from "./components/LibraryContent";
import NavBar from "./components/LibraryNavBar";
import style from "./style.module.css";

import useSegment from "@/hooks/useSegment";
import {
  designPreviewToggledAtom,
  searchAtom,
  selectedFiltersAtom,
  selectedFiltersValuesAtomFamily,
} from "@/stores/Location";
import { allTagsInProject } from "@/stores/Project";
import {
  libraryHiddenFieldsListAtom,
  resetHiddenFieldsActionAtom,
  updateHiddenFieldsActionAtom,
} from "@/stores/TextItemMetadata";
import { deferredUsersAtom } from "@/stores/Workspace";
import { HIDE_JOTAI_DEVTOOLS } from "@/utils/featureFlags";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";

const _dummyPagesAtom = atom([]);

function Library() {
  return (
    <div className={style.libraryContainer}>
      <NavBar />
      <LibraryFilterBar
        sidebarCollapseStateAtom={sidebarCollapseStateAtom}
        filterContextId="library"
        designPreviewToggledAtom={designPreviewToggledAtom}
        searchAtom={searchAtom}
        useSegment={useSegment}
        hiddenFieldsListAtom={libraryHiddenFieldsListAtom}
        resetHiddenFieldsActionAtom={resetHiddenFieldsActionAtom}
        updateHiddenFieldsActionAtom={updateHiddenFieldsActionAtom}
        filters={{}}
        selectedFiltersValuesAtomFamily={selectedFiltersValuesAtomFamily}
        selectedFiltersAtom={selectedFiltersAtom}
        usersAtom={deferredUsersAtom}
        tagsAtom={allTagsInProject}
        pagesAtom={_dummyPagesAtom}
      />
      <LibraryContent />
      {process.env.ENV === "development" && !HIDE_JOTAI_DEVTOOLS && (
        <DevTools
          options={{ shouldShowPrivateAtoms: false, shouldExpandJsonTreeViewInitially: true }}
          position="bottom-right"
        />
      )}
    </div>
  );
}

export default Library;
