import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import classNames from "classnames";
import { Atom, useAtomValue } from "jotai";
import React, { Suspense, useMemo } from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

export default function FolderBreadcrumbsLabel(props: IProps) {
  return (
    <Suspense fallback={<React.Fragment />}>
      <FolderBreadcrumbsLabelInner {...props} />
    </Suspense>
  );
}

interface IProps {
  maxCrumbs?: number;
  selectedLibraryFolderId: string | null;
  libraryComponentFoldersAtom: Atom<ILibraryComponentFolder[] | Promise<ILibraryComponentFolder[]>>;
}

function FolderBreadcrumbsLabelInner(props: IProps) {
  const { selectedLibraryFolderId, libraryComponentFoldersAtom, maxCrumbs = 4 } = props;

  const libraryComponentFolders = useAtomValue(libraryComponentFoldersAtom);
  const libraryComponentFoldersById = useMemo(
    () =>
      new Map<string | null, ILibraryComponentFolder>(libraryComponentFolders.map((folder) => [folder._id, folder])),
    [libraryComponentFolders]
  );

  const currentFolder = useMemo(
    () => libraryComponentFoldersById.get(selectedLibraryFolderId ?? null),
    [selectedLibraryFolderId, libraryComponentFoldersById]
  );

  const ancestorCrumbs = useMemo(() => {
    // no crumbs if no selected folder
    if (!selectedLibraryFolderId) return [];

    // no crumbs if selected folder couldn't be found
    if (!currentFolder) return [];

    // important to note the name of this variable: only _ancestor_ folder crumbs are included
    // here because a crumb for the current folder is rendered separately
    //
    // this is important to understand so that it's less confusing when reading the code below that indexes on the array
    let ancestorFolderCrumbs: string[] = [];

    let ancestorFolder = libraryComponentFoldersById.get(currentFolder.parentId);
    while (ancestorFolder) {
      ancestorFolderCrumbs.unshift(ancestorFolder.name ?? "Folder");
      ancestorFolder = libraryComponentFoldersById.get(ancestorFolder.parentId);
    }

    return ancestorFolderCrumbs;
  }, [currentFolder, libraryComponentFoldersById, selectedLibraryFolderId]);

  const crumbs = useMemo(() => {
    let crumbs: string[] = [];
    crumbs.push(...ancestorCrumbs);
    crumbs.push(currentFolder?.name ?? "Folder");

    if (crumbs.length > maxCrumbs) {
      crumbs = [crumbs[0], "...", ...crumbs.slice(crumbs.length - 1)];
    }

    return crumbs;
  }, [ancestorCrumbs, currentFolder, maxCrumbs]);

  return (
    <div className={style.folderBreadcrumbsLabel}>
      <Icon Icon={<FolderIcon />} size="xxs" color="secondary" />
      <div className={style.crumbs}>
        {crumbs.map((crumb, index) => {
          const isLast = index === crumbs.length - 1;
          const isJustEllipsis = crumb === "...";

          return (
            <React.Fragment key={crumb}>
              <Text
                truncate
                className={classNames(style.crumb, {
                  [style.lastCrumb]: isLast,
                  [style.justEllipsis]: isJustEllipsis,
                })}
                size="micro"
                color="secondary"
              >
                {crumb}
              </Text>
              {!isLast && <Separator />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

function Separator() {
  return (
    <Text size="micro" color="secondary">
      /
    </Text>
  );
}
