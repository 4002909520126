import React from "react";
import Text from "../Text";

interface IProps {
  className?: string;
  separator?: string;
}

export function BreadcrumbSeparator({ className, separator = "/" }: IProps) {
  return (
    <Text color="icon-minimal" className={className} data-testid="breadcrumb-separator">
      {separator}
    </Text>
  );
}

export default BreadcrumbSeparator;
