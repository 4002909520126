import React, { useCallback, useMemo, useRef, useState } from "react";
import { ComboboxOption, IBaseComboboxProps, IInternalStateProps, NEW_ITEM_VALUE } from "../";

/**
 * Hook for managing the internal state of the BaseCombobox component
 * @param comboboxProps - The props of the BaseCombobox component
 * @param optionList - The list of options to display in the combobox
 * @param stateProps - Props specific to internal state of combobox (e.g. onFocus, onBlur)
 * @returns
 */
function useInternalState(
  comboboxProps: IBaseComboboxProps,
  optionList: ComboboxOption[],
  stateProps: IInternalStateProps
) {
  const {
    displayProps,
    exclusions,
    isDefaultOpen = false,
    multiple,
    selectedItems,
    textInputDisabled,
    onCreateNew,
    setSelectedItems,
  } = comboboxProps;
  const { isLoading = false, onBlur, onFocus, onOpenChange } = stateProps;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const [query, setQuery] = useState(multiple ? "" : selectedItems[0]?.label ?? "");
  const [options, setOptions] = useState<ComboboxOption[]>(optionList);
  const [showFocusBorder, setShowFocusBorder] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  const selectedOptionsValues = useMemo(() => selectedItems.map((item) => item.value), [selectedItems]);

  // Filters non-selected options that don't match the current query
  const renderedOptions = useMemo(() => {
    // If there's no query, show all selected items and unselected items
    if (!query.trim()) {
      return options;
    }

    // Find items that match the query
    const queryFilteredOptionValues = options
      .filter((option) => !option.isLoading && option.label.toLowerCase().includes(query.toLowerCase()))
      .map((option) => option.value);

    // If we found matches, show them along with all selected items
    if (queryFilteredOptionValues.length > 0) {
      return [
        ...options.filter(
          (option) =>
            selectedOptionsValues.includes(option.value) ||
            queryFilteredOptionValues.includes(option.value) ||
            option.isLoading
        ),
      ];
    }

    return [];
  }, [options, query, selectedOptionsValues]);

  const showCreateNew = useMemo(
    () =>
      !!onCreateNew &&
      query.trim() !== "" &&
      !optionList.some((item) => item.label === query) &&
      !exclusions?.some((value) => value.toLocaleLowerCase() === query.toLocaleLowerCase()),
    [optionList, exclusions, query, onCreateNew]
  );

  // Resorts selected items first
  const sortOptions = useCallback(() => {
    setOptions(
      optionList.toSorted((a, b) => {
        const aSelected = selectedOptionsValues.includes(a.value);
        const bSelected = selectedOptionsValues.includes(b.value);
        return Number(bSelected) - Number(aSelected); // Selected items first
      })
    );
  }, [optionList, selectedOptionsValues]);

  const createNewItem = useCallback(
    (value: string) => {
      if (!onCreateNew) {
        const errorMsg = "Cannot create a new item without an onCreateNew callback in BaseCombobox.";
        console.error(errorMsg);
        return null;
      }

      if (value.trim() === "") return null;

      onCreateNew(value);

      // Set isDraftItem flag to indicate this is an unsaved combobox option
      const newItem = { value, label: value, isDraftItem: true };
      return newItem;
    },
    [onCreateNew]
  );

  const onChangeValue = useCallback(
    function onChangeValue(value: ComboboxOption[] | ComboboxOption | null, preventBlur: boolean = false) {
      if (!value) {
        setSelectedItems([]);
        return;
      }

      // Handle multiselection
      if (Array.isArray(value)) {
        // Handle case where we're creating new item
        const newValueIdx = value.findIndex((item) => item.value === NEW_ITEM_VALUE);
        if (newValueIdx !== -1) {
          const newItem = createNewItem(query);
          if (!newItem) return;
          const newSelectedItems = [...value.slice(0, newValueIdx), newItem, ...value.slice(newValueIdx + 1)];
          setSelectedItems(newSelectedItems);
          return;
        }

        setSelectedItems(value);
        return;
      }

      let resolvedValue: ComboboxOption = value;

      // Handle case where we're creating new item
      if (value.value === NEW_ITEM_VALUE) {
        const newItem = createNewItem(query);
        if (!newItem) return;
        resolvedValue = newItem;
      }

      // Handle case where we're deselecting an already selected item
      if (selectedItems.some((item) => item.value === resolvedValue.value)) {
        setSelectedItems(selectedItems.filter((item) => item.value !== resolvedValue.value));
        setQuery("");
      } else {
        if (multiple) {
          setSelectedItems([...selectedItems, resolvedValue]);
        } else {
          setSelectedItems([resolvedValue]);
        }
        setQuery(resolvedValue?.label ?? ""); // Immediately set the query to the new value's label
      }

      setIsOpen(false);

      // timeout hack that we need to do prevent focus after click selection, third party refocuses after blurring
      // would love to not have to do this, but controlling the focus behavior of the combobox is quite difficult
      if (!preventBlur) setTimeout(() => inputRef.current?.blur(), 0);
    },
    [multiple, query, selectedItems, createNewItem, setSelectedItems]
  );

  /**
   * Callback that handles opening and closing the combobox.
   */
  const handleToggleComboboxOpen = useCallback(
    (open: boolean) => {
      if (open) {
        sortOptions();
        setShowFocusBorder(false);
      }
      setIsOpen(open);

      onOpenChange?.(open);
    },
    [sortOptions, onOpenChange]
  );

  /**
   * Event handler for user pressing the escape key -- exposed for usage in BaseComboboxWithPopover (popover listens for this event)
   */
  const handleEscapeClicked = useCallback(
    (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();
      setIsOpen(false);
      setQuery(multiple ? "" : selectedItems[0]?.label ?? "");
    },
    [selectedItems, multiple]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        handleEscapeClicked(event);
      }

      if (event.key === " " && inputRef.current?.hasAttribute("data-focus") && !isOpen) {
        handleToggleComboboxOpen(true);
        event.preventDefault();
        return;
      }

      if (event.key === "Enter") {
        // If user presses enter with input focused + combobox closed, open the combobox
        if (inputRef.current?.hasAttribute("data-focus") && !isOpen) {
          event.preventDefault();
          handleToggleComboboxOpen(true);
          return;
        }

        let item: ComboboxOption | null = null;

        // this is anti-React, but the only way to determine which of the options is focused
        // via keyboard input is to inspect the DOM directly since the lib doesn't expose
        // an API to hook into it
        const optionsParentElement = optionsListRef.current;
        if (optionsParentElement) {
          const optionElements = optionsParentElement.children;
          for (let i = 0; i < (optionElements?.length ?? 0); i++) {
            const optionElement = optionElements[i] as HTMLDivElement;
            if ("focus" in optionElement.dataset && "value" in optionElement.dataset) {
              const value = optionElement.dataset.value;
              item = optionList.find((item) => item.value === value) ?? null;
              break;
            }
          }
        }

        if (item) {
          event.preventDefault();
          event.stopPropagation();
          onChangeValue(item, true);
          setShowFocusBorder(true);
          return;
        }

        if (onCreateNew) {
          event.preventDefault();
          event.stopPropagation();
          const newItem = createNewItem(query);
          onChangeValue(newItem, true);
          setShowFocusBorder(true);
          return;
        }

        // by nooping here, we fall back to the default behavior of the combobox
      }
    },
    [
      optionList,
      isOpen,
      query,
      createNewItem,
      handleEscapeClicked,
      handleToggleComboboxOpen,
      onChangeValue,
      onCreateNew,
    ]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Exit early if input is disabled, shouldn't handle updates
      if (textInputDisabled) return;

      const newValue = e.target.value;
      const oldValue = query;

      // Only prevent the event if we're adding whitespace, not when we're deleting changing to empty string
      if (newValue.length > oldValue.length && newValue.trim() === "") {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (isLoading && selectedItems.length) {
        setSelectedItems([]);
      }

      setQuery(e.target.value);
      handleToggleComboboxOpen(true);
    },
    [handleToggleComboboxOpen, setSelectedItems, isLoading, textInputDisabled, query, selectedItems.length]
  );

  const handleFocus = useCallback(
    (e?: React.FocusEvent<HTMLInputElement>) => {
      // if the combobox is in a loading state, we want to maintain the value of the query
      // when it's focused
      if (!isLoading) {
        setQuery("");
      }

      if (!isOpen) setShowFocusBorder(true);

      onFocus?.();
    },
    [isLoading, isOpen, onFocus]
  );

  const handleBlur = useCallback(() => {
    if (displayProps?.type !== "popover") setIsOpen(false);
    setShowFocusBorder(false);
    onBlur?.();
  }, [displayProps?.type, onBlur]);

  const handleInputClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      // prevents focus handler from firing, erroneously highlighting input with focus border when clicked on
      if (!isOpen) {
        e.stopPropagation();
        handleToggleComboboxOpen(true);
      }
    },
    [isOpen, handleToggleComboboxOpen]
  );

  return {
    query,
    isOpen,
    isLoading,
    showCreateNew,
    showFocusBorder,

    inputRef,
    optionsListRef,

    options: renderedOptions,

    onChangeValue,
    handleKeyDown,
    handleEscapeClicked,
    onInputChange,
    handleFocus,
    handleBlur,
    handleInputClick,
    handleToggleComboboxOpen,
  };
}

export default useInternalState;
