import { ITipTapRichText } from "../types/TextItem";

export const generateBaseRichTextObject = (text: string): ITipTapRichText => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text,
        },
      ],
    },
  ],
});

/**
 * Checks if a rich text object is styled (contains variables, bold, italic, underline, etc.)
 * Returns false if given invalid rich text or valid rich text without any styling
 */
export const isRichTextStyled = (input?: ITipTapRichText | {} | null) => {
  if (!input || typeof input !== "object") {
    return false;
  }

  if (!("type" in input) || (input.type !== "doc" && !input.content?.length)) return false;

  return (input.content || []).some((paragraph) => {
    return (paragraph?.content || []).some((node) => {
      return node.type === "variable" || (node.type === "text" && (node?.marks || []).length > 0);
    });
  });
};
