import * as RadixToggle from "@radix-ui/react-toggle";
import classNames from "classnames";
import React, { forwardRef, MouseEventHandler } from "react";
import { useNativeProps } from "../../useNativeProps";
import Icon from "../Icon";
import style from "./index.module.css";

interface IToggleProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;

  variant?: "base" | "icon";

  /**
   * Handler for when toggle is pressed.
   *
   * @param pressed The pressed state of the toggle.
   * @returns void
   */
  onPressedChange?: (pressed: boolean) => void;

  /**
   * Handler for when toggle is clicked.
   *
   * @param event The mouse event object.
   * @returns void
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /**
   * The controlled pressed state of the toggle. Must be used in conjunction w/onChange handler.
   */
  pressed: boolean;

  /**
   * Flag for whether toggle is initially pressed.
   */
  isToggled?: boolean;

  /**
   * The icon to be rendered. Intended for use with @mui/icons-material.
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   *
   * This is *not* the way to render an icon-only button. Use the variant="icon" prop and pass in the icon
   * as the children instead.
   */
  leadingIcon?: React.ReactNode | React.ReactSVGElement;
  iconSize?: "xxs" | "xs" | "small" | "base";

  /**
   * Size of toggle container. Overrides icon size prop.
   */
  size?: "base" | "small";
  state?: "default" | "focus";
  color?: "primary" | "secondary" | "tertiary" | "danger" | "nav-select";

  /**
   * Set to false to prevent the element from showing any change when toggled.
   */
  hideToggledState?: boolean;

  /**
   * Set to true to force the element to show the toggled state.
   */
  forceToggledDisplay?: boolean;

  disabled?: boolean;
}

export const Toggle = forwardRef<HTMLButtonElement, IToggleProps>(function Toggle(
  {
    variant = "base",
    color = "primary",
    state = "default",
    size = "base",
    onPressedChange,
    onClick,
    pressed,
    isToggled = false,
    iconSize = "small",
    leadingIcon,
    disabled = false,
    hideToggledState = false,
    ...props
  }: IToggleProps,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>
) {
  const nativeProps = useNativeProps<HTMLButtonElement, typeof props>(props, {
    forceToggledDisplay: true,
  });

  const calcIconSize = iconSize ?? size === "small" ? "xxs" : "xs";

  return (
    <RadixToggle.Root
      {...nativeProps}
      style={props.style}
      className={classNames(
        style.ToggleWrapper,
        {
          [style[color]]: true,
          [style[state]]: true,
          [style["small"]]: size === "small",
          [style.pressed]: !hideToggledState && (pressed || !!props.forceToggledDisplay),
        },
        props.className
      )}
      onPressedChange={(pressed) => onPressedChange?.(pressed)}
      onClick={onClick}
      pressed={pressed}
      disabled={disabled}
      data-testid="toggle"
      ref={forwardedRef}
    >
      {leadingIcon && <Icon Icon={leadingIcon} size={calcIconSize} />}
      {variant !== "icon" && props.children}
      {variant === "icon" && <Icon Icon={props.children} size={calcIconSize} style={{ color: "inherit" }} />}
    </RadixToggle.Root>
  );
});

export default Toggle;
