import useSegment from "@/hooks/useSegment";
import { designPreviewToggledAtom, searchAtom } from "@/stores/Location";
import { allTagsInProject, projectIdAtom, projectSidebarCollapsedAtom } from "@/stores/Project";
import {
  pageFiltersOptionsAtom,
  PROJECT_FILTERS,
  selectedFiltersAtomFamily,
  selectedFiltersListAtom,
} from "@/stores/ProjectFiltering";
import {
  hiddenFieldsListAtom,
  resetHiddenFieldsActionAtom,
  updateHiddenFieldsActionAtom,
} from "@/stores/TextItemMetadata";
import { deferredUsersAtom } from "@/stores/Workspace";
import DesignFilterBar from "@ds/organisms/FilterBar";
import { useAtomValue } from "jotai";
import { Suspense } from "react";

function FilterBar() {
  const projectId = useAtomValue(projectIdAtom);

  if (!projectId) return null;

  return (
    <Suspense fallback={<DesignFilterBar.Fallback />}>
      <DesignFilterBar
        filterContextId={projectId}
        designPreviewToggledAtom={designPreviewToggledAtom}
        searchAtom={searchAtom}
        sidebarCollapseStateAtom={projectSidebarCollapsedAtom}
        hiddenFieldsListAtom={hiddenFieldsListAtom}
        resetHiddenFieldsActionAtom={resetHiddenFieldsActionAtom}
        updateHiddenFieldsActionAtom={updateHiddenFieldsActionAtom}
        useSegment={useSegment}
        filters={PROJECT_FILTERS}
        selectedFiltersValuesAtomFamily={selectedFiltersAtomFamily}
        pagesAtom={pageFiltersOptionsAtom}
        selectedFiltersAtom={selectedFiltersListAtom}
        usersAtom={deferredUsersAtom}
        tagsAtom={allTagsInProject}
      />
    </Suspense>
  );
}

FilterBar.Fallback = DesignFilterBar.Fallback;

export default FilterBar;
