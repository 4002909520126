import { ZObjectId } from "@shared/types/lib";
import { ZLibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { z } from "zod";

export const ZGetLibraryComponentFoldersRequest = {
  query: z.object({
    folderIds: z.array(z.string()).optional(),
    fields: z.string().optional(),
  }),
};
export const ZGetLibraryComponentFoldersResponse = z.object({
  folders: z.array(ZLibraryComponentFolder),
});
export const GetLibraryComponentFoldersMethod = "GET";
export const GetLibraryComponentFoldersPath = "/library-component-folder";

export const ZCreateLibraryComponentFolderRequest = {
  body: z.object({
    _id: ZObjectId.optional(),
    name: z.string(),
    parentId: ZObjectId.nullable(),
  }),
};

export const ZCreateLibraryComponentFolderResponse = z.object({
  newComponentFolder: ZLibraryComponentFolder,
});
export const CreateLibraryComponentFolderMethod = "POST";
export const CreateLibraryComponentFolderPath = "/library-component-folder";
