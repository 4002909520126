import Button from "@ds/atoms/Button";
import React from "react";
import style from "./style.module.css";

interface IDeleteTextItemProps {
  text: string;
  onClick: () => void;
}

function DeleteItem(props: IDeleteTextItemProps) {
  return (
    <div className={style.DeleteTextItemWrapper}>
      <Button size="micro" level="subtle" onClick={props.onClick}>
        <span className={style.deleteText}>{props.text}</span>
      </Button>
    </div>
  );
}

export default DeleteItem;
