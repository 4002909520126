import VariableRichTextArea from "@/components/VariableTextArea/VariableRichTextArea";
import useBetterLocalStorage from "@/hooks/useBetterLocalStorage";
import { client } from "@/http/lib/dittoClient";
import { useWorkspace } from "@/store/workspaceContext";
import { libraryComponentFoldersListAtom } from "@/stores/ComponentFolders";
import {
  createLibraryComponentActionAtom,
  libraryCreateComponentModalIsOpenAtom,
  libraryCreateComponentModalSelectedFolderId,
} from "@/stores/Library";
import Button from "@ds/atoms/Button";
import { NO_ITEMS_VALUE } from "@ds/molecules/BaseCombobox";
import Callout from "@ds/molecules/Callout";
import CompactInputLabel from "@ds/molecules/CompactInputLabel";
import DialogueModal from "@ds/molecules/DialogueModal";
import LibraryComponentAutoNameInput, { fetchAutoName } from "@ds/molecules/LibraryComponentAutoNameInput";
import LibraryComponentFolderFilterDropdown, {
  ALL_COMPONENTS,
} from "@ds/molecules/LibraryComponentFolderFilterDropdown";
import Modal from "@ds/molecules/Modal";
import { TextItemStatic } from "@ds/molecules/TextItem";
import { DEFAULT_RICH_TEXT } from "@shared/common/constants";
import { ITextItemVariable, ITipTapRichText } from "@shared/types/TextItem";
import { IVariable } from "@shared/types/Variable";
import { atom, Atom, PrimitiveAtom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import React, { Suspense, useCallback, useMemo, useRef, useState } from "react";
import useDebouncedCallback from "../../../../../../util/useDebouncedCallback";
import style from "./style.module.css";

const REGENERATE_AUTO_NAME_DEBOUNCE_MS = 300;

function LibraryCreateComponentModal() {
  const workspace = useWorkspace();

  const [hasCreatedComponent, setHasCreatedComponent] = useBetterLocalStorage<boolean>(
    "HAS_CREATED_LIBRARY_COMPONENT",
    false
  );
  const [libraryCreateComponentModalIsOpen, setLibraryCreateComponentModalIsOpen] = useAtom(
    libraryCreateComponentModalIsOpenAtom
  );

  const createLibraryComponentAction = useSetAtom(createLibraryComponentActionAtom);

  const componentTextAtomRef = useRef(atom(""));
  componentTextAtomRef.current.debugLabel = "componentTextAtom";
  const componentRichTextAtomRef = useRef(atom(DEFAULT_RICH_TEXT));
  componentRichTextAtomRef.current.debugLabel = "componentRichTextAtom";

  const [userHasManuallyUpdatedComponentName, setUserHasManuallyUpdatedComponentName] = useState(false);

  const componentNameBaseAtomRef = useRef(atom<string | Promise<string>>(""));
  componentNameBaseAtomRef.current.debugLabel = "componentNameBaseAtom";

  const componentNameAtomRef = useRef(
    atom(
      (get) => get(componentNameBaseAtomRef.current),
      (_, set, value: string | Promise<string>, isManualUpdate = true) => {
        set(componentNameBaseAtomRef.current, value);
        if (isManualUpdate) {
          setUserHasManuallyUpdatedComponentName(true);
        }
      }
    )
  );
  componentNameAtomRef.current.debugLabel = "componentNameAtom";
  const setComponentName = useSetAtom(componentNameAtomRef.current);

  const componentVariablesAtomRef = useRef(atom<ITextItemVariable[]>([]));
  componentVariablesAtomRef.current.debugLabel = "componentVariablesAtom";

  const resetModal = useAtomCallback(
    useCallback(
      function _resetModal(_, set) {
        set(componentTextAtomRef.current, "");
        set(componentRichTextAtomRef.current, DEFAULT_RICH_TEXT);
        set(componentNameAtomRef.current, "");
        set(componentVariablesAtomRef.current, []);
        setUserHasManuallyUpdatedComponentName(false);
      },
      [setUserHasManuallyUpdatedComponentName]
    )
  );

  const [confirmShouldDiscardUnsavedChanges, unsavedChangesModalProps] = DialogueModal.useUnsavedChangesModal(
    "This component will not be created."
  );

  const getComponentName = useAtomCallback(useCallback((get) => get(componentNameAtomRef.current), []));
  const getComponentText = useAtomCallback(useCallback((get) => get(componentTextAtomRef.current), []));

  const handleOpenChange = useCallback(
    async function handleOpenChange(open: boolean) {
      if (open) {
        setLibraryCreateComponentModalIsOpen(true);
        return;
      }

      // `getComponentName` can return a promise or a string; we actually don't care which,
      // as a truthy string means a component name is set, a promise (which is truthy) indicates
      // that a component name is actively loading, and either case means should confirm
      // that the user wants to discard changes in flight
      const hasComponentName = !!getComponentName();
      const hasComponentText = !!getComponentText();

      const shouldConfirm = hasComponentText || hasComponentName;
      const shouldClose = shouldConfirm ? await confirmShouldDiscardUnsavedChanges() : true;
      if (shouldClose) {
        setLibraryCreateComponentModalIsOpen(false);
        resetModal();
      }
    },
    [
      setLibraryCreateComponentModalIsOpen,
      resetModal,
      getComponentName,
      getComponentText,
      confirmShouldDiscardUnsavedChanges,
    ]
  );

  const onCreate = useCallback(
    async function _handleCreate(props: {
      folderId: string | null;
      name: string;
      richText: ITipTapRichText;
      text: string;
      variables: ITextItemVariable[];
    }) {
      createLibraryComponentAction({
        ...props,
        workspaceId: workspace.workspaceInfo._id,
      });
      resetModal();
      handleOpenChange(false);
      setHasCreatedComponent(true);
    },
    [createLibraryComponentAction, handleOpenChange, resetModal, workspace.workspaceInfo._id, setHasCreatedComponent]
  );

  const regenerateAutoName = useAtomCallback(
    useCallback(
      (get) => {
        const text = get(componentTextAtomRef.current);
        if (text === "") return setComponentName("", false);

        const variables = get(componentVariablesAtomRef.current).map((variable) => ({
          name: variable.name,
          variable_id: variable.variable_id,
          type: variable.type,
          data: variable.data,
        })) as ITextItemVariable[];

        setComponentName(fetchAutoName(client, text, variables), false);
      },
      [setComponentName]
    )
  );

  const createLibraryComponentProps = useCreateLibraryComponent({
    libraryCreateComponentModalIsOpen,
    onCreate,
    regenerateAutoName,
    userHasManuallyUpdatedComponentName,
    foldersAtom: libraryComponentFoldersListAtom,
    componentNameAtom: componentNameAtomRef.current,
    componentTextAtom: componentTextAtomRef.current,
    componentRichTextAtom: componentRichTextAtomRef.current,
    componentVariablesAtom: componentVariablesAtomRef.current,
  });

  const modalDescription =
    "Text components can be reused everywhere to create and manage consistency across projects and in designs.";

  return (
    <>
      <DialogueModal {...unsavedChangesModalProps} additionalZIndex={2} />
      {libraryCreateComponentModalIsOpen && <div className={style.modalOverlay} />}
      <Modal
        headline="Create text component"
        description={modalDescription}
        open={libraryCreateComponentModalIsOpen}
        onOpenChange={handleOpenChange}
        modal={false}
        className={style.modalContainer}
      >
        <div className={style.contentContainer}>
          {!hasCreatedComponent && <Callout content={modalDescription} variant="secondary" className={style.callout} />}
          <CompactInputLabel
            label="Text"
            input={<VariableRichTextArea {...createLibraryComponentProps.componentText} />}
          />
          <CompactInputLabel
            label="Publish to..."
            input={<LibraryComponentFolderFilterDropdown {...createLibraryComponentProps.folderDropdown} />}
          />
          <CompactInputLabel
            label="Component name"
            helper="You can change component names at any time"
            input={<LibraryComponentAutoNameInput {...createLibraryComponentProps.autoNameInput} />}
          />
          {createLibraryComponentProps.textItem.defaultText && (
            <CompactInputLabel
              label="Component preview"
              input={<TextItemStatic {...createLibraryComponentProps.textItem} className={style.componentPreview} />}
            />
          )}
          <div className={style.footer}>
            <Button level="outline" onClick={() => handleOpenChange(false)}>
              Cancel
            </Button>
            <CreateButton
              onCreate={createLibraryComponentProps.onCreate}
              componentText={createLibraryComponentProps.textItem.defaultText}
              componentNameAtom={createLibraryComponentProps.textItem.component.name}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

interface ICreateButtonProps {
  onCreate: () => void;
  componentText: string;
  componentNameAtom: PrimitiveAtom<string | Promise<string>>;
}

function CreateButton(props: ICreateButtonProps) {
  return (
    <Suspense fallback={<Button disabled>Create</Button>}>
      <CreateButtonContent {...props} />
    </Suspense>
  );
}

function CreateButtonContent(props: ICreateButtonProps) {
  const componentName = useAtomValue(props.componentNameAtom);
  return (
    <Button onClick={props.onCreate} disabled={!props.componentText || !componentName}>
      Create
    </Button>
  );
}

const textLabelLeft = <div></div>;

export function useCreateLibraryComponent(props: {
  userHasManuallyUpdatedComponentName: boolean;
  foldersAtom: Atom<{ _id: string; name: string }[] | Promise<{ _id: string; name: string }[]>>;
  componentTextAtom: PrimitiveAtom<string>;
  componentRichTextAtom: PrimitiveAtom<ITipTapRichText>;
  componentNameAtom: PrimitiveAtom<string | Promise<string>>;
  componentVariablesAtom: PrimitiveAtom<ITextItemVariable[]>;
  libraryCreateComponentModalIsOpen: boolean;
  regenerateAutoName: () => void;
  onCreate: (props: {
    folderId: string | null;
    name: string;
    richText: ITipTapRichText;
    text: string;
    variables: ITextItemVariable[];
  }) => Promise<void>;
}) {
  const { regenerateAutoName } = props;
  const folders = useAtomValue(props.foldersAtom);
  const [richText, setRichText] = useAtom(props.componentRichTextAtom);
  const [text, setText] = useAtom(props.componentTextAtom);
  const [variables, setVariables] = useAtom(props.componentVariablesAtom);

  const [selectedFolderId, setSelectedFolderId] = useAtom(libraryCreateComponentModalSelectedFolderId);
  const selectedFolder = useMemo(() => {
    if (!selectedFolderId) {
      return null;
    }

    if (selectedFolderId === ALL_COMPONENTS.value) {
      return { _id: ALL_COMPONENTS.value, name: ALL_COMPONENTS.label };
    }

    return folders.find((f) => f._id === selectedFolderId) ?? null;
  }, [folders, selectedFolderId]);

  const onFolderChange = useCallback(
    (folder: { _id: string; name: string } | null) => {
      if (!folder || folder?._id === NO_ITEMS_VALUE) setSelectedFolderId(null);
      else setSelectedFolderId(folder._id);
    },
    [setSelectedFolderId]
  );
  const onEnablePlurals = useCallback(() => {}, []);

  const regenerateAutoNameDebounced = useDebouncedCallback(regenerateAutoName, REGENERATE_AUTO_NAME_DEBOUNCE_MS);
  const { componentNameAtom, userHasManuallyUpdatedComponentName } = props;
  const maybeRegenerateAutoName = useAtomCallback(
    useCallback(
      async (get) => {
        const componentName = await get(componentNameAtom);
        if (componentName === "" || !userHasManuallyUpdatedComponentName) {
          regenerateAutoNameDebounced({});
        }
      },
      [regenerateAutoNameDebounced, componentNameAtom, userHasManuallyUpdatedComponentName]
    )
  );

  const handleTextChange = useCallback(
    async (
      newInputValue: {
        text: string;
        variables: IVariable[];
      },
      richText: ITipTapRichText | undefined
    ) => {
      if (richText) {
        setRichText(richText);
        setText(newInputValue.text);
        setVariables(
          newInputValue.variables.map((variable) => ({
            name: variable.name,
            variable_id: variable.variable_id,
            type: variable.type,
            data: variable.data,
          })) as ITextItemVariable[]
        );
        maybeRegenerateAutoName();
      }
    },
    [setRichText, setText, setVariables, maybeRegenerateAutoName]
  );

  const onCreate = useAtomCallback(
    useCallback(
      async (get) => {
        props.onCreate({
          folderId: selectedFolder && selectedFolder._id !== ALL_COMPONENTS.value ? selectedFolder?._id : null,
          name: await get(props.componentNameAtom),
          richText,
          text: text,
          variables,
        });
      },
      [props, richText, selectedFolder, text, variables]
    )
  );

  const onRegenerateAutoName = useCallback(() => {
    regenerateAutoName();
  }, [regenerateAutoName]);

  return {
    folderDropdown: {
      selectedFolder,
      foldersAtom: props.foldersAtom,
      onChange: onFolderChange,
    },
    autoNameInput: {
      valueAtom: props.componentNameAtom,
      onRegenerateAutoName,
    },
    componentText: {
      // This is dumb, but the component defaults to "No Text." on empty string and we don't want anything to show up.
      placeholder: " ",
      className: style.richTextContainer,
      value: {},
      hideTopLabels: true,
      useNSLabels: true,
      onEnablePlurals,
      handleTextChange,
      isBaseText: true,
      isVariant: false,
      readonly: false,
      shouldShowRichText: true,
      characterLimitDisabled: false,
      disabled: false,
      hideLabels: true,
      textLabelLeft: textLabelLeft,
      pluralInputsDisabled: true,
    },
    textItem: {
      defaultValue: richText,
      defaultText: text,
      component: {
        name: props.componentNameAtom,
      },
    },
    onCreate,
  };
}

export default LibraryCreateComponentModal;
