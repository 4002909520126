import React from "react";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export function BoltIcon(props: IProps) {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path d="M2.5 13.25L3.125 8.875H0L5.625 0.75H6.875L6.25 5.75H10L3.75 13.25H2.5Z" fill="currentColor" />
    </svg>
  );
}

export default BoltIcon;
