// import { BubbleMenu, EditorContent, FloatingMenu, useEditor } from "@tiptap/react";
import { Editor, EditorContent } from "@tiptap/react";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import getExtensions from "../../../../shared/frontend/richText/templates";
import { escapeRegExp } from "../../../../shared/lib/regex";
import { ITipTapRichText } from "../../../../shared/types/TextItem";
import Scrollbar from "../../molecules/Scrollbar";
import style from "./index.module.css";
import { ShiftEnterExtension } from "./ShiftEnterExtension";

interface IProps {
  style?: React.CSSProperties;
  className?: string;

  autoFocus?: boolean;
  content?: ITipTapRichText;
  highlightedPhrase?: string | null;
  editable?: boolean; // determines whether or not the user can click directly into the text area
  editing?: boolean; // when this goes from true -> false, causes the text area value to reset
  placeholder?: string;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onTextChange?: (richText: ITipTapRichText) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onEnter?: (inputValue: ITipTapRichText) => void;
}

export function EditableTextArea(props: IProps) {
  const { onKeyDown, onTextChange, onEnter } = props;

  const editor = useMemo(
    function createEditor() {
      const editor = new Editor({
        extensions: [
          ...getExtensions({
            placeholder: props.placeholder,
            emptyEditorClass: "emptyEditor",
          }),
          ShiftEnterExtension,
        ],
        editorProps: {
          attributes: {
            class: `${style.editorContainer}`,
            "data-rich-text": "enabled",
            "data-suberscript": "enabled",
            "data-testid": props.editable ? "text-item-input" : "text-item-input-disabled",
          },
          handleKeyDown: (editorView, e) => {
            if (e.key === "Enter" && !e.shiftKey && onEnter) {
              e.stopPropagation();
              onEnter(editorView.state.doc.toJSON() as ITipTapRichText);
              return true;
            }
          },
        },
        autofocus: props.autoFocus ?? false, // Note: Undefined has weird behavior, so defaulting to false
        content: props.content,
        onUpdate({ editor }) {
          onTextChange?.(editor.getJSON() as ITipTapRichText);
        },
      });

      return editor;
    },
    [onEnter, onTextChange, props.autoFocus, props.content, props.editable, props.placeholder]
  );

  useEffect(
    function clearContentOnEditingDisabled() {
      if (!editor) {
        return;
      }

      const content = props.content;

      return () => {
        // Set the content any time the user was editing and then stops
        // Doing this in a the return to avoid doing this on mount
        if (props.editing && content) {
          editor.commands.setContent(content);
        }
      };
    },
    [editor, props.editing, props.content]
  );

  useEffect(
    function setSearchTerm() {
      if (!editor) return;

      // don't highlight text content while editing
      if (!props.editing && props.highlightedPhrase) {
        editor.commands.setSearchTerm(escapeRegExp(props.highlightedPhrase));
      } else {
        editor.commands.setSearchTerm("");
      }
    },
    [editor, props.editing, props.highlightedPhrase]
  );

  useEffect(() => {
    if (!editor) return;

    // Focus the editor whenever editing is enabled
    if (props.editing && !editor.isFocused) {
      editor.commands.focus("end");
    }

    // Blur the editor whenever the user stops editing
    // Note: This must be done in the return rather than a simple if/else to avoid blurring on initial render,
    // which caused a bug where the blur would steal focus from other elements on the page, like the search input.
    return () => {
      if (props.editing) {
        editor.commands.blur();
      }
    };
  }, [editor, props.editing]);

  const handleEditorContentKeyDown = useCallback(
    function _handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
      // This prevents the global unselect selection on escape behavior
      if (e.key === "Escape") {
        e.stopPropagation();
      }

      onKeyDown?.(e);
    },
    [onKeyDown]
  );

  return (
    <div className={style.editableInputWrapper}>
      {/* This allows for disabling editing without re-creating the TipTap editor when editability changes, which
          often causes a noticeable flicker */}
      {!props.editable && <div className={style.readOnlyOverlay} />}
      <Scrollbar className={style.scrollbar}>
        <EditorContent
          className={classNames(style.editorContent, props.className, { [style.isInlineEditing]: props.editing })}
          editor={editor}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={handleEditorContentKeyDown}
          placeholder={props.placeholder}
        />
      </Scrollbar>
    </div>
  );
}

export default EditableTextArea;
