import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import SeparatorLine from "../../atoms/SeparatorLine";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  /** The content to be wrapped */
  children: React.ReactNode;
  /** Whether the footer is visible */
  visible?: boolean;
  /** Whether to render a separator line between the helperText and buttons */
  renderSeparator?: boolean;
  /** Helper text shown in the footer */
  helperText?: string | React.ReactNode;
  /** Primary button text */
  primaryText?: string;
  /** Primary button click handler */
  onPrimary?: () => void;
  /** Secondary button text */
  secondaryText?: string;
  /** Secondary button click handler */
  onSecondary?: () => void;
  /** The color theme to use, which determines the colors of many elements */
  theme?: "purple" | "blue" | "blue-transparent" | "purple-transparent";
  /** Optional class to apply to the outer container */
  className?: string;
  /** Optional class to apply to the footer element */
  footerClassName?: string;
}

export function ButtonWrapFooter({
  children,
  theme = "purple",
  visible = false,
  renderSeparator = false,
  helperText,
  primaryText = "Save",
  onPrimary,
  secondaryText = "Cancel",
  onSecondary,
  className,
  footerClassName,
}: IProps) {
  return (
    <div
      className={classNames(
        style.buttonWrapContainer,
        {
          [style.visible]: visible,
          [style[`theme-${theme}`]]: true,
        },
        className
      )}
    >
      <div className={style.childrenContainer}>
        {children}
        <div className={classNames(style.borderOverlay, { [style.visible]: visible })} />
      </div>

      {visible && (
        <>
          <div className={classNames(style.footer, footerClassName)}>
            {helperText && (
              <div className={style.helperTextContainer}>
                {typeof helperText === "string" ? (
                  <Text size="micro" className={style.helperText}>
                    {helperText}
                  </Text>
                ) : (
                  helperText
                )}
                {renderSeparator && <SeparatorLine className={style.separatorLine} />}
              </div>
            )}

            <div className={style.buttons}>
              {onSecondary && (
                <Button level="subtle" size="small" className={style.secondaryButton} onClick={onSecondary}>
                  {secondaryText}
                </Button>
              )}

              {onPrimary && (
                <Button level="primary" size="small" className={style.primaryButton} onClick={onPrimary}>
                  {primaryText}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ButtonWrapFooter;
