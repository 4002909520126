import { libraryComponentFamilyAtom } from "@/stores/Components";
import { componentIsSelectedAtomFamily, handleComponentClickActionAtom } from "@/stores/Library";
import { usersByIdAtom } from "@/stores/Workspace";
import TextItem, { TextItemComponentState, TextItemStatic } from "@ds/molecules/TextItem";
import { ILibraryComponentItem } from "@shared/types/Library";
import { useAtomValue, useSetAtom } from "jotai";
import { memo, useCallback } from "react";
import style from "./style.module.css";

const LibraryComponentListItem = memo(function LibraryComponentListItem(props: {
  item: ILibraryComponentItem;
  isScrolling: boolean;
}) {
  const component = useAtomValue(libraryComponentFamilyAtom(props.item._id));
  const handleComponentClickAction = useSetAtom(handleComponentClickActionAtom);
  const usersById = useAtomValue(usersByIdAtom);

  const isSelected = useAtomValue(componentIsSelectedAtomFamily(props.item._id));

  const handleComponentClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      handleComponentClickAction({
        event,
        componentId: props.item._id,
        skipInlineEditing: true,
      });
    },
    [handleComponentClickAction, props.item._id]
  );

  const state: TextItemComponentState = isSelected ? "focus" : "default";

  if (!component) return null;

  return (
    <div className={style.libraryComponentTextItemContainer}>
      <TextItem
        disableAutoscroll
        component={component ?? undefined}
        defaultText={component.text}
        defaultValue={component.rich_text}
        status={component.status}
        assignee={usersById[component.assignee ?? ""]}
        instanceCount={component.instances.length}
        tags={component.tags}
        notes={component.notes}
        state={state}
        onClick={handleComponentClick}
      />
    </div>
  );
});

export function LibraryComponentListItemFallback() {
  return (
    <div className={style.libraryComponentTextItemContainer}>
      <TextItemStatic
        disableAutoscroll
        component={{ name: "Loading..." }}
        defaultText="Loading..."
        defaultValue={{
          type: "doc",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "Loading...",
                },
              ],
            },
          ],
        }}
      />
    </div>
  );
}

export default LibraryComponentListItem;
