import SearchRankingsVisualizer from "@/views/Debug/SearchRankingsVisualizer";
import { Link, Route, Switch } from "react-router-dom";
import style from "./index.module.css";

export default function Debug() {
  return (
    <div className={style.debugPage}>
      <div className={style.top}>
        <h1>Debug</h1>

        <div className={style.links}>
          <Link className={style.link} to="/debug/search">
            Search Rankings Visualizer
          </Link>
        </div>
      </div>

      <Switch>
        <Route path="/debug/search">
          <SearchRankingsVisualizer />
        </Route>
      </Switch>
    </div>
  );
}
