/**
 * Returns a list of tags to write and list of tags to delete based on diff between original and current tags.
 *
 * @param baseTags The original base tags in selection.
 * @param tags The current tags in state.
 * @returns The lists of tags to write and delete.
 */
export function getTagsChanges(baseTags: string[][], tags: string[]) {
  const baseTagsSet = new Set(baseTags.flat());
  const tagsSet = new Set(tags);

  /**
   * The new behavior is to set all selected text items to the tags currently
   * selected in the tag list. This requires that the set of tags to create is
   * exactly the selected tags, while the set of tags to delete is all other tags
   * which are not selected.
   */
  const tagsToDelete = Array.from(baseTagsSet).filter((baseTag) => !tagsSet.has(baseTag));

  return {
    tagsToWrite: tags,
    tagsToDelete,
  };
}
