import { useLayoutEffect, useRef, useState } from "react";

/**
 * Hook for tracking the dimensions of the combobox to accurately measure the offets for top + left for the absolutely positioned options list
 * combobox border, height and width shrink and grow as the user zooms in and out
 *
 * @param textInputDisabled - if true, the combobox input element is disabled and we don't need to measure the dimensions
 * @returns dimensions and ref to the combobox div
 */
function useComboboxDimensions(textInputDisabled?: boolean) {
  const comboboxRef = useRef<HTMLDivElement>(null);
  const [comboboxBorderLeftWidth, setComboboxBorderLeftWidth] = useState(0);
  const [comboboxBorderBottomWidth, setComboboxBorderBottomWidth] = useState(0);
  const [comboboxHeight, setComboboxHeight] = useState(0);
  const [comboboxWidth, setComboboxWidth] = useState(0);

  useLayoutEffect(
    function calculateWrapperDimensions() {
      // Exit early if text input is disabled-- we don't need to measure the combobox dimensions
      if (!comboboxRef.current || textInputDisabled) return;

      function updateDimensions() {
        if (!comboboxRef.current) return;
        const style = window.getComputedStyle(comboboxRef.current);

        const wrapperHeight = parseFloat(style.height);
        if (wrapperHeight !== comboboxHeight) setComboboxHeight(wrapperHeight);
        const wrapperWidth = parseFloat(style.width);
        if (wrapperWidth !== comboboxWidth) setComboboxWidth(wrapperWidth);
        const borderLeftWidth = parseFloat(style.borderLeftWidth);
        if (borderLeftWidth !== comboboxBorderLeftWidth) setComboboxBorderLeftWidth(borderLeftWidth);
        const borderBottomWidth = parseFloat(style.borderBottomWidth);
        if (borderBottomWidth !== comboboxBorderBottomWidth) setComboboxBorderBottomWidth(borderBottomWidth);
      }

      // Create resize observer to track zoom changes
      const resizeObserver = new ResizeObserver(updateDimensions);
      resizeObserver.observe(comboboxRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    },
    [comboboxWidth, comboboxBorderLeftWidth, comboboxBorderBottomWidth, comboboxHeight, textInputDisabled]
  );

  return {
    comboboxWidth,
    comboboxBorderLeftWidth,
    comboboxBorderBottomWidth,
    comboboxHeight,
    comboboxRef,
  };
}

export default useComboboxDimensions;
