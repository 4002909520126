import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/react";
import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames";
import { Atom, getDefaultStore, useAtomValue } from "jotai";
import React, { ComponentType, Suspense, useCallback, useMemo, useState } from "react";
import Button from "../../atoms/Button";
import Checkbox from "../../atoms/Checkbox";
import Icon from "../../atoms/Icon";
import LoadingIcon from "../../atoms/LoadingIcon";
import Text from "../../atoms/Text";
import Toggle from "../../atoms/Toggle";
import { zIndexCombobox } from "../../tokens/zIndex";
import { Option } from "../MultiCombobox";
import Scrollbar from "../Scrollbar";
import useComboboxDimensions from "./hooks/useComboboxDimensions";
import useInternalState from "./hooks/useInternalState";
import style from "./index.module.css";

export const NO_ITEMS_VALUE = "__NO_ITEMS__";
export const NEW_ITEM_VALUE = "__NEW__";

export interface ComboboxOption {
  value: string;
  label: string;
  /**
   * Whether the option is a draft item created with the "Create item" combobox functionality.
   */
  isDraftItem?: boolean;
  /**
   * Whether or not the option is in a loading state. Currently only used when the entire Combobox
   * is in a loading state, but could technically be extended to support loading states on a per-option
   * basis.
   */
  isLoading?: boolean;
}

export interface IBaseComboboxProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * Message to display when options list is loaded but empty.
   */
  emptyMessage?: string;
  /**
   * The options in the combobox dropdown. Stored in an atom to be able to handle asynchronous loading states internally.
   */
  optionsAtom: Atom<ComboboxOption[] | Promise<ComboboxOption[]>>;
  /**
   * Options which should not be shown in the dropdown and cannot be created.
   */
  exclusions?: string[];

  selectedItems: ComboboxOption[];

  setSelectedItems: (newSelection: ComboboxOption[]) => void;

  /**
   * Whether the combobox allows multiple selections.
   */
  multiple?: boolean;

  /**
   * Placeholder text for the combobox input.
   */
  placeholder?: string;
  /**
   * Text to display when creating a new item.
   */
  createNewText?: string;
  /**
   * Callback function that runs when the user creates a new item in the combobox.
   */
  onCreateNew?: (value: string) => void;

  /**
   * Optional callback function that runs when the user resets the selection.
   */
  onResetSelection?: () => void;

  /**
   * Optional text to display in the reset selection button.
   */
  resetSelectionText?: string;
  /**
   * Autofocus the combobox input on render.
   */
  autoFocus?: boolean;
  /**
   * Flag to have combobox options opened by default.
   */
  isDefaultOpen?: boolean;
  /**
   * Hide the down arrow icon.
   */
  hideDownArrow?: boolean;
  /**
   * The size of the combobox.
   */
  size?: "base" | "small" | "micro";

  /**
   * Jotai store used for atom values.
   */
  store?: ReturnType<typeof getDefaultStore>;

  /**
   * Determines how combobox is rendered, standard or as popover (defaults to standard).
   */
  displayProps?: StandardDisplay | PopoverDisplayProps;

  /**
   * Set to true to hide the combobox text input.
   */
  textInputDisabled?: boolean;

  /**
   * Sets z-index for combobox content.
   */
  zIndex?: number;

  /**
   * Flag to set whether multi-selected items should be rendered with a checkbox (default is checkmark icon).
   */
  renderMultiSelectCheckbox?: boolean;

  /**
   * Optional component for customizing option content in the combobox dropdown.
   */
  OptionContent?: ComponentType<{
    option: ComboboxOption;
    selected: boolean;
    query?: string;
  }>;
}

/**
 * Standard combobox, renders inline with input field.
 */
interface StandardDisplay {
  type: "standard";
}

/**
 * Combobox is rendered in a popover.
 */
interface PopoverDisplayProps {
  type: "popover";

  /**
   * Component to render as the trigger for the popover.
   */
  TriggerComponent: ComponentType<{
    selectedOptions: ComboboxOption[];
    size?: "base" | "small";
    hideToggledState?: boolean;
  }>;

  triggerLeadingIcon?: React.ReactNode;

  /**
   * If true, will render the popover trigger as a Toggle.
   */
  asToggle?: boolean;

  toggleClassName?: string;

  /**
   * Set to true to force the element to show the toggled state.
   */
  forceToggledDisplay?: boolean;

  /**
   * Set to false to prevent the element from showing any change when toggled.
   */
  hideToggledState?: boolean;

  hideArrow?: boolean;
}

const DEFAULT_STORE = getDefaultStore();
const LOADING_OPTION = {
  label: "",
  value: "__LOADING__",
  isLoading: true,
};

export interface IInternalStateProps {
  onFocus?: () => void;
  onBlur?: () => void;
  onOpenChange?: (open: boolean) => void;
  isLoading?: boolean;
}

export function BaseCombobox(props: IBaseComboboxProps) {
  // Keep track of whether or not the input was focused while the options were still loading.
  // If so, we want to auto-focus the loading input as soon as it mounts after the suspense finishes.
  const [loadingInputIsFocused, setLoadingInputIsFocused] = useState(false);
  const [loadingComboboxOpened, setLoadingComboboxOpened] = useState(false);
  const internalStateProps = useMemo<IInternalStateProps>(
    () => ({
      onFocus: () => setLoadingInputIsFocused(true),
      onBlur: () => setLoadingInputIsFocused(false),
      onOpenChange: (open: boolean) => setLoadingComboboxOpened(open),
    }),
    []
  );

  return (
    <Suspense
      fallback={<BaseComboboxLoading props={props} internalStateProps={{ ...internalStateProps, isLoading: true }} />}
    >
      <BaseComboboxLoaded
        props={{ ...props, autoFocus: props.autoFocus ?? loadingInputIsFocused, isDefaultOpen: loadingComboboxOpened }}
        internalStateProps={internalStateProps}
      />
    </Suspense>
  );
}

function BaseComboboxLoaded({
  props,
  internalStateProps,
}: {
  props: IBaseComboboxProps;
  internalStateProps: IInternalStateProps;
}) {
  const options = useAtomValue(props.optionsAtom, { store: props.store ?? DEFAULT_STORE });
  const state = useInternalState(props, options, internalStateProps);

  return <BaseComboboxContent rootProps={props} state={state} />;
}

function BaseComboboxLoading({
  props,
  internalStateProps,
}: {
  props: IBaseComboboxProps;
  internalStateProps: IInternalStateProps;
}) {
  const state = useInternalState(props, [...props.selectedItems, LOADING_OPTION], internalStateProps);

  return <BaseComboboxContent rootProps={props} state={state} />;
}

type BaseComboboxRenderProps = {
  state: ReturnType<typeof useInternalState>;
  rootProps: IBaseComboboxProps;
};

function BaseComboboxContent({ rootProps, state }: BaseComboboxRenderProps) {
  const { displayProps = { type: "standard" } } = rootProps;
  if (displayProps.type === "popover") {
    return <BaseComboboxWithPopover rootProps={rootProps} state={state} />;
  }

  return <BaseComboboxInner rootProps={rootProps} state={state} />;
}

function BaseComboboxWithPopover(props: BaseComboboxRenderProps) {
  const { rootProps, state } = props;
  const { displayProps, selectedItems, size } = rootProps;
  const {
    asToggle,
    hideToggledState,
    TriggerComponent,
    triggerLeadingIcon,
    forceToggledDisplay,
    toggleClassName,
    hideArrow,
  } = displayProps as PopoverDisplayProps;
  const { isOpen, handleToggleComboboxOpen, handleEscapeClicked } = state;

  const normalizedSize = size === "micro" ? "small" : size;

  const handleButtonTriggerClick = useCallback(() => {
    handleToggleComboboxOpen(!isOpen);
  }, [handleToggleComboboxOpen, isOpen]);

  return (
    <Popover.Root defaultOpen open={isOpen} onOpenChange={() => {}}>
      <Popover.Trigger asChild>
        {asToggle ? (
          <Toggle
            hideToggledState={hideToggledState}
            leadingIcon={triggerLeadingIcon}
            pressed={isOpen}
            forceToggledDisplay={forceToggledDisplay}
            onPressedChange={(pressed) => handleToggleComboboxOpen(pressed)}
            className={toggleClassName}
            size={normalizedSize}
            iconSize={size === "small" ? "xxs" : "xs"}
          >
            <TriggerComponent
              selectedOptions={selectedItems}
              size={normalizedSize}
              hideToggledState={hideToggledState}
            />
          </Toggle>
        ) : (
          <button
            data-testid="dropdown-combobox-trigger"
            className={classNames(style.trigger, { [style.open]: isOpen })}
            onClick={handleButtonTriggerClick}
          >
            <TriggerComponent
              selectedOptions={selectedItems}
              size={normalizedSize}
              hideToggledState={hideToggledState}
            />
            {!hideArrow && <Icon Icon={isOpen ? <ExpandLess /> : <ExpandMore />} size="xs" color="secondary" />}
          </button>
        )}
      </Popover.Trigger>

      <Popover.Content
        align="start"
        sideOffset={6}
        className={style.comboboxPopoverWrapper}
        data-testid="dropdown-combobox-content"
        onEscapeKeyDown={(e) => handleEscapeClicked(e)}
      >
        <BaseComboboxInner rootProps={rootProps} state={state} />
      </Popover.Content>
    </Popover.Root>
  );
}

function BaseComboboxInner(props: BaseComboboxRenderProps) {
  const {
    state: {
      query,
      isOpen,
      showCreateNew,
      options,
      showFocusBorder,

      inputRef,
      optionsListRef,

      onChangeValue,
      handleKeyDown,
      onInputChange,
      handleBlur,
      handleFocus,
      handleInputClick,
      handleToggleComboboxOpen,
    },
    rootProps,
  } = props;

  const {
    emptyMessage = "No items available",
    multiple,
    selectedItems,
    OptionContent,
    renderMultiSelectCheckbox = false,
    onResetSelection,
    resetSelectionText = "Reset",
    size,
  } = rootProps;

  const { comboboxWidth, comboboxBorderLeftWidth, comboboxBorderBottomWidth, comboboxHeight, comboboxRef } =
    useComboboxDimensions(rootProps.textInputDisabled);

  const showEmptyMessage = options.length === 0 && !showCreateNew;
  const value = multiple ? selectedItems : selectedItems[0] ?? null;
  const renderClearSelectionButton = onResetSelection && !showEmptyMessage;
  const renderSelectionCheckbox = renderMultiSelectCheckbox && multiple;
  const optionsListStyle = {
    ...(comboboxWidth ? { width: comboboxWidth } : {}),
    ...(comboboxHeight && comboboxBorderBottomWidth ? { top: comboboxHeight - comboboxBorderBottomWidth } : {}),
    ...(comboboxBorderLeftWidth ? { left: -1 * comboboxBorderLeftWidth } : {}),
    ...(rootProps.zIndex ? { zIndex: rootProps.zIndex } : { zIndex: zIndexCombobox }),
  };

  let inputValue: string;
  // if the option list is open, the input should reflect the value
  // of the current query so the user can modify it to manipulate the list
  if (isOpen) {
    inputValue = query;
  }
  // otherwise, if the option list is closed, the input should reflect either
  // the selected item OR the query (if either is set)
  else {
    inputValue = multiple ? "" : selectedItems[0]?.label ?? "";
  }

  const _OptionContent = useCallback(
    (option: ComboboxOption, selected: boolean) => {
      return OptionContent ? (
        <OptionContent option={option} query={query} selected={selected} />
      ) : (
        <>
          <Text truncate={true} size={size}>
            {option.label}
          </Text>
        </>
      );
    },
    [OptionContent, query, size]
  );

  const handleDownArrowClick = useCallback(() => {
    inputRef.current?.focus();
    handleToggleComboboxOpen(true);
  }, [handleToggleComboboxOpen, inputRef]);

  return (
    <Combobox immediate={false} multiple={rootProps.multiple} onChange={onChangeValue} value={value} by="value">
      <div
        className={classNames(style.combobox, {
          [style.open]: isOpen,
          [style.hidden]: rootProps.textInputDisabled,
          [style[`size-${rootProps.size}`]]: rootProps.size,
          [style.focusBorder]: showFocusBorder,
        })}
        ref={comboboxRef}
      >
        <ComboboxInput
          data-testid="combobox-input"
          autoFocus={rootProps.autoFocus}
          placeholder={rootProps.placeholder}
          onKeyDown={handleKeyDown}
          onChange={onInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseDown={handleInputClick}
          className={classNames(style.input, { [style.hidden]: rootProps.textInputDisabled })}
          value={inputValue}
          ref={inputRef}
          autoComplete="off"
        />
        {!rootProps.hideDownArrow && !isOpen && (
          <div className={style.downArrow} onClick={handleDownArrowClick}>
            <Icon Icon={<KeyboardArrowDownIcon />} size="xs" className={style.dropdownIcon} />
          </div>
        )}

        {isOpen && (
          <ComboboxOptions
            // set static to true in order to control combobox open/close behavior
            static={true}
            modal={false}
            anchor={false}
            style={optionsListStyle}
            className={classNames(style.optionsList, { [style.textInputDisabled]: rootProps.textInputDisabled })}
          >
            <Scrollbar className={style.scrollbar} viewportClassName={style.viewport}>
              <div className={style.filteredOptions} ref={optionsListRef}>
                {options.map((option) => {
                  if (option.isLoading) {
                    return (
                      <Option
                        key={`option-${option.value}`}
                        className={classNames(style.option, style.loading)}
                        innerClassName={style.optionInner}
                        label={option.label}
                        value={option.value}
                      >
                        {/* Ensure this remains a reference instead of an inline function; an inline
                      function causes re-mounting on render which can break the CSS animation */}
                        {OptionLoadingContents}
                      </Option>
                    );
                  }

                  return (
                    <Option
                      key={`option-${option.value}`}
                      className={classNames(style.option)}
                      innerClassName={style.optionInner}
                      label={option.label}
                      value={option.value}
                    >
                      {({ selected }) => {
                        return (
                          <>
                            {!renderSelectionCheckbox && (
                              <Icon
                                Icon={<CheckIcon />}
                                size="xxs"
                                className={classNames({ [style.hidden]: !selected })}
                              />
                            )}
                            {renderSelectionCheckbox && (
                              <Checkbox checked={selected} onChange={() => {}} size="xs" color="invert" />
                            )}

                            {_OptionContent(option, selected)}
                          </>
                        );
                      }}
                    </Option>
                  );
                })}
                {showEmptyMessage && <OptionEmptyContents message={emptyMessage} />}
                {showCreateNew && <CreateNewOption query={query} />}
              </div>
              {renderClearSelectionButton && (
                <ResetSelectionButton onResetSelection={onResetSelection} resetSelectionText={resetSelectionText} />
              )}
            </Scrollbar>
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}

// Text must be static so that this component can be passed by reference,
// avoiding re-mounting each render, and avoiding interrupting the animation
function OptionLoadingContents() {
  return (
    <>
      <LoadingIcon size="xxs" />
      <Text color="secondary" size="micro">
        Loading...
      </Text>
    </>
  );
}

function OptionEmptyContents(props: { message: string }) {
  return (
    <div className={classNames(style.option, style.empty)}>
      <Text color="secondary" size="micro">
        {props.message}
      </Text>
    </div>
  );
}

function CreateNewOption(props: { query: string }) {
  return (
    <Option
      key={`option-${NEW_ITEM_VALUE}`}
      className={classNames(style.option, style.createNewOptionWrapper)}
      innerClassName={style.optionInner}
      label={props.query}
      value={NEW_ITEM_VALUE}
    >
      {() => (
        <>
          <Icon Icon={<Add />} size="xs" className={style.createNewIcon} />
          <Text truncate>{"Create: " + props.query}</Text>
        </>
      )}
    </Option>
  );
}

function ResetSelectionButton(props: { onResetSelection: () => void; resetSelectionText?: string }) {
  return (
    <div className={style.resetSelectionButton}>
      <Button level="subtle" size="small" onClick={props.onResetSelection}>
        <Text size="small" color="secondary" weight="light">
          {props.resetSelectionText ?? "Clear"}
        </Text>
      </Button>
    </div>
  );
}

export default BaseCombobox;
