import { client } from "@/http/lib/dittoClient";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import { TextItemStatic } from "@ds/molecules/TextItem";
import { ISearchMatcherName, ISearchScoreBreakdown } from "@shared/types/LibraryComponent";
import classNames from "classnames";
import { useEffect, useState } from "react";
import style from "./SearchRankingsVisualizer.module.css";

// make sure these precisely match the names in services/libraryComponent/search.ts!
const DEFAULT_BOOSTS: Record<ISearchMatcherName, number> = {
  "Exact name match": 15,
  "Exact text match": 15,
  "Exact tag match": 15,
  "Partial name match": 5,
  "Partial text match": 3,
  "Name autocomplete": 2,
  "Text autocomplete": 2,
} as const;

const BOOST_DESCRIPTIONS: Record<ISearchMatcherName, string> = {
  "Exact name match": "Query exactly matches the full name of the component.",
  "Exact text match": "Query exactly matches the full text of the component.",
  "Exact tag match": "Query exactly matches AT LEAST ONE tag of the component.",
  "Partial name match": "Query matches at least one full word in the component's name.",
  "Partial text match": "Query matches at least one full word in the component's text.",
  "Name autocomplete": "Query matches any substring, up to 15 letters, of the component's name.",
  "Text autocomplete": "Query matches any substring, up to 15 letters, of the component's text.",
} as const;

function getInitialStateFromURL() {
  const params = new URLSearchParams(window.location.search);
  const query = params.get("q") || "";
  const boostsParam = params.get("boosts");

  let boosts = DEFAULT_BOOSTS;
  if (boostsParam) {
    try {
      boosts = JSON.parse(decodeURIComponent(boostsParam));
    } catch (e) {
      console.warn("Failed to parse boosts from URL", e);
    }
  }

  return { query, boosts };
}

function updateURL(query: string, boosts: typeof DEFAULT_BOOSTS) {
  const params = new URLSearchParams();
  if (query) params.set("q", query);
  params.set("boosts", encodeURIComponent(JSON.stringify(boosts)));

  const newURL = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, "", newURL);
}

export default function SearchRankingsVisualizer() {
  const [query, setQuery] = useState(() => getInitialStateFromURL().query);
  const [searchResults, setSearchResults] = useState<ISearchScoreBreakdown[]>([]);
  const [boosts, setBoosts] = useState(() => getInitialStateFromURL().boosts);
  const [lastEditedPriority, setLastEditedPriority] = useState<string | null>(null);
  const [animationKey, setAnimationKey] = useState(0);

  async function search(query: string) {
    const response = await client.libraryComponent.searchLibraryComponentsDebug({
      query,
      customBoosts: boosts,
    });

    setSearchResults(response.searchResults);
  }

  useEffect(
    function searchOnQueryChange() {
      search(query);
      updateURL(query, boosts);
    },
    [query, boosts]
  );

  return (
    <div className={style.visualizerContainer}>
      <div className={style.searchArea}>
        <h2>Search</h2>
        <TextInput value={query} onChange={setQuery} />
        <Text style={{ fontStyle: "italic" }} color="secondary" size="small">
          Note: if you copy the URL, it will save the current boosts and query.
        </Text>
        <div className={style.results}>
          {searchResults.map(({ fullComponent, searchFactors, totalScore }) => (
            <div className={style.resultRow} key={fullComponent._id}>
              <TextItemStatic
                className={style.resultTextItem}
                level="compact"
                state={"default"}
                component={fullComponent}
                showComponentInstances
                defaultText={fullComponent.text}
                defaultValue={fullComponent.rich_text}
                status={fullComponent.status}
                notes={fullComponent.notes}
                tags={fullComponent.tags}
                instanceCount={fullComponent.instances.length}
              />

              <div className={style.scores}>
                <Text size="large">Total score: {totalScore.toFixed(2)}</Text>

                <div className={style.searchFactors}>
                  {searchFactors.map(({ debugName, score }) => (
                    <div key={debugName} className={style.searchFactor}>
                      <Text>{debugName}</Text>
                      <Text>{score.toFixed(2)}</Text>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={style.prioritiesArea}>
        <h2>Search Priorities</h2>
        <div className={style.priorities}>
          <table>
            <tbody>
              <tr>
                <th>
                  <Text size="large" weight="strong">
                    Boost Value
                  </Text>
                </th>
                <th>
                  <Text size="large" weight="strong">
                    Name
                  </Text>
                </th>
              </tr>

              {Object.entries(boosts)
                .sort(([_, valueA], [__, valueB]) => valueB - valueA)
                .map(([name, value]) => (
                  <tr
                    key={name}
                    className={classNames(style.priority, {
                      [style.priorityAnimated]: lastEditedPriority === name,
                    })}
                    data-animation-key={lastEditedPriority === name ? animationKey : undefined}
                  >
                    <td>
                      <input
                        type="number"
                        value={value}
                        onChange={(e) => {
                          setBoosts({ ...boosts, [name]: Number(e.target.value) });
                          setLastEditedPriority(name);
                          setAnimationKey((k) => k + 1);
                          setTimeout(() => setLastEditedPriority(null), 750);
                        }}
                      />
                    </td>
                    <td className={style.priorityName}>
                      <div className={style.priorityNameContent}>
                        <Text>{name}</Text>
                        <Text color="secondary" size="micro">
                          {BOOST_DESCRIPTIONS[name]}
                        </Text>
                        {/* <Tooltip content={BOOST_DESCRIPTIONS[name]} type="invert">
                          <Icon className={style.icon} color="secondary" size="xs" Icon={<InfoOutlined />} />
                        </Tooltip> */}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Button onClick={() => setBoosts(DEFAULT_BOOSTS)}>Reset to Defaults</Button>
        </div>
      </div>
    </div>
  );
}
