import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Icon, { IProps as IIconProps } from "../../atoms/Icon";
import Label from "../../atoms/Label";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IBaseCalloutProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The content of the callout. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  content?: string | React.ReactNode;

  /**
   * Optional clickable action text. Used in conjunction with onActionClick prop.
   */
  actionText?: string;

  /**
   * Handler for when the action text is clicked.
   */
  onActionClick?: () => void;

  /**
   * Style variants that affect the presentation of the callout.
   */
  variant?: "secondary" | "info" | "warning" | "purple";

  /**
   * Header text for your callout.
   */
  header?: string;

  /**
   * Optional weight for the header text.
   */
  headerWeight?: "medium" | "semibold" | "bold";

  /**
   * Optional flag to render the callout with square corners.
   */
  squareCorners?: boolean;

  /**
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   */
  leadingIcon?: React.ReactNode;

  iconColor?: IIconProps["color"];
  spacing?: "default" | "spacing-lg";
}

export function CalloutBase({
  content,
  variant = "info",
  spacing = "default",
  header,
  headerWeight = "semibold",
  leadingIcon,
  iconColor,
  squareCorners = false,
  ...props
}: IBaseCalloutProps) {
  const renderedContent = useCalloutContent({
    header,
    headerWeight,
    variant,
    content,
    actionText: props.actionText,
    onActionClick: props.onActionClick,
  });

  return (
    <div
      style={props.style}
      className={classNames(
        style.CalloutWrapper,
        {
          [style[variant]]: true,
          [style.squareCorners]: squareCorners,
        },
        props.className
      )}
      data-testid="callout"
    >
      {leadingIcon && !header && (
        <Icon
          className={style.leadingIcon}
          Icon={leadingIcon}
          color={iconColor}
          size="xs"
          data-testid="content-leading-icon"
        />
      )}

      <div className={style.textWrapper}>{renderedContent}</div>
    </div>
  );
}

interface ICalloutWithNudgeProps extends IBaseCalloutProps {
  /**
   * The content to be shown when the callout is expanded. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  nudgeContent: string | React.ReactNode;

  /**
   * Optionally set callout to open by default.
   */
  defaultExpanded?: boolean;
}

export function CalloutWithNudge({
  content,
  variant = "info",
  spacing = "default",
  header,
  headerWeight = "semibold",
  leadingIcon,
  iconColor,
  nudgeContent,
  defaultExpanded = false,
  squareCorners = false,
  ...props
}: ICalloutWithNudgeProps) {
  const calloutRef = useRef<HTMLDivElement>(null);

  const renderedContent = useCalloutContent({
    header,
    headerWeight,
    variant,
    content,
    actionText: props.actionText,
    onActionClick: props.onActionClick,
  });

  const { nudgeState, setNudgeState, renderedNudgeContent } = useNudgeContent({ nudgeContent, defaultExpanded });

  useEffect(
    // We record the height of the callout on mount, *before* the nudge gets expanded, so that we can have a clickable
    // region that's only the height of the un-expanded callout.
    function calculateCalloutHeight() {
      if (calloutRef.current) {
        const calloutHeight = calloutRef.current.getBoundingClientRect().height;
        document.documentElement.style.setProperty("--callout-container-height", `${calloutHeight}px`);
      }
    },
    []
  );

  function handleExpandToggle() {
    setNudgeState(nudgeState === "open" ? "closed" : "open");
  }

  return (
    <div
      style={props.style}
      className={classNames(
        style.CalloutWrapper,
        {
          [style[variant]]: true,
          [style.squareCorners]: squareCorners,
        },
        props.className
      )}
      data-testid="callout"
      ref={calloutRef}
    >
      <div className={classNames(style.clickWrapper, style.clickable)} onClick={handleExpandToggle} />

      {leadingIcon && !header && (
        <Icon
          className={style.leadingIcon}
          Icon={leadingIcon}
          color={iconColor}
          size="xs"
          data-testid="content-leading-icon"
        />
      )}

      <div className={style.textWrapper}>
        <div className={style.nudgeSection}>{renderedContent}</div>

        {renderedNudgeContent}
      </div>

      {nudgeContent && (
        <Icon
          className={classNames(style.expandIcon, { [style.open]: nudgeState === "open" })}
          Icon={<ExpandMoreIcon />}
          size="xs"
        />
      )}
    </div>
  );
}

function useCalloutContent({
  header,
  headerWeight,
  content,
  actionText,
  variant,
  onActionClick,
}: Pick<IBaseCalloutProps, "header" | "headerWeight" | "variant" | "content" | "actionText" | "onActionClick">) {
  const renderedContent =
    typeof content === "string" ? (
      <Text size="small" className={style.content} color={variant}>
        {content}
      </Text>
    ) : (
      content
    );

  return (
    <>
      {header && (
        <Label size="sm" className={style.header} weight={headerWeight}>
          {header}
        </Label>
      )}
      {renderedContent}
      {actionText && (
        <Text size="small" className={style.actionText} color="action" fitContent onClick={onActionClick}>
          {actionText}
        </Text>
      )}
    </>
  );
}

export function useNudgeContent({
  nudgeContent,
  defaultExpanded = false,
}: Pick<ICalloutWithNudgeProps, "nudgeContent" | "defaultExpanded">) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [nudgeState, setNudgeState] = useState<"open" | "closed" | "unset">(defaultExpanded ? "open" : "unset");

  useEffect(
    function calculateNudgeContentHeight() {
      if (contentRef.current) {
        const nudgeContentHeight = contentRef.current.getBoundingClientRect().height;
        document.documentElement.style.setProperty("--nudge-content-height", `${nudgeContentHeight}px`);
      }
    },
    [nudgeContent]
  );

  const renderedNudgeContent = (
    <div className={style.nudgeContentWrapper} data-state={nudgeState}>
      <div className={style.nudgeContent} ref={contentRef}>
        {typeof nudgeContent === "string" ? <Text>{nudgeContent}</Text> : nudgeContent}
      </div>
    </div>
  );

  return {
    contentRef,
    nudgeState,
    setNudgeState,
    renderedNudgeContent,
  };
}

export default CalloutBase;
