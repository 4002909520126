import { libraryComponentFolderFamilyAtom } from "@/stores/ComponentFolders";
import { navigateToLibraryFolderActionAtom } from "@/stores/Library";
import NavItem from "@ds/molecules/NavItem";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import { ILibraryComponentFolderItem } from "@shared/types/Library";
import { useAtomValue, useSetAtom } from "jotai";
import { memo, useCallback } from "react";
import style from "./style.module.css";

const LibraryComponentFolderNavItem = memo(function LibraryComponentFolderNavItem(props: {
  item: ILibraryComponentFolderItem;
}) {
  const folder = useAtomValue(libraryComponentFolderFamilyAtom(props.item._id));
  const navigateToFolder = useSetAtom(navigateToLibraryFolderActionAtom);

  const handleFolderClick = useCallback(
    function _handleFolderClick() {
      navigateToFolder(props.item._id);
    },
    [navigateToFolder, props.item._id]
  );

  return (
    <NavItem
      onClick={handleFolderClick}
      id={props.item._id}
      type="item"
      label={folder.name}
      icon={<FolderIcon className={style.folderIcon} />}
      dragAndDrop={{ isDragDisabled: true }}
    />
  );
});

export default LibraryComponentFolderNavItem;
