import { currentToastAtom, hideToastActionAtom } from "@/stores/Toast";
import Text from "@ds/atoms/Text";
import Toast from "@ds/organisms/Toast";
import { useAtomValue, useSetAtom } from "jotai";

// This ToastWrapper component is hooked up to Jotai.
// Call `showToastActionAtom` anywhere to trigger rendering this toast.

function ToastWrapper() {
  const toast = useAtomValue(currentToastAtom);
  const hideToast = useSetAtom(hideToastActionAtom);

  if (!toast) {
    return null;
  }

  const { showCloseButton, ...rest } = toast;
  return (
    <Toast onClickClose={showCloseButton ? hideToast : undefined} {...rest}>
      <Text color="invert">{toast.message}</Text>
    </Toast>
  );
}

export default ToastWrapper;
