import { client } from "@/http/lib/dittoClient";
import { calculateAutoSaveDiffFromState } from "@shared/frontend/lib/calculateAutoSaveDiffFromState";
import { getTagsChanges } from "@shared/frontend/lib/getTagChanges";
import { ZTextItemsUpdate } from "@shared/types/DittoProject";
import { IHiddenFields, ITextItem, ITextItemStatus, ZHiddenFields } from "@shared/types/TextItem";
import { atom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { uniq } from "lodash";
import { z } from "zod";
import { updateLibraryComponentActionAtom } from "./Components";
import { allTagsInProject as allTagsInProjectAtom, projectIdAtom, updateTextItemsActionAtom } from "./Project";

const LIBRARY_HIDDEN_FIELDS_ID = "LIBRARY_HIDDEN_FIELDS";

const initialHiddenFields: IHiddenFields = {
  status: {
    value: "status",
    label: "Status",
    isHidden: false,
  },
  assigned: {
    value: "assigned",
    label: "Assigned",
    isHidden: false,
  },
  tags: {
    value: "tags",
    label: "Tags",
    isHidden: false,
  },
  notes: {
    value: "notes",
    label: "Notes",
    isHidden: false,
  },
  variants: {
    value: "variants",
    label: "Variants",
    isHidden: false,
  },
  comments: {
    value: "comments",
    label: "Comments",
    isHidden: false,
  },
  instances: {
    value: "instances",
    label: "Instances",
    isHidden: false,
  },
  plurals: {
    value: "plurals",
    label: "Plurals",
    isHidden: false,
  },
  developerID: {
    value: "developerID",
    label: "Developer ID",
    isHidden: false,
  },
};

const STORAGE_KEY_PREFIX = "hidden-fields-map-";

// MARK: Storage Atom
const hiddenFieldsWithStorageAtomFamily = atomFamily((contextId: string) => {
  const storageAtom = atomWithStorage<IHiddenFields>(`${STORAGE_KEY_PREFIX}${contextId}`, initialHiddenFields, {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);

      try {
        return ZHiddenFields.parse(JSON.parse(storedValue ?? ""));
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
    // subscribe to window storage event to persist state across tab switching
    subscribe(key, callback, initialValue) {
      if (typeof window === "undefined" || typeof window.addEventListener === "undefined") {
        return () => {};
      }

      const storageListener = (e: StorageEvent) => {
        if (e.storageArea === localStorage && e.key === key) {
          let newValue;
          try {
            newValue = ZHiddenFields.parse(JSON.parse(e.newValue ?? ""));
          } catch {
            newValue = initialValue;
          }
          callback(newValue);
        }
      };
      window.addEventListener("storage", storageListener);
      return () => window.removeEventListener("storage", storageListener);
    },
  });

  return storageAtom;
});

// MARK: Derived Atoms
// derived object map of hidden fields from local storage atom
export const libraryHiddenFieldsAtom = atom((get) => get(hiddenFieldsWithStorageAtomFamily(LIBRARY_HIDDEN_FIELDS_ID)));
// derived list of hidden fields from local storage atom
export const libraryHiddenFieldsListAtom = atom((get) => Object.values(get(libraryHiddenFieldsAtom)));

// derived object map of hidden fields from local storage atom
export const hiddenFieldsAtom = atom((get) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return null;
  const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(projectId);
  const hiddenFields = get(hiddenFieldsWithStorageAtom);

  return hiddenFields;
});

// derived list of hidden fields from local storage atom
export const hiddenFieldsListAtom = atom((get) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return null;
  const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(projectId);
  const hiddenFields = get(hiddenFieldsWithStorageAtom);

  return Object.values(hiddenFields);
});

// MARK: Actions
export const updateHiddenFieldsActionAtom = atom(
  null,
  (get, set, id: string, updatedFields: { value: string; label: string; isHidden: boolean }[]) => {
    const updatedFieldsMap = {};
    const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(id);

    updatedFields.forEach((field) => {
      updatedFieldsMap[field.value] = field;
    });
    set(hiddenFieldsWithStorageAtom, updatedFieldsMap as IHiddenFields);
  }
);

export const resetHiddenFieldsActionAtom = atom(null, (get, set, id: string) => {
  const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(id);

  set(hiddenFieldsWithStorageAtom, initialHiddenFields);
});

// Rename the interface to match
interface AutoSaveParams {
  newValues: {
    tags?: string[] | { mixed: true };
    status?: ITextItemStatus | "MIXED";
    assignee?: { id: string; name: string } | null;
    notes?: string | null | { mixed: true };
    characterLimit?: number | null;
  };
  selectedTextItems: ITextItem[];
  allTagsInProject: string[];
  previousTags: string[] | { mixed: true };
  previousStatus: ITextItemStatus | "MIXED";
  previousAssignee: { id: string; name: string } | null;
  previousNotes: string | null | { mixed: true };
  previousCharacterLimit: number | null;
}

export const autoSaveActionAtom = atom(null, async (get, set, params: AutoSaveParams) => {
  const {
    newValues,
    selectedTextItems,
    allTagsInProject,
    previousTags,
    previousStatus,
    previousAssignee,
    previousNotes,
    previousCharacterLimit,
  } = params;
  const projectId = get(projectIdAtom);
  if (!projectId || !newValues) return;

  const textItemsChanges = selectedTextItems.map((textItem) => ({
    ...calculateAutoSaveDiffFromState(
      textItem,
      selectedTextItems,
      newValues.tags || previousTags,
      newValues.status || previousStatus,
      newValues.assignee !== undefined ? newValues.assignee : previousAssignee,
      newValues.notes !== null && newValues.notes !== undefined ? newValues.notes : previousNotes,
      newValues.characterLimit || previousCharacterLimit
    ),
    textItemId: textItem._id,
  }));

  // We want bulk updates to be one request, which means all selected text items
  // will recieve the same updates.
  const changedFields = textItemsChanges.reduce((acc, textItem) => {
    Object.entries(textItem).forEach(([key, value]) => {
      if (value === true) {
        acc[key] = true;
      }
    });
    return acc;
  }, {} as Record<"statusChanged" | "assigneeChanged" | "tagsChanged" | "notesChanged" | "characterLimitChanged", boolean>);

  const { tagsToWrite, tagsToDelete } = Array.isArray(newValues.tags)
    ? getTagsChanges(
        selectedTextItems.map((textItem) => textItem.tags),
        newValues.tags
      )
    : { tagsToWrite: [], tagsToDelete: [] };

  const result = await client.dittoProject.updateTextItems({
    projectId,
    updates: selectedTextItems.map((textItem) => {
      const update: z.infer<typeof ZTextItemsUpdate> = {
        textItemIds: [textItem._id],
        ...(changedFields.statusChanged ? { status: newValues.status as ITextItemStatus } : {}),
        ...(changedFields.assigneeChanged ? { assignee: newValues.assignee?.id! || null } : {}),
        ...(changedFields.tagsChanged && tagsToWrite.length ? { tags: tagsToWrite } : {}),
        ...(changedFields.tagsChanged && tagsToDelete.length ? { tagsToDelete } : {}),
        ...(changedFields.notesChanged && typeof newValues.notes === "string" ? { notes: newValues.notes } : {}),
        ...(newValues.characterLimit !== null && changedFields.characterLimitChanged
          ? { characterLimit: newValues.characterLimit as number }
          : {}),
      };
      return update;
    }),
  });

  const updatedTextItems: ITextItem[] = Object.values(result.updatedTextItems);
  const updatedLibraryComponents = Object.values(result.updatedLibraryComponents);

  // Update text items
  set(updateTextItemsActionAtom, updatedTextItems);

  // Update library components
  for (const updatedLibraryComponent of updatedLibraryComponents) {
    set(updateLibraryComponentActionAtom, {
      _id: updatedLibraryComponent._id,
      update: updatedLibraryComponent,
    });
  }

  // Update project tags if new ones are added
  if (changedFields.tagsChanged && tagsToWrite.length) {
    const newAllTagsInProject = uniq([...allTagsInProject, ...tagsToWrite]);
    if (JSON.stringify(allTagsInProject) !== JSON.stringify(newAllTagsInProject)) {
      set(allTagsInProjectAtom, newAllTagsInProject);
    }
  }
});
