import classNames from "classnames";
import React, { forwardRef, useMemo } from "react";
import BreadcrumbLinkDefault from "../../atoms/BreadcrumbLink";
import BreadcrumbSeparator from "../../atoms/BreadcrumbSeparator";
import BreadcrumbTerminus from "../../atoms/BreadcrumbTerminus";
import style from "./index.module.css";

interface IProps extends React.PropsWithChildren<{}> {
  className?: string;
  style?: React.CSSProperties;
  previousCrumbClassName?: string;
  previousCrumbs: { label: string; value: string }[];
  finalCrumbClassName?: string;
  finalCrumb: string | React.ReactNode;
  separator?: string; // Default is "/" but you can pass in any character (or maybe ReactNode in the future)
  BreadcrumbLink?: React.ComponentType<{ label: string; value: string; className?: string }>;
}

export const Breadcrumbs = forwardRef<HTMLDivElement, IProps>(function BreadCrumbs(props, ref) {
  // avoid trailing slashes by hiding the final separator if there's no final crumb
  const hideFinalSeparator = !props.finalCrumb;

  const BreadcrumbLink = props.BreadcrumbLink ?? BreadcrumbLinkDefault;

  // this is a sanity check to ensure that the breadcrumb values are unique;
  // if they are not, rendering will break due to duplicate keys in the JSX below
  // and cause issues that are hard to debug
  const previousBreadcrumbKeys = useMemo(() => {
    const keySet = new Set<string>();

    for (const crumb of props.previousCrumbs) {
      keySet.add(crumb.label + crumb.value);
    }

    if (keySet.size !== props.previousCrumbs.length) {
      throw new Error("Duplicate breadcrumb values detected");
    }

    return Array.from(keySet);
  }, [props.previousCrumbs]);

  return (
    <div
      style={props.style}
      className={classNames(style.BreadcrumbsWrapper, props.className)}
      data-testid="breadcrumbs"
      ref={ref}
    >
      {props.previousCrumbs.map((crumb, i, arr) => (
        <React.Fragment key={previousBreadcrumbKeys[i]}>
          <BreadcrumbLink {...crumb} className={props.previousCrumbClassName} />
          {!(i === arr.length - 1 && hideFinalSeparator) && (
            <BreadcrumbSeparator className={props.previousCrumbClassName} separator={props.separator} />
          )}
        </React.Fragment>
      ))}
      {typeof props.finalCrumb === "string" ? (
        <BreadcrumbTerminus className={props.finalCrumbClassName} text={props.finalCrumb} />
      ) : (
        <>{props.finalCrumb}</>
      )}
    </div>
  );
});

export default Breadcrumbs;
