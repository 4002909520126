import { selectedLibraryComponentActivityFamilyAtom } from "@/stores/Activity";
import { derivedOnlySelectedComponentAtom } from "@/stores/Library";
import TextItemActivityItem from "@/views/NS/ProjectNS/components/DetailsPanel/TextItemActivity/TextItemActivityItem";
import Scrollbar from "@ds/molecules/Scrollbar";
import ChangeItemList from "@ds/organisms/ChangeItemList";
import { useAtomValue } from "jotai";
import style from "./style.module.css";

function LibraryEditActivityPanel() {
  const onlySelectedComponent = useAtomValue(derivedOnlySelectedComponentAtom);
  const selectedComponentActivity = useAtomValue(
    selectedLibraryComponentActivityFamilyAtom(onlySelectedComponent?._id ?? null)
  );

  return (
    <Scrollbar className={style.scrollArea}>
      <ChangeItemList changeItems={selectedComponentActivity} ChangeItemComponent={TextItemActivityItem} />
    </Scrollbar>
  );
}

export default LibraryEditActivityPanel;
