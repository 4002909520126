import { ZCommentType } from "@shared/types/CommentThread";
import {
  ZDittoProject,
  ZDittoProjectBlock,
  ZTextItemConflictResolution,
  ZTextItemsUpdate,
} from "@shared/types/DittoProject";
import {
  ZCreateBlocksProps,
  ZCreateDittoProject,
  ZCreateTextItemsFromPluginProps,
  ZCreateTextItemsFromWebAppProps,
  ZDittoProjectData,
  ZMoveTextItemsProps,
} from "@shared/types/http/DittoProject";
import { ZObjectId } from "@shared/types/lib";
import { ZLibraryComponent } from "@shared/types/LibraryComponent";
import { ZFigmaV2Instance, ZTextItem, ZTextItemStatus } from "@shared/types/TextItem";
import { ZRole } from "@shared/types/User";
import { z } from "zod";

const DITTO_PROJECT_BASE_PATH = "/ditto-project";
const DITTO_PROJECT_PATH_WITH_PROJECT_ID = `${DITTO_PROJECT_BASE_PATH}/:projectId`;

// MARK: Types for routes that don't start with /:projectId

export const ZGetProjectSummariesInWorkspaceRequest = {
  query: z.object({
    projectIds: z.array(z.string()).optional(),
  }),
};
export const ZGetProjectSummariesInWorkspaceResponse = z.unknown();
export const GetProjectSummariesInWorkspaceMethod = "GET";
export const GetProjectSummariesInWorkspacePath = `${DITTO_PROJECT_BASE_PATH}/summaries`;

export const ZValidateProjectRequest = {
  params: z.object({
    projectId: z.string().optional(),
  }),
  query: z.object({
    fileId: z.string(),
    branchId: z.string().optional(),
    dittoComponentNodeId: z.string(),
    dittoComponentKey: z.string(),
  }),
};
export const ZValidateProjectResponse = z.unknown();
export const ValidateProjectMethod = "GET";
export const ValidateProjectPath = `${DITTO_PROJECT_BASE_PATH}/validateProject/:projectId?`;

export const ZValidateLegacyProjectRequest = {
  params: z.object({
    legacyDocId: z.string(),
  }),
  query: z.object({
    fileId: z.string(),
    branchId: z.string().optional(),
    dittoComponentNodeId: z.string(),
    dittoComponentKey: z.string(),
  }),
};
export const ZValidateLegacyProjectResponse = z.unknown();
export const ValidateLegacyProjectMethod = "GET";
export const ValidateLegacyProjectPath = `${DITTO_PROJECT_BASE_PATH}/validateLegacyProject/:legacyDocId`;

export const ZCreateDittoProjectRequest = {
  body: ZCreateDittoProject,
};
export const ZCreateDittoProjectResponse = z.unknown();
export const CreateDittoProjectMethod = "POST";
export const CreateDittoProjectPath = `${DITTO_PROJECT_BASE_PATH}/create`;

export const ZCreatePopulatedDittoProjectRequest = {
  body: z
    .object({
      name: z.string(),
      numberOfBlocks: z.number(),
      maxNumberOfTextItemsInBlocks: z.number(),
      textItemsOutsideOfBlocks: z.number(),
    })
    .partial(),
};
export const ZCreatePopulatedDittoProjectResponse = z.unknown();
export const CreatePopulatedDittoProjectMethod = "POST";
export const CreatePopulatedDittoProjectPath = `${DITTO_PROJECT_BASE_PATH}/createPopulatedDittoProject`;

// MARK: Project

export const ZGetProjectsRequest = {
  query: z.object({
    search: z.string().optional(),
    skip: z.string().optional(),
    limit: z.string().optional(),
    figma: z.string().optional(),
  }),
};
export const ZGetProjectsResponse = z.object({
  projects: z.array(ZDittoProject),
});
export const GetProjectsMethod = "GET";
export const GetProjectsPath = `${DITTO_PROJECT_BASE_PATH}`;

export const ZGetProjectDataRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    projectContentSearchQuery: z.string().optional(),
    statuses: z.array(ZTextItemStatus).optional(),
    tags: z.array(z.string()).optional(),
    assignees: z.array(z.string()).optional(),
    pages: z.array(z.string()).optional(),
  }),
};
export const ZGetProjectDataResponse = z.unknown();
export const GetProjectDataMethod = "GET";
export const GetProjectDataPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}`;

export const ZUpdateProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    projectData: ZDittoProject.partial(),
  }),
};
export const ZUpdateProjectResponse = z.unknown();
export const UpdateProjectMethod = "PATCH";
export const UpdateProjectPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}`;

export const ZDeleteProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZDeleteProjectResponse = z.unknown();
export const DeleteProjectMethod = "DELETE";
export const DeleteProjectPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}`;

// MARK: Blocks

export const ZGetBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    ids: z.array(z.string()).optional(),
  }),
};
export const ZGetBlocksResponse = z.array(ZDittoProjectBlock);
export const GetBlocksMethod = "GET";
export const GetBlocksPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/blocks`;

export const ZCreateBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: ZCreateBlocksProps,
};
export const ZCreateBlocksResponse = z.array(ZDittoProjectBlock);
export const CreateBlocksMethod = "POST";
export const CreateBlocksPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/blocks`;

export const ZUpdateBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    blocks: z.array(
      z.object({
        _id: z.string(),
        name: z.string().optional(),
        frameCount: z.number().optional(),
      })
    ),
  }),
};
export const ZUpdateBlocksResponse = z.array(ZDittoProjectBlock);
export const UpdateBlocksMethod = "PATCH";
export const UpdateBlocksPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/blocks`;

export const ZReorderBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    blockIds: z.array(z.string()),
    newIndex: z.number(),
  }),
};
export const ZReorderBlocksResponse = ZDittoProject;
export const ReorderBlocksMethod = "PATCH";
export const ReorderBlocksPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/blocks/reorder`;

export const ZDeleteBlocksRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    blockIds: z.array(z.string()),
  }),
};
export const ZDeleteBlocksResponse = ZDittoProject;
export const DeleteBlocksMethod = "DELETE";
export const DeleteBlocksPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/blocks`;

// MARK: Text Items

export const ZGetTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    ids: z.array(z.string()).optional(),
  }),
};
export const ZGetTextItemsResponse = z.unknown();
export const GetTextItemsMethod = "GET";
export const GetTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items`;

export const ZCreateTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.union([ZCreateTextItemsFromPluginProps, ZCreateTextItemsFromWebAppProps]),
};
export const ZCreateTextItemsResponse = z.unknown();
export const CreateTextItemsMethod = "POST";
export const CreateTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items`;

export const ZUpdateTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    updates: z.array(ZTextItemsUpdate),
    variantId: z.string().optional(),
    newVariant: z
      .object({
        name: z.string(),
        variantId: ZObjectId,
      })
      .optional(),
  }),
};
export const ZUpdateTextItemsResponse = z.object({
  updatedTextItems: z.record(ZTextItem),
  updatedLibraryComponents: z.record(ZLibraryComponent),
});
export const UpdateTextItemsMethod = "PATCH";
export const UpdateTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items`;

export const ZMoveTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: ZMoveTextItemsProps,
};
export const ZMoveTextItemsResponse = z.unknown();
export const MoveTextItemsMethod = "POST";
export const MoveTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/move-text-items`;

export const ZRemoveVariantFromTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    variantId: ZObjectId,
    textItemIds: z.array(ZObjectId),
  }),
};
export const ZRemoveVariantFromTextItemsResponse = z.unknown();
export const RemoveVariantFromTextItemsMethod = "PATCH";
export const RemoveVariantFromTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/remove-variant`;

export const ZDeleteTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    textItemIds: z.array(ZObjectId),
  }),
};
export const ZDeleteTextItemsResponse = z.array(ZTextItem);
export const DeleteTextItemsMethod = "POST";
export const DeleteTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/delete-text-items`;

export const ZConnectTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    figmaTextNodeInstancesByTextItemId: z.record(z.string(), z.array(ZFigmaV2Instance)),
    source: z.enum(["text-suggestion", "block-suggestion", "manual-linking"]).optional(),
    fromBlockSuggestion: z.boolean().optional(),
  }),
};
export const ZConnectTextItemsResponse = z.unknown();
export const ConnectTextItemsMethod = "PATCH";
export const ConnectTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/connect`;

export const ZDisconnectTextItemsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    figmaNodeIdsByTextItemId: z.record(z.string(), z.array(z.string())),
  }),
};
export const ZDisconnectTextItemsResponse = z.unknown();
export const DisconnectTextItemsMethod = "PATCH";
export const DisconnectTextItemsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/disconnect`;

export const ZSplitOffInstancesRequest = {
  params: z.object({
    projectId: z.string(),
    textItemId: z.string(),
  }),
  body: z.object({
    figmaNodeIds: z.string().array(),
  }),
};
export const ZSplitOffInstancesResponse = z.object({
  textItem: ZTextItem,
});
export const SplitOffInstancesMethod = "PATCH";
export const SplitOffInstancesPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/:textItemId/split-instances`;

/**
 * @deprecated DIT-9505 Remove
 */
export const ZUnlinkTextItemRequest = {
  params: z.object({
    projectId: z.string(),
    textItemId: z.string(),
  }),
  body: z.object({
    figmaNodeId: z.string(),
  }),
};
export const ZUnlinkTextItemResponse = z.object({
  textItem: ZTextItem,
});
export const UnlinkTextItemMethod = "PATCH";
export const UnlinkTextItemPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/:textItemId/unlink`;

export const ZResolveTextItemConflictRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    projectId: ZObjectId,
    update: ZTextItemConflictResolution,
  }),
};
export const ZResolveTextItemConflictResponse = z.unknown();
export const ResolveTextItemConflictMethod = "PATCH";
export const ResolveTextItemConflictPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/resolve-conflict`;

export const ZSearchDittoProjectTextItemsRequest = {
  query: z.object({
    query: z.string().optional(),
    // 👇 unused, but required for compatibility with the web app
    folderId: z.string().optional(),
    statuses: z.array(ZTextItemStatus).optional(),
    tags: z.array(z.string()).optional(),
  }),
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZSearchDittoProjectTextItemsResponse = z.object({
  project: ZDittoProjectData,
});
export const SearchDittoProjectTextItemsMethod = "GET";
export const SearchDittoProjectTextItemsPath = `${DITTO_PROJECT_BASE_PATH}/:projectId/search`;

// MARK: Figma and data mapping

export const ZConnectFigmaToProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    fileId: z.string(),
    branchId: z.string().optional(),
    dittoComponentNodeId: z.string(),
    dittoComponentKey: z.string(),
  }),
};
export const ZConnectFigmaToProjectResponse = z.unknown();
export const ConnectFigmaToProjectMethod = "PUT";
export const ConnectFigmaToProjectPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/connect`;

export const ZGetSyncedFigmaPagesRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetSyncedFigmaPagesResponse = z.unknown();
export const GetSyncedFigmaPagesMethod = "GET";
export const GetSyncedFigmaPagesPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/synced-figma-pages`;

export const ZGetTopLevelFramesMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetTopLevelFramesMapResponse = z.unknown();
export const GetTopLevelFramesMapMethod = "GET";
export const GetTopLevelFramesMapPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/top-level-frames`;

export const ZGetFramePreviewsMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetFramePreviewsMapResponse = z.unknown();
export const GetFramePreviewsMapMethod = "GET";
export const GetFramePreviewsMapPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/frame-previews`;

export const ZGetTextItemsMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetTextItemsMapResponse = z.unknown();
export const GetTextItemsMapMethod = "GET";
export const GetTextItemsMapPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items-map`;

export const ZGetSyncedTextNodesMapRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetSyncedTextNodesMapResponse = z.unknown();
export const GetSyncedTextNodesMapMethod = "GET";
export const GetSyncedTextNodesMapPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/synced-text-nodes-map`;

export const ZGetBlockNodesInFramesRequest = {
  params: z.object({
    projectId: z.string(),
    blockId: z.string(),
  }),
  query: z.object({
    topLevelFrameIds: z.string().array(),
  }),
};
export const ZGetBlockNodesInFramesResponse = z.object({
  nodeIds: z.string().array(),
});
export const GetBlockNodesInFramesMethod = "GET";
export const GetBlockNodesInFramesPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/blocks/:blockId/nodes-in-frames`;

// MARK: Miscellaneous

export const ZGetAllProjectTagsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetAllProjectTagsResponse = z.unknown();
export const GetAllProjectTagsMethod = "GET";
export const GetAllProjectTagsPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/tags`;

export const ZInviteUsersToProjectRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    invitations: z.array(
      z.object({
        type: z.enum(["new", "existing"]),
        role: ZRole,
        permissionGroups: z.array(z.string()),
        email: z.string().email(),
        name: z.string(),
        isAlreadyInvited: z.boolean(),
      })
    ),
    message: z.string(),
  }),
};
export const ZInviteUsersToProjectResponse = z.unknown();
export const InviteUsersToProjectMethod = "POST";
export const InviteUsersToProjectPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/invite`;

export const ZMoveProjectToFolderRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    folderId: ZObjectId.nullable(),
  }),
};
export const ZMoveProjectToFolderResponse = z.unknown();
export const MoveProjectToFolderMethod = "PUT";
export const MoveProjectToFolderPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/move-to-folder`;

export const ZGetMostRecentCommentThreadIdByTextItemIdRequest = {
  params: z.object({
    projectId: z.string(),
    textItemId: z.string(),
  }),
  query: z.object({
    type: ZCommentType.optional(),
  }),
};
export const ZGetMostRecentCommentThreadIdByTextItemIdResponse = z.object({ _id: ZObjectId }).nullable();
export const GetMostRecentCommentThreadIdByTextItemIdMethod = "GET";
export const GetMostRecentCommentThreadIdByTextItemIdPath = `${DITTO_PROJECT_PATH_WITH_PROJECT_ID}/text-items/:textItemId/comments/most-recent`;
