import classNames from "classnames";
import { Atom, useAtomValue } from "jotai";
import React, { useMemo } from "react";
import getBoldedMatchingText from "../../../helpers/getBoldedMatchingText";
import Avatar from "../../atoms/Avatar";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import style from "./index.module.css";

interface IOption {
  value: string;
  label: string;
  picture?: string | null;
}

export interface AssigneeFilterDropdownUser {
  _id: string;
  name: string;
  picture?: string | null;
}

interface IProps {
  selectedUsers: string[];
  setSelectedUsers: (value: string[]) => void;
  usersAtom: Atom<AssigneeFilterDropdownUser[] | Promise<AssigneeFilterDropdownUser[]>>;

  onRemoveFilter?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export function AssigneeFilterDropdown(props: IProps) {
  const users = useAtomValue(props.usersAtom);
  function onChange(selectedOptions: IOption[]) {
    props.setSelectedUsers(selectedOptions.map((option) => option.value));
  }

  const usersMap = useMemo(() => {
    return users.reduce((acc, user) => {
      acc[user._id] = user;
      return acc;
    }, {} as Record<string, AssigneeFilterDropdownUser>);
  }, [users]);

  const formattedSelectedOptions = props.selectedUsers
    .map((userId) =>
      userId === "no-assignee"
        ? {
            _id: "no-assignee",
            name: "No assignee",
            picture: null,
          }
        : usersMap[userId]
    )
    .map((user) => ({
      value: user._id,
      label: user.name,
      picture: user.picture,
    }));

  const formattedOptions = useMemo(
    () =>
      users
        .map((user) => ({
          value: user._id,
          label: user.name,
          picture: user.picture,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [users]
  );

  const options = [
    {
      value: "no-assignee",
      label: "No assignee",
      picture: null,
    },
    ...formattedOptions,
  ];

  return (
    <DropdownCombobox
      multiple
      className={classNames(style.assigneeFilterDropdown, props.className)}
      selectedOptions={formattedSelectedOptions}
      onChange={onChange}
      style={props.style}
      options={options}
      TriggerComponent={TriggerComponent}
      OptionComponent={OptionComponent}
      onRemoveFilter={props.onRemoveFilter}
      inputPlaceholder="Choose assignee..."
    />
  );
}

interface ITriggerProps {
  selectedOptions: IOption[];
}

function TriggerComponent(props: ITriggerProps) {
  return (
    <div className={style.trigger}>
      {props.selectedOptions.length === 0 && (
        <Text color="secondary" weight="light" size="micro">
          Choose assignee...
        </Text>
      )}

      {props.selectedOptions.length === 1 && (
        <div className={style.selected}>
          {props.selectedOptions[0].value === "no-assignee" && <div className={style.emptyCircle} />}
          {props.selectedOptions[0].value !== "no-assignee" && (
            <Avatar userName={props.selectedOptions[0].label} picture={props.selectedOptions[0].picture} size="xs" />
          )}
          <Text color="secondary" weight="light" size="micro">
            {props.selectedOptions[0].label}
          </Text>
        </div>
      )}

      {props.selectedOptions.length > 1 && (
        <div className={style.selected}>
          <div className={style.bubbles}>
            {props.selectedOptions.slice(0, 3).map((option, index) =>
              option.value === "no-assignee" ? (
                <div
                  className={classNames(style.emptyCircle, style.avatar, {
                    [style.overlap]: index < props.selectedOptions.length - 1,
                  })}
                  key={index}
                  style={{
                    zIndex: props.selectedOptions.length - index,
                    marginLeft: index === 0 ? 0 : -2,
                  }}
                />
              ) : (
                <Avatar
                  userName={option.label}
                  picture={option.picture}
                  size="xs"
                  className={classNames(style.avatar, {
                    [style.overlap]: index < props.selectedOptions.length - 1,
                  })}
                  key={index}
                  style={{
                    zIndex: props.selectedOptions.length - index,
                    marginLeft: index === 0 ? 0 : -2,
                  }}
                />
              )
            )}
          </div>
          <Text color="secondary" weight="light" size="micro">
            {props.selectedOptions.length} assignees
          </Text>
        </div>
      )}
    </div>
  );
}

interface IOptionProps {
  option: IOption;
  query: string;
  selected: boolean;
}

function OptionComponent(props: IOptionProps) {
  if (props.option.value === "no-assignee")
    return (
      <div className={style.assigneeOption}>
        <div className={style.emptyCircle} />
        <Text color="primary" size="small">
          No assignee
        </Text>
      </div>
    );

  return (
    <div className={style.assigneeOption}>
      <Avatar userName={props.option.label} picture={props.option.picture} size="xs" />
      <Text color="primary" size="small" className={style.label}>
        <span>{getBoldedMatchingText(props.option.label, props.query)}</span>
      </Text>
    </div>
  );
}

export default AssigneeFilterDropdown;
