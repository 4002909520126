import { routes } from "@/defs";
import { ALL_COMPONENTS } from "@ds/molecules/LibraryComponentFolderFilterDropdown";
import { CollapseState } from "@ds/organisms/FilterBar";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { getPriorSelections } from "@shared/frontend/stores/selection";
import { REFRESH } from "@shared/frontend/stores/symbols";
import { IObjectId } from "@shared/types/lib";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { ITextItemVariable, ITipTapRichText } from "@shared/types/TextItem";
import { isNonEmptyArray } from "@shared/utils/array";
import logger from "@shared/utils/logger";
import { Virtualizer } from "@tanstack/react-virtual";
import ObjectID from "bson-objectid";
import { atom, SetStateAction, Setter, WritableAtom } from "jotai";
import { derive, soon, soonAll } from "jotai-derive";
import { atomFamily } from "jotai/utils";
import { client } from "../http/lib/dittoClient";
import {
  libraryComponentFolderFamilyAtom,
  libraryComponentFoldersListAtom,
  refreshLibraryComponentFoldersListAtom,
} from "./ComponentFolders";
import { libraryComponentFamilyAtom, updateLibraryComponentActionAtom } from "./Components";
import { locationAtom } from "./Location";
import { showToastActionAtom, Toast } from "./Toast";

const DETAILS_PANEL_PARAM = "detailsPanel";

export const sidebarCollapseStateAtom = atom<CollapseState>("unset");

/**
 * Controls the folder id that is selected in the create component modal in the library.
 * Needs to be global so that it can be updated each time..
 * 1. the user navigates to a new folder
 * 2. the user selects a new folder in the dropdown
 */
export const libraryCreateComponentModalSelectedFolderId = atom<string | null>(null);

/**
 * Represents the id of the currently selected library folder.
 * This is used to load the library list in context of the selected folder.
 */
export const selectedLibraryFolderIdAtom = atom(
  (get) => {
    const location = get(locationAtom);

    const folderId = location.pathname?.match(/library\/([\w\d]+)/)?.[1] ?? null;
    if (!folderId) return null;

    return soon(get(libraryComponentFoldersListAtom), (libraryComponentFolders) => {
      if (!isNonEmptyArray(libraryComponentFolders)) return null;
      if (!libraryComponentFolders.some((folder) => folder._id === folderId)) return null;
      return folderId;
    });
  },
  async (get, set, folderId: string | undefined | null) => {
    const location = get(locationAtom);
    const folderIdCurrent = await get(selectedLibraryFolderIdAtom);

    // re-compute library selection state based on the new folder id;
    // de-select components which don't exist in the folder we're navigating to
    //
    // it's possible for `selectedLibraryFolderAtom` to be set when the app mounts,
    // so we need to re-compute the selection state based on the new folder id
    // rather than just always de-selecting all components each time this setter is called
    let librarySelection = await get(librarySelectedItemsAtom);
    if (librarySelection.type !== "none") {
      const folderItems = await get(libraryListAtomFamily(folderId ?? undefined));
      const folderComponentsSet = new Set(folderItems.filter((i) => i.type === "component").map((i) => i._id));
      const folderComponentIdsSelected = librarySelection.ids.filter((id) => folderComponentsSet.has(id));

      librarySelection = folderComponentIdsSelected.length
        ? {
            type: "component",
            ids: folderComponentIdsSelected,
          }
        : { type: "none" };
    }

    const updateFolderId = (folderId: string | null) => {
      // by including an update to the search params according to the newly (de)selected components,
      // we can avoid a small issue where the URL briefly flashes between multiple states
      let searchParams = atomWithURLStorage.getSearchParamsFromValueUpdate(
        SELECTED_LIBRARY_COMPONENT_IDS_KEY,
        location,
        librarySelection.type === "component" ? librarySelection.ids : null
      );

      // always reset the panel selection
      searchParams = atomWithURLStorage.getSearchParamsFromValueUpdate(DETAILS_PANEL_PARAM, location, null);

      // updating the location atom will cause `selectedLibraryFolderIdAtom` to properly re-compute
      set(
        locationAtom,
        {
          ...location,
          pathname: routes.nonNavRoutes.library.getPath(folderId),
          searchParams,
        },
        // if the folder id isn't changing, replace the history state rather than adding a new one
        // (avoids the browser back button needing to be clicked multiple times to navigate back)
        { replace: (folderId ?? null) === folderIdCurrent }
      );

      // update the library selection atom will cause selected components to properly re-compute
      // NOTE: this will internally update the `locationAtom` as well, which is why we're pre-computing
      // changes to the search params above to avoid multiple visible updates to the page location
      set(librarySelectedItemsAtom, librarySelection);

      // each time the folder id changes, we want to update the default value of the selected folder in the create component modal
      set(libraryCreateComponentModalSelectedFolderId, folderId ?? ALL_COMPONENTS.value);
    };

    if (!folderId) {
      updateFolderId(null);
      return;
    }

    return soon(get(libraryComponentFoldersListAtom), async (libraryComponentFolders) => {
      const folderExists = libraryComponentFolders.some((folder) => folder._id === folderId);
      updateFolderId(folderExists ? folderId : null);
    });
  }
);

const getFolderIdFromPathname = (pathname: string) => {
  return pathname.match(/\/library\/([\w\d]+)/)?.[1] ?? null;
};

// required to keep the selected folder id in sync with the url in the case
// where the back button is clicked rather than the `selectedLibraryFolderIdAtom` setter
// being called directly
selectedLibraryFolderIdAtom.onMount = function handleBrowserBackButton(set) {
  const listener = (_: PopStateEvent) => {
    const folderId = getFolderIdFromPathname(window.location.pathname);
    set(folderId);
  };
  window.addEventListener("popstate", listener);
  return () => window.removeEventListener("popstate", listener);
};

export const navigateToLibraryFolderActionAtom = atom(null, (get, set, folderId: string | null) => {
  set(selectedLibraryFolderIdAtom, folderId);
});

export type LibraryListItem =
  | {
      type: "component";
      _id: string;
      sortKey: string;
    }
  | {
      type: "componentFolder";
      _id: string;
    };

/**
 * Composed of the items to render in the library list. This is the structure of the items in the library.
 * NOTE: this is loaded in the context of the currently selected folder id.
 * NOTE 2: this family can be preloaded by calling `preloadLibraryListAtomFamilyActionAtom`
 */
export const libraryListAtomFamily = atomFamily((folderId?: IObjectId) => {
  const { valueAtom: familyAtom, refreshAtom } = asyncMutableDerivedAtom({
    loadData: async () => {
      try {
        const result = await client.library.getLibraryStructure({ folderId });
        return result;
      } catch (e) {
        logger.error("Error loading library list", { context: { folderId } }, e);
        // TODO: Render an error component here.
        return [];
      }
    },
  });

  familyAtom.debugLabel = `LibraryList Atom Family (${folderId})`;

  const refreshableFamilyAtom = atom(
    (get) => get(familyAtom),
    (_, set, arg: LibraryListItem[] | typeof REFRESH) => {
      if (arg === REFRESH) {
        set(refreshAtom);
        return;
      }

      set(familyAtom, arg);
    }
  );

  return refreshableFamilyAtom;
});

/**
 * Preloads the library list for all component folders loaded in the library. Safe to be called multiple times;
 * since it's calling `get` on atom families instead of making network requests directly, it should be pretty cheap.
 *
 * TODO: in the future when libraries start to get very large, consider optimizing this, since right now
 * it will fire one request per library component folder in the workspace.
 * Potential optimization paths:
 *   1. could reduce number of requests by updating `libraryListAtomFamily` to be a `batchedAsyncAtomFamily`
 *   2. could reduce number of requests by only preloading folders that are children of the currently selected folder
 *   3. could continue preloading folders, but not preload component information by removing references to `libraryComponentFamilyAtom`
 *   4. by removing this atom, you could axe the preload altogether; folder component information will
 *   be loaded on demand
 */
export const preloadLibraryListAtomFamilyActionAtom = atom(null, async (get) => {
  const libraryComponentFoldersList = await get(libraryComponentFoldersListAtom);

  const preloadForFolderId = async (folderId: string | undefined) => {
    // this preloads the library structure for the folder:
    // if you remove this, you'll get page-level loading UI each time you navigate to
    // a new folder for the first time since refreshing
    const items = await get(libraryListAtomFamily(folderId));
    // this preloads the data for each component in the folder
    // if you remove this, you'll get component-level loading UI each time you navigate to
    // a new folder for the first time since refreshing
    return items.map((item) => item.type === "component" && get(libraryComponentFamilyAtom(item._id)));
  };

  const preloadForFolderIds = async (folderIds: (string | undefined)[]) => {
    const items = await Promise.all(folderIds.map(preloadForFolderId));
    return items.flat();
  };

  preloadForFolderId(undefined);
  preloadForFolderIds(libraryComponentFoldersList.map((folder) => folder._id));
});

/**
 * Represents the virtualizer for the library list items. Provides a way to programmatically
 * interact with the virtualized list.
 */
export const libraryItemsVirtualizerAtom = atom<Record<string, Virtualizer<Element, Element>>>({});

/**
 * Represents the items to render in the library list. This is the structure of the items in the library.
 */
export const libraryItemsAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) =>
    soon(get(libraryListAtomFamily(folderId ?? undefined)), (libraryItems) => {
      return libraryItems.filter((item) => item.type === "component");
    })
  );
});

/**
 * The count of the items in the library list.
 */
export const libraryItemsCountAtom = derive([libraryItemsAtom], (libraryItems) => libraryItems.length);
libraryItemsCountAtom.debugLabel = "LibraryItemsCountAtom";

/**
 * Represents the virtualizer for the library nav items. Provides a way to programmatically
 * interact with the virtualized list.
 */
export const libraryNavItemsVirtualizerAtom = atom<Record<string, Virtualizer<Element, Element>>>({});

/**
 * Represents the items to render in the library nav. This is the structure of the items in the library including components and folders.
 */
export const libraryNavItemsAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) => {
    return soon(get(libraryListAtomFamily(folderId ?? undefined)), (libraryItems) => {
      return libraryItems;
    });
  });
});

/**
 * Represents the name of the currently selected library folder.
 */
export const libraryCurrentFolderNameAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) => {
    if (!folderId) return null;
    return soon(get(libraryComponentFolderFamilyAtom(folderId)), (folder) => folder.name);
  });
});

/**
 * Represents the parent id of the currently selected library folder.
 */
export const libraryCurrentFolderParentIdAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) => {
    if (!folderId) return null;
    return soon(get(libraryComponentFolderFamilyAtom(folderId)), (folder) => folder.parentId);
  });
});

/**
 * Controls whether the library create component modal is currently showing
 */
export const libraryCreateComponentModalIsOpenAtom = atom(false);

/**
 * Controls whether the library create folder modal is currently showing
 */
export const libraryCreateFolderModalIsOpenAtom = atom(false);

// MARK: - Selection

export type LibrarySelection =
  | {
      type: "none";
    }
  | {
      type: "component";
      ids: string[];
    };

export const componentIsSelectedAtomFamily = atomFamily((id: string) =>
  atom((get) => soon(get(selectedComponentIdsAtom), (data) => data.includes(id)))
);

const MAX_ITERATIONS = 100;
const DEFAULT_ITERATIONS_DELAY = 200;
function recursivelyScrollToItemInVirtualizer(set: Setter, id: string, loop: number = 0) {
  let result = set(scrollToComponentIdActionAtom, id);
  if (result[0] === false || result[1] === false) {
    if (loop < MAX_ITERATIONS)
      setTimeout(() => recursivelyScrollToItemInVirtualizer(set, id, ++loop), DEFAULT_ITERATIONS_DELAY);
    else
      logger.error(
        "Failed to scroll to item in virtualizer",
        { context: { id } },
        new Error("Failed to load virtual list!")
      );
  } else {
    logger.debug(`Scrolled to item in virtualizer after ${loop} iterations`, { context: { id, loop } });
  }
}

const SELECTED_LIBRARY_COMPONENT_IDS_KEY = "selectedLibraryComponentIds";

/**
 * Special atom type for use only in this file.
 * Stores the current selection state for the project.
 * These selection states are mutually exclusive -- we can have text items selected, or blocks selected, but not both.
 * Any other kind of selection logic should be enforced here as well.
 */
function librarySelectionAtom(): WritableAtom<
  LibrarySelection | Promise<LibrarySelection>,
  [SetStateAction<LibrarySelection>],
  void
> {
  const componentIdsAtom = atomWithURLStorage(SELECTED_LIBRARY_COMPONENT_IDS_KEY, locationAtom, {
    onMount: (get, set, componentIdString) => {
      if (!componentIdString) return;
      const componentIds = componentIdString.split(",");
      if (componentIds.length > 0) {
        recursivelyScrollToItemInVirtualizer(set, componentIds[0]);
      }
    },
    replace: true,
  });
  componentIdsAtom.debugLabel = "componentIdsAtom (librarySelectionAtom)";
  // atomWithURLStorage will return an array of strings if the URL param is present, and null if it's not
  // we should probably allow it to just store a string, but for now the code in this atom takes
  // care of string <> array conversion

  const selectionAtom = atom(
    (get) => {
      return soon(get(libraryNavItemsAtom), (libraryNavItems) => {
        const componentIds = get(componentIdsAtom);
        /**
         * Check that the items actually exist in the current folder
         */
        const filteredComponentIds = componentIds?.filter((id) => libraryNavItems.some((item) => item._id === id));

        // It's possible for the URL to have an empty value for the selection URL keys. This means the atom returns a value
        // of an empty array. As of the time of this comment, we treat this case as if the selection is empty. If this ever
        // changes, we should make sure that every consumer of `selectedLibraryComponentIdsAtom` knows they might
        // receive an empty array.
        if (filteredComponentIds && filteredComponentIds.length > 0) {
          return { type: "component", ids: filteredComponentIds } as LibrarySelection;
        } else {
          return { type: "none" } as LibrarySelection;
        }
      });
    },
    async (get, set, newSelection: LibrarySelection) => {
      if (newSelection.type === "none") {
        set(componentIdsAtom, null);
        return;
      }

      if (newSelection.type === "component") {
        set(componentIdsAtom, newSelection.ids);
        set(scrollToComponentIdActionAtom, newSelection.ids[0]);
        return;
      }

      throw new Error("Invalid selection type");

      // // Either immediately change selection or show a discard changes confirmation modal
      // set(discardChangesModalActionAtom, {
      //   onConfirm: _handleSelectionChange,
      //   ignoreInlineChanges: false,
      //   activeElement: document.activeElement,
      // });
    }
  );

  return selectionAtom;
}

/**
 * Scrolls to the component with the given id in the virtualized list.
 */
const scrollToComponentIdActionAtom = atom(null, (get, set, componentId: string) => {
  const contentVirtualizers = get(libraryItemsVirtualizerAtom);
  const navListVirtualizers = get(libraryNavItemsVirtualizerAtom);

  let mainNavResult = scrollToItemInVirtualizer({
    itemId: componentId,
    virtualizers: contentVirtualizers,
  });

  let leftNavResult = scrollToItemInVirtualizer({
    itemId: componentId,
    virtualizers: navListVirtualizers,
  });

  return [mainNavResult, leftNavResult];
});

/**
 * Scrolls to the item with the given id in the virtualizer.
 */
function scrollToItemInVirtualizer(props: {
  itemId: string;
  scrollOptions?: ScrollToOptions;
  virtualizers: Record<string, Virtualizer<Element, Element>>;
}) {
  if (Object.values(props.virtualizers).length === 0) {
    return false;
  }
  for (const virtualizer of Object.values(props.virtualizers)) {
    let item = virtualizer.measurementsCache.find((item) => item.key === props.itemId);
    if (item) {
      virtualizer.scrollToIndex(item.index, { align: "center" });
      return true;
    }
  }
  logger.warn("Could not find item to scroll to", { context: { itemId: props.itemId } });
}

export const libraryHasChangesAtom = atom(false);

export const librarySelectedItemsAtom = librarySelectionAtom();

export const selectedComponentIdsAtom = atom((get) => {
  return soon(get(librarySelectedItemsAtom), (selection) => {
    if (selection.type === "component") {
      return selection.ids;
    } else {
      return [];
    }
  });
});

const _selectedComponentsAtom = atom((get) => {
  return soon(get(selectedComponentIdsAtom), (selectedComponentIds) => {
    const valuesArray = selectedComponentIds.map((id) => get(libraryComponentFamilyAtom(id)));
    if (!isNonEmptyArray(valuesArray)) return [] as ILibraryComponent[];
    const components = soonAll(valuesArray);
    return components;
  });
});

export const derivedSelectedComponentsAtom = derive([_selectedComponentsAtom], (items) => items);
derivedSelectedComponentsAtom.debugLabel = "derivedSelectedComponentsAtom";

/**
 * If exactly one component is selected, returns the full data for that component.
 * Otherwise, returns null.
 */
export const derivedOnlySelectedComponentAtom = derive([_selectedComponentsAtom], (selectedItems) => {
  if (selectedItems.length !== 1) return null;
  return selectedItems[0];
});
derivedOnlySelectedComponentAtom.debugLabel = "derivedOnlySelectedComponentAtom";

export const handleComponentClickActionAtom = atom(
  null,
  async (
    get,
    set,
    props: {
      event: React.MouseEvent<HTMLElement, MouseEvent>;
      componentId: IObjectId;
      skipInlineEditing: boolean;
    }
  ) => {
    const isSelected = get(componentIsSelectedAtomFamily(props.componentId));
    // TODO: handle inline editing
    const isInlineEditing = false;
    const selectedComponentIds = await get(selectedComponentIdsAtom);
    if (props.event && "shiftKey" in props.event && props.event.shiftKey) {
      // We need the ordered list of all text items to determine the range of elements to select.
      const components = await get(libraryItemsAtom);
      // If we press SHIFT+click, select the range of elements between the most recently selected element and the current one.
      const mostRecentlySelectedIndex = components.findIndex(
        (item) => item._id === selectedComponentIds[selectedComponentIds.length - 1]
      );
      // If the last selected element is not found (should never happen), select the current element.
      if (mostRecentlySelectedIndex === -1) {
        // TODO: handle inline editing
        set(librarySelectedItemsAtom, { type: "component", ids: [props.componentId] });
        return;
      }
      const currentIndex = components.findIndex((item) => item._id === props.componentId);
      const startIndex = Math.min(mostRecentlySelectedIndex, currentIndex);
      const endIndex = Math.max(mostRecentlySelectedIndex, currentIndex);
      const alreadyPresentSelections = getPriorSelections(
        selectedComponentIds,
        components,
        currentIndex,
        mostRecentlySelectedIndex,
        startIndex,
        endIndex
      );
      // Create the new selections array and ensure the most recently selected element is preserved at the end.
      const newSelections = [
        ...alreadyPresentSelections,
        ...components.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
        ...components
          .slice(mostRecentlySelectedIndex, endIndex + 1)
          .map((item) => item._id)
          .reverse(),
      ];
      // TODO: handle inline editing
      set(librarySelectedItemsAtom, { type: "component", ids: newSelections });
    } else if (props.event && "metaKey" in props.event && props.event.metaKey) {
      // If we press CMD+click, toggle selection of the element, without affecting other selections.
      // TODO: Handle inline editing. See the project for an example of this.
      if (isSelected) {
        set(librarySelectedItemsAtom, {
          type: "component",
          ids: selectedComponentIds.filter((id) => id !== props.componentId),
        });
      } else {
        // Place the text item twice in the selections array to properly handle both pivot and pointer state (relevant for arrow-shift selection)
        set(librarySelectedItemsAtom, {
          type: "component",
          ids: [props.componentId, ...selectedComponentIds, props.componentId],
        });
      }
    } else {
      // If we plain-select a selected element, turn on inline editing and remove other selections.
      if (isSelected) {
        if (selectedComponentIds.length > 1) {
          // other things were selected, so we should just select this one
          set(librarySelectedItemsAtom, { type: "component", ids: [props.componentId] });
        } else {
          // nothing else was selected, so we should just inline edit unless we are skipping inline editing
          if (props.skipInlineEditing) {
            set(librarySelectedItemsAtom, { type: "none" });
          } else {
            // TODO; set inline editing
          }
        }
      } else if (!isSelected) {
        if (isInlineEditing) {
          // TODO: Handle inline editing. See the project for an example of this.
        } else {
          set(librarySelectedItemsAtom, { type: "component", ids: [props.componentId] });
        }
        return;
      }
    }
  }
);
/**
 * Creates a new library component from the library page.
 */
export const createLibraryComponentActionAtom = atom(
  null,
  async (
    get,
    set,
    props: {
      folderId: IObjectId | null;
      name: string;
      richText: ITipTapRichText;
      text: string;
      workspaceId: IObjectId;
      variables: ITextItemVariable[];
    }
  ) => {
    const optimisticComponent: ILibraryComponent = {
      _id: ObjectID().toHexString(),
      name: props.name,
      rich_text: props.richText,
      text: props.text,
      folderId: props.folderId || null,
      status: "NONE",
      instances: [],
      commentThreads: [],
      variables: props.variables,
      variants: [],
      plurals: [],
      tags: [],
      assignee: null,
      notes: null,
      apiId: crypto.randomUUID(),
      sortKey: "ZZZZZZZZZZZZZZZZZZZZZZZZ",
      workspaceId: props.workspaceId,
    };

    // Optimistically update the component family atom

    libraryComponentFamilyAtom(optimisticComponent._id, optimisticComponent);
    const libraryStructureNode = libraryListAtomFamily(optimisticComponent.folderId || undefined);
    set(libraryStructureNode, [
      ...(await get(libraryStructureNode)),
      { type: "component", _id: optimisticComponent._id, sortKey: optimisticComponent.sortKey },
    ]);

    try {
      const result = await client.libraryComponent.createLibraryComponent({
        _id: optimisticComponent._id,
        name: optimisticComponent.name,
        richText: optimisticComponent.rich_text,
        folderId: optimisticComponent.folderId || null,
        variables: optimisticComponent.variables,
      });
      set(updateLibraryComponentActionAtom, { _id: optimisticComponent._id, update: result.newComponent });

      // Show created component toast message
      let toast: Toast = {
        message: "",
      };
      let folderName = "All components";

      // Select the newly created component if it's in the current folder
      if (optimisticComponent.folderId === (get(selectedLibraryFolderIdAtom) || null)) {
        set(librarySelectedItemsAtom, { type: "component", ids: [result.newComponent._id] });
      } else {
        toast.action = "View";
        toast.onClickAction = () => {
          if (result.newComponent.folderId) {
            set(selectedLibraryFolderIdAtom, result.newComponent.folderId);
            set(locationAtom, (previous) => ({
              ...previous,
              pathname: routes.nonNavRoutes.library.getPath(result.newComponent.folderId),
            }));
          } else {
            set(selectedLibraryFolderIdAtom, undefined);
            set(locationAtom, (previous) => ({
              ...previous,
              pathname: routes.nonNavRoutes.library.getPath(null),
            }));
          }
          set(librarySelectedItemsAtom, { type: "component", ids: [result.newComponent._id] });
        };
      }

      if (result.newComponent.folderId) {
        folderName = (await get(libraryComponentFolderFamilyAtom(result.newComponent.folderId))).name;
      }

      toast.message = `Created component "${result.newComponent.name}" in "${folderName}"`;
      set(showToastActionAtom, toast);
    } catch (error) {
      logger.error("Failed to create library component", { context: { optimisticComponent } }, error);
      libraryComponentFamilyAtom.remove(optimisticComponent._id);
      set(
        libraryStructureNode,
        (await get(libraryStructureNode)).filter((item) => item._id !== optimisticComponent._id)
      );
      set(showToastActionAtom, {
        message: `Something went wrong while creating the component. Please try again later.`,
      });
    }
  }
);

/**
 * Creates a new library component folder from the library page.
 */
export const createLibraryComponentFolderActionAtom = atom(
  null,
  async (get, set, props: { parentId: IObjectId | null; name: string; workspaceId: IObjectId }) => {
    const optimisticComponentFolder: ILibraryComponentFolder = {
      _id: ObjectID().toHexString(),
      name: props.name,
      apiId: crypto.randomUUID(),
      parentId: props.parentId || null,
      workspaceId: props.workspaceId,
    };

    // Optimistically update the component folder family atom
    libraryComponentFolderFamilyAtom(optimisticComponentFolder._id, optimisticComponentFolder);
    const libraryStructureNode = libraryListAtomFamily(optimisticComponentFolder.parentId || undefined);
    let updatedStructure = [...(await get(libraryStructureNode))];
    const indexOfFirstTextItem = updatedStructure.findIndex((item) => item.type === "component");
    if (indexOfFirstTextItem === -1) {
      updatedStructure.push({ type: "componentFolder", _id: optimisticComponentFolder._id });
    } else {
      updatedStructure.splice(indexOfFirstTextItem, 0, { type: "componentFolder", _id: optimisticComponentFolder._id });
    }
    set(libraryStructureNode, updatedStructure);

    try {
      const result = await client.libraryComponentFolder.createLibraryComponentFolder(optimisticComponentFolder);
      set(libraryComponentFolderFamilyAtom(optimisticComponentFolder._id), result.newComponentFolder);
      set(showToastActionAtom, {
        message: `Created folder "${result.newComponentFolder.name}"`,
        action: "View",
        onClickAction: () => {
          set(selectedLibraryFolderIdAtom, result.newComponentFolder._id);
        },
      });
      set(refreshLibraryComponentFoldersListAtom);
    } catch (error) {
      logger.error("Failed to create library component", { context: { optimisticComponentFolder } }, error);
      libraryComponentFolderFamilyAtom.remove(optimisticComponentFolder._id);
      set(
        libraryStructureNode,
        (await get(libraryStructureNode)).filter((item) => item._id !== optimisticComponentFolder._id)
      );
      set(showToastActionAtom, {
        message: `Something went wrong while creating the component folder. Please try again later.`,
      });
    }
  }
);

// MARK: - Details Panel

const LibraryDetailsPanelContexts = ["GENERAL", "EDIT"] as const;
export type LibraryDetailsPanelContext = (typeof LibraryDetailsPanelContexts)[number];

export const libraryDetailsPanelContextAtom = atom((get) => {
  return soon(get(selectedComponentIdsAtom), (selectedComponentIds) => {
    if (selectedComponentIds.length > 0) {
      return "EDIT";
    }

    return "GENERAL";
  });
});

const LibraryDetailsGeneralPanelTabs = ["ACTIVITY", "COMMENTS"] as const;
export type LibraryDetailsGeneralPanelTab = (typeof LibraryDetailsGeneralPanelTabs)[number];

export const libraryDetailsGeneralPanelSelectedTabAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as LibraryDetailsGeneralPanelTab;

    if (state === null || !LibraryDetailsGeneralPanelTabs.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: LibraryDetailsGeneralPanelTab) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }

      set(locationAtom, {
        ...location,
        searchParams: newSearchParams,
      });
    }

    handleChangePanel();
    // TODO; HAndle discard changes modal

    // set(discardChangesModalActionAtom, {
    //   onConfirm: handleChangePanel,
    //   ignoreInlineChanges: true,
    //   activeElement: document.activeElement,
    // });
  }
);

const LibraryDetailsEditPanelTabs = ["EDIT", "ACTIVITY", "COMMENTS", "VARIANTS"] as const;
export type LibraryDetailsEditPanelTab = (typeof LibraryDetailsEditPanelTabs)[number];

export const LibraryDetailsEditPanelTabAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "EDIT";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as LibraryDetailsEditPanelTab;

    if (state === null || !LibraryDetailsEditPanelTabs.includes(state)) {
      return "EDIT";
    }

    return state;
  },
  (get, set, newPanel: LibraryDetailsEditPanelTab) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }

      set(
        locationAtom,
        {
          ...location,
          searchParams: newSearchParams,
        },
        { replace: true }
      );
    }

    handleChangePanel();
    // TODO; HAndle discard changes modal

    // set(discardChangesModalActionAtom, {
    //   onConfirm: handleChangePanel,
    //   ignoreInlineChanges: true,
    //   activeElement: document.activeElement,
    // });
  }
);
