import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  name: string;
}

export function ComponentFolderNavButton(props: IProps) {
  return (
    <button
      className={classNames(style.componentFolderNavButtonWrapper, props.className)}
      data-testid="component-folder-nav-button"
      onClick={props.onClick}
      style={props.style}
    >
      <Icon color="secondary" Icon={<FolderIcon />} size="xs" />
      <Text truncate className={style.folderName} size="small">
        {props.name}
      </Text>
      <Icon color="secondary" Icon={<ChevronRightIcon />} size="xs" />
    </button>
  );
}

export default ComponentFolderNavButton;
