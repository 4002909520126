import { isDiffRichText } from "@shared/lib/text";
import { ITextItem, ITipTapRichText } from "@shared/types/TextItem";
import { atom, Atom, PrimitiveAtom, useAtomValue } from "jotai";
import { unwrap } from "jotai/utils";
import React, { useCallback, useRef } from "react";
import { COMPONENT_JSON_MIME_TYPE, TEXT_ITEM_JSON_MIME_TYPE } from "../../../../shared/common/constants";
import batchedAsyncAtomFamily from "../../../../shared/frontend/stores/batchedAsyncAtomFamily";
import { ILibraryComponent } from "../../../../shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "../../../../shared/types/LibraryComponentFolder";
import { IUser } from "../../../../shared/types/User";
import Text from "../../atoms/Text";
import ButtonWrapFooter from "../ButtonWrapFooter";
import FolderBreadcrumbsLabel from "../FolderBreadcrumbsLabel";
import { TextItemStatic } from "../TextItem";
import style from "./index.module.css";

type FamilyAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent | ITextItem>>["familyAtom"];

interface IProps {
  dragAndDropEnabled?: boolean;
  selectedComponentId: string | null;
  componentId: string;
  listItemFamilyAtom: (id: string | null) => ReturnType<FamilyAtomType> | PrimitiveAtom<null>;
  libraryComponentFoldersAtom?: Atom<ILibraryComponentFolder[] | Promise<ILibraryComponentFolder[]>>;
  libraryComponentFamilyAtom: (
    id: string | null
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"]>;
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  /**
   * The text to display on the action button.
   */
  actionText: string;
  /**
   * Callback for when the action button is clicked.
   * @param componentId - ID of the component that will be acted upon
   * @returns void
   */
  onComponentActionClick: (componentId: string) => void;
  /**
   * Callback for when the component is clicked.
   * @param componentId - ID of the component that was clicked
   * @returns void
   */
  onComponentSelect: (componentId: string) => void;
  /**
   * Callback for when the component is deselected (e.g. when the user clicks the cancel button)/
   * @returns void
   */
  onComponentDeselect: () => void;
  /**
   * The rich text of the text item that would be changed if the action is taken.
   * Used currently during linking flows.
   */
  originalRichText?: ITipTapRichText;
  /**
   * Whether to show the folder label.
   */
  showFolderLabel?: boolean;
  /**
   * Custom styles for the component.
   */
  style?: React.CSSProperties;
  /**
   * Custom class name for the component.
   */
  className?: string;
}

function useComponentData(props: IProps) {
  const { current: componentNameAtom } = useRef(
    unwrap(
      atom(async (get) => {
        const itemAtom = props.listItemFamilyAtom(props.componentId);
        if (!itemAtom) return null;

        const item = await get(itemAtom);
        if (!item) return null;

        if ("ws_comp" in item && item.ws_comp) {
          const component = await get(props.libraryComponentFamilyAtom(item.ws_comp));
          if (component) return component.name;
          else return null;
        }

        if ("instances" in item) {
          return item.name;
        }

        return null;
      }),
      (prev) => prev ?? null
    )
  );
  const componentName = useAtomValue(componentNameAtom);

  return componentName
    ? {
        name: componentName,
        // we don't care about actual instances for now since we display text item instances when linking
        instances: [],
      }
    : null;
}

export function ActionableComponentItem(props: IProps) {
  const usersByUserId = useAtomValue(props.usersByIdAtom);

  const {
    componentId: itemId,
    onComponentActionClick,
    onComponentSelect,
    onComponentDeselect,
    dragAndDropEnabled,
  } = props;

  const componentOrTextItem = useAtomValue(props.listItemFamilyAtom(props.componentId));
  const componentData = useComponentData(props);
  const theme = componentData ? "purple" : "blue";

  const handleComponentActionClick = useCallback(() => {
    onComponentActionClick(itemId);
  }, [itemId, onComponentActionClick]);

  const handleComponentSelect = useCallback(() => {
    onComponentSelect(itemId);
  }, [itemId, onComponentSelect]);

  const handleDragStart = useCallback(
    function handleDragStart(event: React.DragEvent<HTMLDivElement>) {
      if (!componentOrTextItem) return;
      if (!dragAndDropEnabled) return;
      if ("ws_comp" in componentOrTextItem) {
        event.dataTransfer.setData(TEXT_ITEM_JSON_MIME_TYPE, JSON.stringify(componentOrTextItem));
      } else {
        event.dataTransfer.setData(COMPONENT_JSON_MIME_TYPE, JSON.stringify(componentOrTextItem));
      }
    },
    [componentOrTextItem, dragAndDropEnabled]
  );

  if (!componentOrTextItem) return null;

  const assignee = componentOrTextItem.assignee ? usersByUserId[componentOrTextItem.assignee] : null;
  const isSelected = props.selectedComponentId === componentOrTextItem._id;

  const helperText =
    props.originalRichText !== undefined && isDiffRichText(props.originalRichText, componentOrTextItem.rich_text)
      ? "Text will be updated to match"
      : "";

  return (
    <div
      key={componentOrTextItem._id}
      className={style.linkableComponentItem}
      draggable={!!dragAndDropEnabled}
      onDragStart={handleDragStart}
    >
      {props.showFolderLabel && props.libraryComponentFoldersAtom && "folderId" in componentOrTextItem && (
        <FolderLabel
          folderId={componentOrTextItem.folderId}
          libraryComponentFoldersAtom={props.libraryComponentFoldersAtom}
        />
      )}
      <ButtonWrapFooter
        key={componentOrTextItem._id}
        visible={isSelected}
        theme={theme}
        renderSeparator
        primaryText={props.actionText}
        onPrimary={handleComponentActionClick}
        secondaryText="Cancel"
        onSecondary={onComponentDeselect}
        helperText={helperText}
      >
        <TextItemStatic
          borderColor={isSelected ? "transparent" : undefined}
          level="compact"
          state={"default"}
          component={componentData ?? undefined}
          showComponentInstances
          defaultText={componentOrTextItem.text}
          defaultValue={componentOrTextItem.rich_text}
          onClick={handleComponentSelect}
          status={componentOrTextItem.status}
          notes={componentOrTextItem.notes}
          tags={componentOrTextItem.tags}
          assignee={assignee ?? undefined}
          instanceCount={
            "instances" in componentOrTextItem
              ? componentOrTextItem.instances.length
              : componentOrTextItem.integrations.figmaV2?.instances?.length ?? 0
          }
          hideVariantsBadge={true}
        />
      </ButtonWrapFooter>
    </div>
  );
}

function FolderLabel(props: {
  folderId: string | null;
  libraryComponentFoldersAtom: Atom<ILibraryComponentFolder[] | Promise<ILibraryComponentFolder[]>>;
}) {
  if (!props.folderId) {
    return (
      <Text size="micro" color="secondary">
        All components
      </Text>
    );
  }

  return (
    <FolderBreadcrumbsLabel
      selectedLibraryFolderId={props.folderId}
      libraryComponentFoldersAtom={props.libraryComponentFoldersAtom}
      maxCrumbs={2}
    />
  );
}

export default ActionableComponentItem;
