import { client } from "@/http/lib/dittoClient";
import { initializeProjectIdActionAtom, projectIdAtom, projectStoreAtom } from "@/stores/Project";
import { HIDE_JOTAI_DEVTOOLS } from "@/utils/featureFlags";
import logger from "@shared/utils/logger";
import { Provider, useAtom, useAtomValue, useSetAtom } from "jotai";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";
import { Suspense, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import FilterBar from "./components/FilterBar";
import DialogueModalContainer from "./components/Modals/DialogueModalContainer";
import NavBar from "./components/NavBar";
import ProjectContent from "./components/ProjectContent";
import ToastWrapper from "./components/ToastWrapper";
import { WebsocketsHandler } from "./components/WebsocketsHandler";
import style from "./index.module.css";

function ProjectPageWrapper() {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const projectId = useAtomValue(projectIdAtom);
  const projectStore = useAtomValue(projectStoreAtom);
  const initializeProjectIdAction = useSetAtom(initializeProjectIdActionAtom);

  // This function is used to initialize the projectIdAtom.
  //   1. Check if there's a valid Northstar project at this ID
  //   2. If not, check if there's a legacy ActualDocument at this ID, and redirect to the new URL if so.
  //   3. Otherwise, just redirect to /projects.
  const initProjectIdAtom = useCallback(async () => {
    const projectId = params.id;
    if (!projectId) {
      throw new Error("Project ID in URL is required");
    }

    let dittoProject;
    try {
      dittoProject = await client.dittoProject.getProjectData({ projectId });
    } catch (error) {
      logger.error("Unable to find DittoProject record for projectId", { context: { projectId } }, error);
    }

    if (dittoProject) {
      initializeProjectIdAction(projectId);
      return;
    }

    // If there's no DittoProject record, check if there's a legacy ActualDocument record.
    let legacyProject;
    try {
      legacyProject = await client.doc.getDocName({ doc_id: projectId });
    } catch (error) {
      logger.error("Unable to find legacy ActualDocument record for projectId", { context: { projectId } }, error);
    }

    if (legacyProject) {
      history.push(`/projects/${projectId}`);
      return;
    }

    // If there's no DittoProject or legacy ActualDocument record, redirect to /projects.
    history.push("/projects");
  }, [initializeProjectIdAction, params.id, history]);

  useEffect(
    function initProjectIdAtomOnMount() {
      initProjectIdAtom();
    },
    [initProjectIdAtom]
  );

  if (!projectId) {
    return <></>;
  }

  return (
    <Provider store={projectStore} key={projectId}>
      {process.env.ENV === "development" && !HIDE_JOTAI_DEVTOOLS && (
        <DevTools
          store={projectStore}
          options={{ shouldShowPrivateAtoms: false, shouldExpandJsonTreeViewInitially: true }}
          position="bottom-right"
        />
      )}
      <ProjectPage projectId={projectId} />
    </Provider>
  );
}

function ProjectPage({ projectId }: { projectId: string }) {
  const [initialProjectId, setProjectId] = useAtom(projectIdAtom);

  useEffect(
    function setProjectIdOnMount() {
      setProjectId(projectId);
    },
    [projectId, setProjectId]
  );

  if (!initialProjectId) {
    return <></>;
  }

  return (
    <div className={style.wrapper}>
      {projectId && (
        <>
          <WebsocketsHandler projectId={projectId} />
          <NavBar />
          <Suspense fallback={<FilterBar.Fallback />}>
            <FilterBar />
          </Suspense>
          <ProjectContent />
          <DialogueModalContainer />
          <ToastWrapper />
        </>
      )}
    </div>
  );
}

export default ProjectPageWrapper;
