import { client } from "@/http/lib/dittoClient";
import { createLibraryComponentFoldersStore } from "@shared/frontend/stores/LibraryComponentFolders";
import { REFRESH } from "@shared/frontend/stores/symbols";
import { atom } from "jotai";
import { observe } from "jotai-effect";
import { libraryListAtomFamily, preloadLibraryListAtomFamilyActionAtom, selectedLibraryFolderIdAtom } from "./Library";
import { locationAtom } from "./Location";

export const {
  libraryComponentFolderFamilyAtom,
  resetLibraryComponentFolderFamilyActionAtom,
  libraryComponentFoldersListAtom,
  refreshLibraryComponentFoldersListAtom: _refreshLibraryComponentFoldersListAtom,
  handleLibraryComponentFolderCreatedActionAtom: _handleLibraryComponentFolderCreatedActionAtom,
} = createLibraryComponentFoldersStore(client);

observe(function preloadComponentLibraryFolders(get, set) {
  // don't preload component library folders if we're not on the library page
  const location = get(locationAtom);
  if (!location.pathname?.includes("/library")) return;

  // run every time the component folders list is updated, which happens if a folder is created
  get(libraryComponentFoldersListAtom);

  set(preloadLibraryListAtomFamilyActionAtom);
});

export const refreshLibraryComponentFoldersListAtom: typeof _refreshLibraryComponentFoldersListAtom = atom(
  null,
  async (get, set) => {
    await set(_refreshLibraryComponentFoldersListAtom);
    const selectedFolderId = await get(selectedLibraryFolderIdAtom);
    set(libraryListAtomFamily(selectedFolderId ?? undefined), REFRESH);
  }
);

export const handleLibraryComponentFolderCreatedActionAtom: typeof _handleLibraryComponentFolderCreatedActionAtom =
  atom(null, async (_, set, arg) => {
    // update the front-end respository of component folders
    set(_handleLibraryComponentFolderCreatedActionAtom, arg);

    // update the component library structure
    const parentFolderAtom = libraryListAtomFamily(arg.newFolderParentId ?? undefined);
    // NOTE: refresh rather than manually setting data to avoid having to re-implement sorting logic
    set(parentFolderAtom, REFRESH);
  });
