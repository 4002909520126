import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import BoltIcon from "../../atoms/Icon/icons/BoltIcon";
import Text from "../../atoms/Text";
import { TagIcon } from "../../organisms/TextItemMetadata/icons";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  tags: string[];
  onTagClick: (tag: string) => void;

  maxSuggestions?: number;
}

export function TagSearchSuggestion(props: IProps) {
  function handleTagClick(tag: string) {
    props.onTagClick(tag);
  }

  return (
    <div
      style={props.style}
      className={classNames(style.tagSearchSuggestion, props.className)}
      data-testid="tag-search-suggestion"
    >
      <Icon Icon={<BoltIcon />} color="secondary" size="xxs" />
      <Text color="secondary" size="micro" className={style.labelText}>
        Search suggestion
      </Text>

      <div className={style.suggestionBadges}>
        {props.tags.slice(0, props.maxSuggestions).map((tag) => (
          <ClickableTagBadge key={tag} name={tag} onClick={() => handleTagClick(tag)} />
        ))}
      </div>
    </div>
  );
}

interface BadgeProps {
  name: string;
  onClick: () => void;
}

function ClickableTagBadge(props: BadgeProps) {
  return (
    <button className={style.tagBadge} onClick={props.onClick}>
      <Icon Icon={<TagIcon />} color="secondary" size="xxxs" />
      <Text color="secondary" size="micro">
        {props.name}
      </Text>
    </button>
  );
}

export default TagSearchSuggestion;
