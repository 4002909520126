import { client } from "@/http/lib/dittoClient";
import { createLibraryComponentsStore } from "@shared/frontend/stores/LibraryComponents";
import { atom } from "jotai";
import { LibraryListItem, libraryListAtomFamily, librarySelectedItemsAtom } from "./Library";

export const {
  libraryComponentFamilyAtom,
  nullableLibraryComponentFamilyAtom,
  handleLibraryComponentsUpdatedActionAtom,
  updateLibraryComponentActionAtom,
  deleteLibraryComponentActionAtom,
  resetLibraryComponentFamilyActionAtom,
  handleLibraryComponentCreatedActionAtom,
} = createLibraryComponentsStore(client);

export const deleteLibraryComponentStructureAtom = atom(null, async (get, set, data: { _id: string }) => {
  let libraryStructureNodeElement: LibraryListItem | undefined;
  let libraryStructure: LibraryListItem[] = [];

  set(deleteLibraryComponentActionAtom, {
    _id: data._id,
    onDelete: async (component) => {
      const libraryStructureNode = libraryListAtomFamily(component.folderId || undefined);
      libraryStructure = await get(libraryStructureNode);

      libraryStructureNodeElement = libraryStructure.find((item) => item._id === data._id);

      set(librarySelectedItemsAtom, (selectedItems) =>
        selectedItems.type === "component"
          ? { ...selectedItems, ids: selectedItems.ids.filter((item) => item !== data._id) }
          : selectedItems
      );

      set(
        libraryStructureNode,
        libraryStructure.filter((item) => item._id !== data._id)
      );
    },
    onRollback: async (component) => {
      if (libraryStructureNodeElement) {
        const libraryStructureNode = libraryListAtomFamily(component.folderId || undefined);

        set(libraryStructureNode, [...libraryStructure, libraryStructureNodeElement]);
      }
    },
  });
});
