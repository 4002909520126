import { ITipTapRichText } from "../../shared/types/TextItem";

// Checks to see if each ITipTapText has bold mark
export const isRichTextFullyBold = (richText: ITipTapRichText) => {
  const res = richText.content.every((paragraph) => {
    if (paragraph.type === "paragraph") {
      return paragraph.content?.every((text) => {
        if (text.type === "text") {
          return text.marks && text.marks.map((mark) => mark.type).includes("bold");
        }
        return true;
      });
    }
    return true;
  });

  return res;
};

export const removeBoldFromRichText = (richText: ITipTapRichText) => {
  const richTextWithoutBold = { ...richText };
  richText.content!.forEach((paragraph, paragraphIdx) =>
    paragraph.content?.forEach((text, textIdx) => {
      if (paragraph.content && paragraph.content[textIdx]) {
        const textToEdit = richTextWithoutBold.content![paragraphIdx].content![textIdx];
        // Some silly typescript checks
        if (textToEdit.type === "text" && text.type === "text") {
          textToEdit.marks = text.marks?.filter((m) => m.type !== "bold");
          console.log({ textToEdit });
        }
      }
    })
  );

  return richTextWithoutBold;
};
