import AppBouncer from "@/components/AppBouncer/AppBouncer";
import React from "react";
import { createRoot } from "react-dom/client";
import "./utils/datadog";

import "@fontsource/inconsolata/index.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/900.css";

import "@ds/tokens/index.module.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./highlight.module.css";
import "./index.module.css";

if (process.env.SEGMENT_WRITE_KEY) {
  window.analytics.load(process.env.SEGMENT_WRITE_KEY);
  window.analytics.page();
}

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<AppBouncer />);
