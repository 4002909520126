import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { ITextItem, ITipTapRichText } from "@shared/types/TextItem";
import classNames from "classnames";
import { Atom, PrimitiveAtom, useAtomValue } from "jotai";
import React, { memo, useMemo } from "react";
import { IUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import ActionableComponentItem from "../../molecules/ActionableComponentItem";
import style from "./index.module.css";

interface IComponentListProps {
  dragAndDropEnabled?: boolean;
  libraryComponentFoldersAtom?: Atom<ILibraryComponentFolder[] | Promise<ILibraryComponentFolder[]>>;
  filteredComponentIdsAtom: Atom<string[] | Promise<string[]>>;
  searchQuery: string;
  selectedComponentId: string | null;
  onComponentSelect: (id: string) => void;
  onComponentDeselect: () => void;
  actionText: string;
  onComponentActionClick: (id: string) => void;
  onSearchQueryChange: (query: string) => void;
  onLibraryLinkClick: () => void;
  listItemFamilyAtom: (
    id: string | null
  ) =>
    | ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent | ITextItem>>["familyAtom"]>
    | PrimitiveAtom<null>;
  libraryComponentFamilyAtom: (
    id: string | null
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"]>;
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  isSearching: boolean;
  isFiltering: boolean;
  handleClearFilters: () => void;
  inFolder: boolean;
  showFolderLabels: boolean;
  originalRichText?: ITipTapRichText;
}

export const ComponentList = memo(function ComponentList(props: IComponentListProps) {
  const componentIds = useAtomValue(props.filteredComponentIdsAtom);
  const {
    searchQuery,
    selectedComponentId,
    onComponentSelect,
    onComponentDeselect,
    onComponentActionClick,
    onSearchQueryChange,
    onLibraryLinkClick,
    listItemFamilyAtom,
    libraryComponentFamilyAtom,
    usersByIdAtom,
    isSearching,
    isFiltering,
    handleClearFilters,
    inFolder,
    showFolderLabels,
    originalRichText,
    libraryComponentFoldersAtom,
  } = props;

  const emptyStateMessage = useMemo(() => {
    if (componentIds.length > 0) return <React.Fragment />;
    if (isSearching) {
      return (
        <div className={style.emptyState}>
          <Text textAlign="center" color="secondary" size="small">
            Nothing found for "{searchQuery}"
          </Text>
          <Button level="secondary" size="small" onClick={() => onSearchQueryChange("")}>
            Clear search
          </Button>
        </div>
      );
    } else if (isFiltering) {
      return (
        <div className={style.emptyState}>
          <Text textAlign="center" color="secondary" size="small">
            No components found for the selected filters
          </Text>
          <Button level="secondary" size="small" onClick={handleClearFilters}>
            Clear filters
          </Button>
        </div>
      );
    } else if (inFolder) {
      return (
        <div className={style.emptyState}>
          <Text inline textAlign="center" color="secondary" size="small">
            This folder has no components yet. Publish a text item from a project or create a new component{" "}
            <Text inline color="action" size="small" onClick={onLibraryLinkClick}>
              in the library.
            </Text>
          </Text>
        </div>
      );
    } else {
      return (
        <div className={style.emptyState}>
          <Text inline textAlign="center" color="secondary" size="small">
            Your library has no components yet. Publish a text item from a project or create a new component{" "}
            <Text inline color="action" size="small" onClick={onLibraryLinkClick}>
              in the library.
            </Text>
          </Text>
        </div>
      );
    }
  }, [
    componentIds,
    isSearching,
    isFiltering,
    inFolder,
    searchQuery,
    handleClearFilters,
    onSearchQueryChange,
    onLibraryLinkClick,
  ]);

  return (
    <div className={classNames(style.componentList, { [style.showFolderLabels]: showFolderLabels })}>
      {componentIds.map((componentId) => (
        <ActionableComponentItem
          dragAndDropEnabled={props.dragAndDropEnabled}
          libraryComponentFamilyAtom={libraryComponentFamilyAtom}
          showFolderLabel={showFolderLabels}
          libraryComponentFoldersAtom={libraryComponentFoldersAtom}
          key={componentId}
          selectedComponentId={selectedComponentId}
          componentId={componentId}
          actionText={props.actionText}
          onComponentActionClick={onComponentActionClick}
          onComponentSelect={onComponentSelect}
          onComponentDeselect={onComponentDeselect}
          listItemFamilyAtom={listItemFamilyAtom}
          usersByIdAtom={usersByIdAtom}
          originalRichText={originalRichText}
        />
      ))}

      {emptyStateMessage}
    </div>
  );
});
