import { buildDittoClient } from "@shared/client/buildDittoClient";
import * as DittoEvents from "@shared/ditto-events";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { REFRESH } from "@shared/frontend/stores/symbols";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import logger from "@shared/utils/logger";
import { Atom, atom, WritableAtom } from "jotai";

type LibraryComponentFamilyAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"];
type LibraryComponentResetFamilyAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["resetAtom"];
type UpdateLibraryComponentActionAtomType = WritableAtom<
  null,
  [data: { _id: string; update: ILibraryComponent | typeof REFRESH }],
  void
>;
type HandleLibraryComponentsUpdatedActionAtomType = WritableAtom<
  null,
  [data: DittoEvents.ILibraryComponentsUpdatedData],
  void
>;
type HandleLibraryComponentCreatedActionAtomType = WritableAtom<
  null,
  [data: DittoEvents.ILibraryComponentCreatedData],
  void
>;
type DeleteLibraryComponentActionAtomType = WritableAtom<
  null,
  [
    data: {
      _id: string;
      onDelete?: (component: ILibraryComponent) => Promise<void>;
      onRollback?: (component: ILibraryComponent) => Promise<void>;
    }
  ],
  Promise<void>
>;

interface LibraryComponentsStore {
  libraryComponentFamilyAtom: LibraryComponentFamilyAtomType;
  nullableLibraryComponentFamilyAtom: (id: string | null) => ReturnType<LibraryComponentFamilyAtomType> | Atom<null>;
  resetLibraryComponentFamilyActionAtom: LibraryComponentResetFamilyAtomType;
  updateLibraryComponentActionAtom: UpdateLibraryComponentActionAtomType;
  deleteLibraryComponentActionAtom: DeleteLibraryComponentActionAtomType;
  handleLibraryComponentsUpdatedActionAtom: HandleLibraryComponentsUpdatedActionAtomType;
  handleLibraryComponentCreatedActionAtom: HandleLibraryComponentCreatedActionAtomType;
}

export function createLibraryComponentsStore(client: ReturnType<typeof buildDittoClient>): LibraryComponentsStore {
  /**
   * The source of truth for all library components.
   */
  const { familyAtom: libraryComponentFamilyAtom, resetAtom: resetLibraryComponentFamilyActionAtom } =
    batchedAsyncAtomFamily<ILibraryComponent>({
      asyncFetchRequest: async (get, ids) => {
        const data = await client.libraryComponent.getLibraryComponents({ componentIds: ids });
        return data.components;
      },
      getId: (item) => item._id.toString(),
      debugPrefix: "Library Component",
      throttleOptions: {
        leading: true,
      },
    });

  /**
   * This is a null atom that is always a null value
   */
  const nullAtom = atom(() => null);
  /**
   * This is a wrapper around the componentFamilyAtom that returns an atom that is null if the id is null.
   * This is useful for when we want to fetch a component by a TextItem's ws_comp field.
   */
  const nullableLibraryComponentFamilyAtom = function (id: string | null) {
    if (id === null) {
      // it's ABSOLUTELY CRITICAL that we define this atom outside of this function! if we instead do
      // return atom(null) here, then the componentFamilyAtom will be re-evaluated on every render,
      // which will cause a re-render loop.
      return nullAtom;
    } else {
      return libraryComponentFamilyAtom(id);
    }
  };

  /**
   * This is an action atom that updates a component. Since the return value of componentFamilyAtom could
   * be a null atom, we need to check if the component is null and manually case the type to update.
   */
  const updateLibraryComponentActionAtom = atom(
    null,
    (get, set, data: { _id: string; update: ILibraryComponent | typeof REFRESH }) => {
      const componentAtom = libraryComponentFamilyAtom(data._id);
      const component = get(componentAtom);

      // make sure the component's not null, then we can do normal batchedAsyncAtomFamily updates
      if (component !== null) {
        if (data.update === REFRESH) {
          set(componentAtom as ReturnType<typeof libraryComponentFamilyAtom>, REFRESH);
        } else {
          set(componentAtom as ReturnType<typeof libraryComponentFamilyAtom>, {
            ...component,
            ...data.update,
          });
        }
      }
    }
  );

  const handleLibraryComponentsUpdatedActionAtom = atom(
    null,
    (get, set, data: DittoEvents.ILibraryComponentsUpdatedData) => {
      for (const componentId of data.libraryComponentIds) {
        set(updateLibraryComponentActionAtom, { _id: componentId, update: REFRESH });
      }
    }
  );

  const handleLibraryComponentCreatedActionAtom = atom(
    null,
    async (get, set, data: DittoEvents.ILibraryComponentCreatedData) => {
      const _val = await get(libraryComponentFamilyAtom(data.componentId));
    }
  );

  const deleteLibraryComponentActionAtom = atom(
    null,
    async (
      get,
      set,
      data: {
        _id: string;
        onDelete?: (component: ILibraryComponent) => Promise<void>;
        onRollback?: (component: ILibraryComponent) => Promise<void>;
      }
    ) => {
      const component = await get(libraryComponentFamilyAtom(data._id));
      libraryComponentFamilyAtom.remove(data._id);

      await data.onDelete?.(component);

      try {
        await client.libraryComponent.deleteLibraryComponent({ componentId: data._id });
      } catch (e) {
        // If the deletion fails, we need to re-add the component to the store
        set(libraryComponentFamilyAtom(data._id), component);
        await data.onRollback?.(component);

        logger.error(
          "Failed to delete library component",
          {
            context: { componentId: data._id },
          },
          e
        );
      }
    }
  );

  return {
    libraryComponentFamilyAtom,
    nullableLibraryComponentFamilyAtom,
    resetLibraryComponentFamilyActionAtom,
    updateLibraryComponentActionAtom,
    deleteLibraryComponentActionAtom,
    handleLibraryComponentsUpdatedActionAtom,
    handleLibraryComponentCreatedActionAtom,
  };
}
