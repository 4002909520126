import { ZChangeItem } from "@shared/types/ActualChange";
import { z } from "zod";

const CHANGES_BASE_PATH = "/changes";

export const ZGetActivityByItemIdsRequest = {
  query: z.object({
    itemIds: z.string().array(),
    projectId: z.string().optional(),
  }),
};
export const ZGetActivityByItemIdsResponse = z.array(ZChangeItem);
export const GetActivityByItemIdsMethod = "GET";
export const GetActivityByItemIdsPath = `${CHANGES_BASE_PATH}`;

export const ZGetDittoProjectActivityRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
  }),
};
export const ZGetDittoProjectActivityResponse = z.array(ZChangeItem);
export const GetDittoProjectActivityMethod = "GET";
export const GetDittoProjectActivityPath = `${CHANGES_BASE_PATH}/project/:projectId`;

export const ZGetTextItemActivityRequest = {
  params: z.object({
    textItemId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
  }),
};
export const ZGetTextItemActivityResponse = z.array(ZChangeItem);
export const GetTextItemActivityMethod = "GET";
export const GetTextItemActivityPath = `${CHANGES_BASE_PATH}/text-item/:textItemId`;

export const ZGetComponentLibraryActivityRequest = {
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
  }),
};
export const ZGetComponentLibraryActivityResponse = z.array(ZChangeItem);
export const GetComponentLibraryActivityMethod = "GET";
export const GetComponentLibraryActivityPath = `${CHANGES_BASE_PATH}/library`;

export const ZGetLibraryComponentActivityRequest = {
  params: z.object({
    libraryComponentId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
  }),
};
export const ZGetLibraryComponentActivityResponse = z.array(ZChangeItem);
export const GetLibraryComponentActivityMethod = "GET";
export const GetLibraryComponentActivityPath = `${CHANGES_BASE_PATH}/library/:libraryComponentId`;

// ----- Legacy routes -----
export const ZGetCommentsByPageDocIdRequest = {
  params: z.object({
    doc_id: z.string(),
  }),
};
export const ZGetCommentsByPageDocIdResponse = z.array(ZChangeItem);
export const GetCommentsByPageDocIdMethod = "GET";
export const GetCommentsByPageDocIdPath = `${CHANGES_BASE_PATH}/comments/:doc_id`;
