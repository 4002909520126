import { IDittoProject } from "@shared/types/DittoProject";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ITextItem } from "@shared/types/TextItem";
import { atom, Atom, Getter, PrimitiveAtom, Setter, useAtom, useAtomValue, useSetAtom, WritableAtom } from "jotai";
import { soon } from "jotai-derive";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useMemo, useRef, useState } from "react";
import { IDittoClient } from "../../../../shared/client/DittoClient";
import atomWithDebounce from "../../../../shared/frontend/stores/atomWithDebounce";
import batchedAsyncAtomFamily from "../../../../shared/frontend/stores/batchedAsyncAtomFamily";
import { cacheAtom } from "../../../../shared/frontend/stores/cacheAtom";
import { ILibraryComponentFolder } from "../../../../shared/types/LibraryComponentFolder";
import { ITextItemStatus } from "../../../../shared/types/TextItem";
import { IUser } from "../../../../shared/types/User";

type FolderType = ILibraryComponentFolder;

interface IExternalProps<ItemType> {
  dragAndDropEnabled?: boolean;

  /**
   * HTTP Client for fetching data.
   */
  clientMethod:
    | IDittoClient["libraryComponent"]["searchLibraryComponents"]
    | IDittoClient["dittoProject"]["searchDittoProjectTextItems"];
  /**
   * List of selected text items to link to an item in this list.
   */
  selectedTextItems?: ITextItem[];
  /**
   * Atom for the suggested items to link to.
   */
  suggestedItemIdsAtom?: Atom<string[] | Promise<string[]>>;
  /**
   * Text to display on the action button for each list item in the library list.
   */
  actionText: string;
  /**
   * Atom Family used to populate list items.
   * @param id - ID of the list item
   * @returns Atom for the list item by ID.
   */
  listItemFamilyAtom: (
    id: string | null
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ItemType>>["familyAtom"]> | PrimitiveAtom<null>;

  /**
   * Atom Family used to populate component information - needed for rendering components AND needed for rendering
   * text items that are linked to components.
   * @param id - ID of the list item
   * @returns Atom for the list item by ID.
   */
  libraryComponentFamilyAtom: (
    id: string | null
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"]>;

  /**
   * Atom Family used to populate folders for this listType.
   * @param id - ID of the folder
   * @returns Atom for the folder by ID.
   */
  listItemFolderFamilyAtom: (
    id: string
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<FolderType>>["familyAtom"]>;
  /**
   * Atom for the list of item folders to use in this list.
   */
  foldersListAtom?: Atom<FolderType[] | Promise<FolderType[]>>;
  /**
   * Atom for all tags used by these items.
   */
  tagsAtom: Atom<string[] | Promise<string[]>>;
  /**
   * Atom for all projects in the workspace.
   */
  projectsAtom: Atom<IDittoProject[] | Promise<IDittoProject[]>>;
  /**
   * Atom for all users in the workspace by ID.
   */
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  /**
   * Callback action atom for when user selects the action on a selected component in the library list.
   * @param componentId - ID of the component that will be acted upon
   */
  itemActionClickAtom: WritableAtom<void, [string], void>;
  /**
   * Title to display at the top of the component list. If not provided, the default title will be used.
   */
  title?: string;
  /**
   * Callback atom for when the title back button is clicked. Only used when a custom title is provided.
   * @returns void
   */
  onTitleBackClickActionAtom?: WritableAtom<void, [], void>;
  projectId?: string;
  /**
   * Which filters we want to support in the library list
   */
  filters?: FilterType[];
}

const suggestedItemIdsAtom = atom<string[]>((get) => []);
const defaultFoldersListAtom = atom<FolderType[]>((get) => []);
/**
 * External hook used to integrate the CompactLibraryComponentList with the rest of the app. Just pass
 * in all the required atoms, spread the props on the CompactLibraryComponentList component, and you're
 * good to go.
 */
export function useCompactTextList<ItemType>(props: IExternalProps<ItemType>) {
  // Local State filtering Atoms
  const { current: selectedStatusesAtom } = useRef(atom<ITextItemStatus[]>([]));
  const { current: selectedTagsAtom } = useRef(atom<string[]>([]));
  const { current: selectedProjectIdAtom } = useRef(atom<string | null>(null));
  const { current: selectedLibraryFolderIdAtom } = useRef(atom<string | null>(null));
  const { current: selectedSuggestedComponentIdAtom } = useRef(atom<string | null>(null));
  const {
    current: { currentValueAtom: searchQueryAtom, debouncedValueAtom: debouncedSearchQueryAtom },
  } = useRef(atomWithDebounce("", 100));

  const { current: filteredComponentIdsAtom } = useRef(
    cacheAtom(
      atom(async (get) => {
        const folderId = get(selectedLibraryFolderIdAtom);
        const data = await props.clientMethod({
          folderId: folderId ?? undefined,
          query: get(debouncedSearchQueryAtom),
          statuses: get(selectedStatusesAtom),
          tags: get(selectedTagsAtom),
          // @ts-ignore
          projectId: (props.projectId || get(selectedProjectIdAtom)) ?? undefined,
        });
        if ("componentIds" in data) return data.componentIds;
        else {
          // TODO: fix the ui component to be able to render blocks and text items.
          return data.project.blocks.flatMap((block) => block.textItems.map((item) => item._id));
        }
      })
    )
  );

  // Local State Selection Atoms

  const { current: selectedComponentIdAtom } = useRef(atom<string | null>(null));

  const { current: selectedFolderAtom } = useRef(
    atom((get) => {
      const folderId = get(selectedLibraryFolderIdAtom);
      if (!folderId) return null;
      return soon(get(props.listItemFolderFamilyAtom(folderId)), (folder) => folder ?? null);
    })
  );

  // Local State

  const searchQuery = useAtomValue(searchQueryAtom);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useAtom(debouncedSearchQueryAtom);
  const [selectedTags, setSelectedTags] = useAtom(selectedTagsAtom);
  const [selectedStatuses, setSelectedStatuses] = useAtom(selectedStatusesAtom);
  const [selectedProjectId, setSelectedProjectId] = useAtom(selectedProjectIdAtom);
  const [selectedFolderId, setSelectedFolderId] = useAtom(selectedLibraryFolderIdAtom);
  const [selectedComponentId, setSelectedComponentId] = useAtom(selectedComponentIdAtom);

  const selectedFolder = useAtomValue(selectedFolderAtom);
  const enabledFilters: FilterType[] = useMemo(
    () => props.filters ?? ["status", "tags", "usedInProject"],
    [props.filters]
  );

  // MARK: - Legacy Stuff
  const suggestedComponentIds = useAtomValue(props.suggestedItemIdsAtom || suggestedItemIdsAtom);
  const folders = useAtomValue(props.foldersListAtom || defaultFoldersListAtom);
  const [selectedSuggestedComponentId, setSelectedSuggestedComponentId] = useAtom(selectedSuggestedComponentIdAtom);
  const componentActionClick = useSetAtom(props.itemActionClickAtom);

  const [selectedFilters, setSelectedFilters] = useState<FilterType[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const allTags = useAtomValue(props.tagsAtom);

  const inFolder = !!selectedFolder;
  const isSearching = !!debouncedSearchQuery;
  const isFiltering = useMemo(
    () => selectedTags.length > 0 || selectedStatuses.length > 0,
    [selectedTags, selectedStatuses]
  );
  const showFolderLabels = isSearching && !inFolder && folders.length > 0;

  const tagsSet = useMemo(() => new Set(allTags), [allTags]);

  const queryTagMatches = useMemo(() => {
    const queryWords = searchQuery.split(" ");
    return queryWords.filter((word) => !selectedTags.includes(word)).filter((word) => tagsSet.has(word));
  }, [searchQuery, tagsSet, selectedTags]);

  const formattedSelectedFilters = useMemo(
    () => selectedFilters.map((filter) => ({ value: filter, label: FilterOptions[filter] })),
    [selectedFilters]
  );

  const headerText = useMemo(() => {
    if (isSearching) {
      return "Search results";
    } else if (inFolder) {
      return selectedFolder?.name ?? "All components";
    } else {
      return "All components";
    }
  }, [isSearching, inFolder, selectedFolder]);

  const handleTagClick = useCallback(
    (tag: string) => {
      // remove the tag from the query
      setDebouncedSearchQuery(searchQuery.replace(new RegExp(`\\s?${tag}\\s?`, "g"), ""));

      // add the tag to the selected tags
      const newTags = !selectedTags.includes(tag) ? [...selectedTags, tag] : selectedTags;
      setSelectedTags(newTags);

      // make sure the tags filter is visible
      setSelectedFilters((prev) => (!prev.includes("tags") ? [...prev, "tags"] : prev));

      setImmediate(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      });
    },
    [selectedTags, setSelectedTags, setDebouncedSearchQuery, setSelectedFilters, searchQuery]
  );

  const handleClearFilters = useCallback(() => {
    setSelectedFilters([]);
    setSelectedTags([]);
    setSelectedStatuses([]);
    setSelectedProjectId(null);
  }, [setSelectedProjectId, setSelectedStatuses, setSelectedTags]);

  const handleRemoveFilter = useCallback(
    (filter: FilterType) => {
      setSelectedFilters((prev) => prev.filter((f) => f !== filter));

      if (filter === "tags") {
        setSelectedTags([]);
      } else if (filter === "status") {
        setSelectedStatuses([]);
      }
    },
    [setSelectedTags, setSelectedStatuses]
  );

  const handleSetSelectedFilters = useCallback(
    (filters: { value: string; label: string }[]) => {
      setSelectedFilters(filters.map((filter) => filter.value as FilterType));
    },
    [setSelectedFilters]
  );

  const handleFolderClick = useCallback(
    (folder: FolderType) => {
      setSelectedFolderId(folder._id);
      setSelectedComponentId(null);
    },
    [setSelectedFolderId, setSelectedComponentId]
  );

  const handleComponentSelect = useCallback(
    (componentId: string) => {
      if (selectedComponentId === componentId) {
        setSelectedComponentId(null);
      } else {
        setSelectedComponentId(componentId);
      }
      if (selectedSuggestedComponentId) setSelectedSuggestedComponentId(null);
    },
    [selectedComponentId, selectedSuggestedComponentId, setSelectedSuggestedComponentId, setSelectedComponentId]
  );

  const handleSuggestedComponentSelect = useCallback(
    (componentId: string) => {
      setSelectedSuggestedComponentId(componentId);
      if (selectedComponentId) setSelectedComponentId(null);
    },
    [setSelectedSuggestedComponentId, selectedComponentId, setSelectedComponentId]
  );

  const handleLibraryLinkClick = useCallback(() => {
    // TODO
    // history.push("/library");
  }, []);

  const handleBackFolderClick = useCallback(() => {
    setSelectedFolderId(null);
  }, [setSelectedFolderId]);

  const handleComponentDeselect = useCallback(() => {
    setSelectedComponentId(null);
  }, [setSelectedComponentId]);

  const handleSuggestedComponentDeselect = useCallback(() => {
    setSelectedSuggestedComponentId(null);
  }, [setSelectedSuggestedComponentId]);

  const handleTitleBackClick = useAtomCallback(
    useCallback(
      (get: Getter, set: Setter) => {
        if (!props.onTitleBackClickActionAtom) return;
        set(props.onTitleBackClickActionAtom);
      },
      [props.onTitleBackClickActionAtom]
    )
  );

  return {
    selectedFilters,
    searchInputRef,
    inFolder,
    isSearching,
    isFiltering,
    showFolderLabels,
    queryTagMatches,
    headerText,
    formattedSelectedFilters,
    actionText: props.actionText,
    title: props.title,

    handleTitleBackClick,
    handleTagClick,
    handleClearFilters,
    handleRemoveFilter,
    handleSetSelectedFilters,
    handleFolderClick,
    handleComponentSelect,
    handleSuggestedComponentSelect,
    handleLibraryLinkClick,
    handleBackFolderClick,
    handleComponentDeselect,
    handleSuggestedComponentDeselect,
    onComponentActionClick: componentActionClick,

    filteredComponentIdsAtom,
    suggestedComponentIds,
    listItemFamilyAtom: props.listItemFamilyAtom,
    listItemFolderFamilyAtom: props.listItemFolderFamilyAtom,
    usersByIdAtom: props.usersByIdAtom,
    tagsAtom: props.tagsAtom,
    folders,
    foldersListAtom: props.foldersListAtom,
    currentFolder: selectedFolder,
    searchQuery,
    debouncedSearchQuery,
    onSearchQueryChange: setDebouncedSearchQuery,
    selectedFolderId,
    setSelectedFolderId,
    selectedComponentId,
    setSelectedComponentId,
    selectedSuggestedComponentId,
    setSelectedSuggestedComponentId,
    originalRichText: !props.selectedTextItems
      ? undefined
      : props.selectedTextItems.length === 1
      ? props.selectedTextItems[0].rich_text
      : undefined,
    selectedTags,
    setSelectedTags,
    selectedStatuses,
    setSelectedStatuses,
    selectedProjectId,
    setSelectedProjectId,
    projectsAtom: props.projectsAtom,
    enabledFilters,
    dragAndDropEnabled: props.dragAndDropEnabled,
    libraryComponentFamilyAtom: props.libraryComponentFamilyAtom,
  };
}

export type IProps<ItemType> = ReturnType<typeof useCompactTextList<ItemType>> & {
  className?: string;
  style?: React.CSSProperties;
};

export const FilterOptions = {
  status: "Status",
  tags: "Tags",
  usedInProject: "Project",
} as const;

export type FilterType = keyof typeof FilterOptions;
