import { ZLibraryComponentUpdate } from "@shared/types/DittoProject";
import { ZFigmaTextNodeSerialized } from "@shared/types/FigmaTextNode";
import { ZMoveLibraryComponentsProps } from "@shared/types/http/LibraryComponent";
import { ZObjectId } from "@shared/types/lib";
import { ZLibraryComponent, ZSearchScoreBreakdown } from "@shared/types/LibraryComponent";
import { ZTextItem, ZTextItemStatus, ZTextItemVariable, ZTipTapRichText } from "@shared/types/TextItem";
import { z } from "zod";

export const ZGetLibraryComponentsRequest = {
  query: z.object({
    folderId: z.string().optional(),
    componentIds: z.array(z.string()).optional(),
  }),
};
export const ZGetLibraryComponentsResponse = z.object({
  components: z.array(ZLibraryComponent),
});
export const GetLibraryComponentsMethod = "GET";
export const GetLibraryComponentsPath = "/library-component";

export const ZSearchLibraryComponentsRequest = {
  query: z.object({
    query: z.string().optional(),
    folderId: z.string().optional(),
    statuses: z.array(ZTextItemStatus).optional(),
    projectId: z.string().optional(),
    tags: z.array(z.string()).optional(),
  }),
};
export const ZSearchLibraryComponentsResponse = z.object({
  componentIds: z.array(ZObjectId),
});
export const SearchLibraryComponentsMethod = "GET";
export const SearchLibraryComponentsPath = "/library-component/search";

export const ZSearchLibraryComponentsDebugRequest = {
  body: z.object({
    query: z.string().optional(),
    customBoosts: z.record(z.string(), z.number()).optional(),
  }),
};
export const ZSearchLibraryComponentsDebugResponse = z.object({
  searchResults: z.array(ZSearchScoreBreakdown),
});
export const SearchLibraryComponentsDebugMethod = "POST";
export const SearchLibraryComponentsDebugPath = "/library-component/search-debug";

export const ZCreateLibraryComponentRequest = {
  body: z.object({
    _id: ZObjectId,
    name: z.string(),
    richText: ZTipTapRichText,
    folderId: ZObjectId.nullable(),
    variables: z.array(ZTextItemVariable),
  }),
};
export const ZCreateLibraryComponentResponse = z.object({
  newComponent: ZLibraryComponent,
});
export const CreateLibraryComponentMethod = "POST";
export const CreateLibraryComponentPath = "/library-component";

export const ZPublishRequest = {
  body: z.object({
    name: z.string(),
    textItemId: z.string(),
    newComponentId: z.string().optional(),
    folderId: z.string().nullable().optional(),
  }),
};
export const ZPublishResponse = z.object({ autoName: z.string() });
export const PublishMethod = "POST";
export const PublishPath = "/library-component/publish";

export const ZExactTextMatchesRequest = {
  query: z.object({
    textItemId: z.string(),
  }),
};
export const ZExactTextMatchesResponse = z.object({ componentIds: z.array(ZObjectId) });
export const ExactTextMatchesMethod = "GET";
export const ExactTextMatchesPath = "/library-component/exact-text-matches";

export const ZAutoNameRequest = {
  query: z.object({
    text: z.string(),
  }),
};
export const ZAutoNameResponse = z.object({ autoName: z.string() });
export const AutoNameMethod = "GET";
export const AutoNamePath = "/library-component/auto-name";

export const ZUpdateLibraryComponentsRequest = {
  body: z.object({
    updates: z.array(ZLibraryComponentUpdate),
    name: z.string().optional(),
    variantId: z.string().optional(),
    newVariant: z
      .object({
        name: z.string(),
        variantId: ZObjectId,
      })
      .optional(),
  }),
};
export const ZUpdateLibraryComponentsResponse = z.object({
  updatedLibraryComponents: z.array(ZLibraryComponent),
  updatedTextItems: z.array(ZTextItem),
});
export const UpdateLibraryComponentsMethod = "PATCH";
export const UpdateLibraryComponentsPath = "/library-component/update";

export const ZLinkTextItemsRequest = {
  params: z.object({
    componentId: z.string(),
  }),
  body: z.object({
    projectId: z.string(),
    textItemIds: z.array(z.string()),
  }),
};
export const ZLinkTextItemsResponse = z.unknown();
export const LinkTextItemsMethod = "PATCH";
export const LinkTextItemsPath = "/library-component/:componentId/link";

export const ZUnlinkTextItemsRequest = {
  params: z.object({
    componentId: z.string(),
  }),
  body: z.object({
    projectId: z.string(),
    textItemIds: z.array(z.string()),
  }),
};
export const ZUnlinkTextItemsResponse = z.unknown();
export const UnlinkTextItemsMethod = "PATCH";
export const UnlinkTextItemsPath = "/library-component/:componentId/unlink";

export const ZLinkMultipleFigmaNodesRequest = {
  body: z.object({
    projectId: ZObjectId,
    links: z.array(
      z.object({
        componentId: ZObjectId,
        figmaNodes: z.array(ZFigmaTextNodeSerialized),
        textItemId: ZObjectId.optional(),
      })
    ),
  }),
};
export const ZLinkMultipleFigmaNodesResponse = z.object({
  textItems: z.array(ZTextItem),
});
export const LinkMultipleFigmaNodesMethod = "PATCH";
export const LinkMultipleFigmaNodesPath = "/library-component/link-multiple-figma-nodes";

export const ZLinkFigmaNodesRequest = {
  params: z.object({
    componentId: z.string(),
  }),
  body: z.object({
    projectId: ZObjectId,
    figmaNodes: z.array(ZFigmaTextNodeSerialized).nonempty(),
    textItemId: ZObjectId.optional(), // _id to use for the new text item (to support optimistic updates)
  }),
};
export const ZLinkFigmaNodesResponse = z.object({
  textItem: ZTextItem,
});
export const LinkFigmaNodesMethod = "PATCH";
export const LinkFigmaNodesPath = "/library-component/:componentId/linkFigmaNodes";

export const ZGetAllComponentTagsRequest = {};
export const ZGetAllComponentTagsResponse = z.object({
  tags: z.array(z.string()),
});
export const GetAllComponentTagsMethod = "GET";
export const GetAllComponentTagsPath = "/library-component/all-tags";

export const ZMoveLibraryComponentsRequest = {
  body: ZMoveLibraryComponentsProps,
};
export const ZMoveLibraryComponentsResponse = z.object({
  newComponentIds: z.array(z.string()),
});
export const MoveLibraryComponentsMethod = "POST";
export const MoveLibraryComponentsPath = "/library-component/move-components";

export const ZDeleteLibraryComponentRequest = {
  params: z.object({
    componentId: z.string(),
  }),
};
export const ZDeleteLibraryComponentResponse = z.object({});
export const DeleteLibraryComponentMethod = "DELETE";
export const DeleteLibraryComponentPath = "/library-component/:componentId";
