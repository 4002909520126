import z from "zod";
import { ZFigmaTextNode } from "./FigmaTextNode";
import { ZObjectId } from "./lib";
import { SlackNotificationTypes } from "./SlackNotifications";
import { ZTextItemPluralType, ZTextItemStatus, ZTipTapRichText } from "./TextItem";

export const ZDittoProjectBlock = z.object({
  _id: ZObjectId,
  name: z.string(),
  frameCount: z.number(),
});

export type IDittoProjectBlock = z.infer<typeof ZDittoProjectBlock>;

// Type for allowing partial updates to a block; all fields are optional excpet for _id.
export const ZDittoProjectBlockUpdate = ZDittoProjectBlock.partial().extend({
  _id: z.string(),
});
export type IDittoProjectBlockUpdate = z.infer<typeof ZDittoProjectBlockUpdate>;

export const ZDittoProjectTextLayerRules = z.object({
  showStatusIcon: z.boolean(),
});
export type IDittoProjectTextLayerRules = z.infer<typeof ZDittoProjectTextLayerRules>;

const ZDittoProjectFigmaIntegration = z.object({
  fileId: z.string().optional(),
  branchId: z.string().nullable().optional(),
  dittoComponentNodeId: z.string().optional(),
  dittoComponentKey: z.string().optional(),
  textLayerRules: ZDittoProjectTextLayerRules,
  framePreviews: z
    .record(
      z.string(),
      z.object({
        previewUrl: z.string(),
        lastUpdated: z.string(),
      })
    )
    .optional(),
});

export type IDittoProjectFigmaIntegration = z.infer<typeof ZDittoProjectFigmaIntegration>;

const ZSlackProjectFigmaIntegration = z.object({
  channel_id: z.string().nullable(),
  channel_name: z.string().nullable(),
  webhook_url: z.string().nullable(),
  configuration_url: z.string().nullable(),
  notif_types: SlackNotificationTypes,
});

const ZDittoProjectIntegrations = z.object({
  figma: ZDittoProjectFigmaIntegration,
  slack: ZSlackProjectFigmaIntegration.optional(),
});

export const ZDittoProject = z.object({
  _id: ZObjectId,
  workspaceId: ZObjectId,
  name: z.string(),
  folderId: ZObjectId.nullable(),
  integrations: ZDittoProjectIntegrations,
  blocks: z.array(ZDittoProjectBlock),
  createdAt: z.date(),
  updatedAt: z.date(),
});

const ZLinkedDittoProject = ZDittoProject.extend({
  integrations: ZDittoProjectIntegrations.extend({
    figma: ZDittoProjectFigmaIntegration.required(),
  }),
});

export type ILinkedDittoProject = z.infer<typeof ZLinkedDittoProject>;

export type IDittoProject = z.infer<typeof ZDittoProject>;

export const ZTextNodeDiff = z.object({
  textItemId: ZObjectId,
  nodeId: z.string(),
  frameNodeId: z.string(),
  textBefore: z.string(),
  textAfter: z.string(),
  textItemUpdatedAt: z.string(),
});

export type ITextNodeDiff = z.infer<typeof ZTextNodeDiff>;

export const ZTextItemsUpdate = z.object({
  textItemIds: z.array(ZObjectId),
  richText: ZTipTapRichText.optional(),
  plurals: z
    .array(
      z.object({
        rich_text: ZTipTapRichText,
        form: ZTextItemPluralType,
      })
    )
    .nullable()
    .optional(),
  status: ZTextItemStatus.optional(),
  assignee: z.string().nullable().optional(),
  tags: z.array(z.string()).optional(),
  tagsToDelete: z.array(z.string()).optional(),
  notes: z.string().optional(),
  characterLimit: z.number().nullable().optional(),
  lastReconciledRichText: ZTipTapRichText.optional(),
  figmaPage: z
    .object({
      figmaNodeId: z.string(),
      figmaPageId: z.string(),
    })
    .optional(),
});

export type ITextItemUpdate = z.infer<typeof ZTextItemsUpdate>;

export const ZLibraryComponentUpdate = z.object({
  libraryComponentIds: z.array(ZObjectId),
  folderId: z.string().nullable().optional(),
  richText: ZTipTapRichText.optional(),
  lastReconciledRichText: ZTipTapRichText.optional(),
  plurals: z
    .array(
      z.object({
        rich_text: ZTipTapRichText,
        form: ZTextItemPluralType,
      })
    )
    .nullable()
    .optional(),
  status: ZTextItemStatus.optional(),
  assignee: z.string().nullable().optional(),
  tags: z.array(z.string()).optional(),
  tagsToDelete: z.array(z.string()).optional(),
  notes: z.string().optional(),
});

export type ILibraryComponentUpdate = z.infer<typeof ZLibraryComponentUpdate>;

export const ZTextNodeWithSelectionData = ZFigmaTextNode.extend({
  isSelected: z.boolean(),
  diff: ZTextNodeDiff.optional(),
});
export type ITextNodeWithSelectionData = z.infer<typeof ZTextNodeWithSelectionData>;

export const ZFramePreviewData = z.object({
  frameName: z.string(),
  frameNodeId: z.string(),
  previewUrl: z.string(),
  lastUpdated: z.string(),
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
  textNodesToHighlight: z.array(ZFigmaTextNode),
});
export type IFramePreviewData = z.infer<typeof ZFramePreviewData>;

export const ZFramePreviewDataWithSelectionData = ZFramePreviewData.extend({
  frameIsModified: z.boolean(),
  textNodesToHighlight: z.array(ZTextNodeWithSelectionData),
});
export type IFramePreviewDataWithSelectionData = z.infer<typeof ZFramePreviewDataWithSelectionData>;

export const ZTextItemConflictResolution = z.object({
  textItemId: ZObjectId,
  figmaNodes: z.array(
    z.object({
      ids: z.array(z.string()),
      richText: ZTipTapRichText,
    })
  ),
  selection: z.union([z.literal("ditto"), z.literal("figma")]),
  selectedFigmaNodeId: z.string().nullable(),
});

export type ITextItemConflictResolution = z.infer<typeof ZTextItemConflictResolution>;
