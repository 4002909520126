import { client } from "@/http/lib/dittoClient";
import { projectActivityAtom, updateSelectedTextItemActivityActionAtom } from "@/stores/Activity";
import {
  handleCommentCreatedActionAtom,
  handleCommentResolutionUpdatedActionAtom,
  handleCommentUpdatedActionAtom,
} from "@/stores/Comments";
import { handleFigmaSyncCompletedActionAtom, handleFigmaSyncErrorActionAtom } from "@/stores/FigmaSync";
import {
  handleFigmaTextNodesUnlinkedActionAtom,
  handleNewFigmaSyncActionsAtom,
  handleTextItemInstancesSplitActionAtom,
  handleUpdateBlocksActionAtom,
  newTextItemsCreatedActionAtom,
  textItemsDeletedActionAtom,
  textItemsUpdatedActionAtom,
} from "@/stores/Project";
import { refreshVariantsAtom } from "@/stores/Workspace";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { IChangeItem } from "@shared/types/ActualChange";
import { JobNames } from "@shared/types/jobs/JobNames";
import fetchWithRetry from "@shared/utils/fetchWithRetry";
import logger from "@shared/utils/logger";
import { validateChangeItems } from "@shared/utils/validateChangeItems";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { WEBSOCKET_EVENTS } from "../../../../../shared/common/constants";
import { WEBSOCKET_URL } from "../../../../../shared/types/websocket";
import { useAuthenticatedAuth } from "../../../../store/AuthenticatedAuthContext";

interface WebsocketsHandlerProps {
  projectId: string;
}

// Only general project Websockets should be handled here.
// Otherwise, place in a domain specific websocket handler component.
export function WebsocketsHandler(props: WebsocketsHandlerProps) {
  const setProjectActivity = useSetAtom(projectActivityAtom);
  const updateSelectedTextItemActivity = useSetAtom(updateSelectedTextItemActivityActionAtom);
  const handleCommentCreatedAction = useSetAtom(handleCommentCreatedActionAtom);
  const handleCommentUpdatedAction = useSetAtom(handleCommentUpdatedActionAtom);
  const handleCommentResolutionUpdatedAction = useSetAtom(handleCommentResolutionUpdatedActionAtom);

  const { sendMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  const { getTokenSilently } = useAuthenticatedAuth();

  useEffect(() => {
    async function sendDocSubscribeMsg() {
      if (!props.projectId) {
        return;
      }

      const subscribeToDocMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_DOC_SUBSCRIPTION,
        token: await getTokenSilently(),
        docId: props.projectId,
      };
      sendMessage(JSON.stringify(subscribeToDocMsg));
    }

    if (readyState === 1) {
      sendDocSubscribeMsg();
    }
  }, [readyState, props.projectId, sendMessage, getTokenSilently]);

  useDittoEventListener(
    DittoEvents.projectActualChangesCreated,
    async function handleProjectActualChangesCreated(data) {
      const {
        success,
        response: items,
        error,
      } = await fetchWithRetry({
        request: () => {
          return client.changes.getActivityByItemIds({
            itemIds: data.changeItemIds,
            projectId: data.projectId,
          });
        },
        requestName: "webapp/websocket/fetchChangeItems",
        validationFn: (response) => response.length === data.changeItemIds.length,
        fallbackResponse: [],
        initialDelay: 300,
      });

      if (!success) {
        logger.error(
          `Failed to find all change items by ids after retry`,
          {
            context: { itemIds: data.changeItemIds, returnedItems: items },
          },
          error || new Error("Failed to find all change items by ids after retry")
        );
      }

      const validItems = validateChangeItems(items);
      if (validItems.length) {
        setProjectActivity((prev: IChangeItem[]) => {
          return [...validItems, ...prev];
        });
        updateSelectedTextItemActivity(validItems);
      }
    }
  );

  // MARK: - Comments

  useDittoEventListener(DittoEvents.projectCommentThreadCreated, async function handleCommentCreated(data) {
    const [newComment] = await client.comments.getCommentsByCommentIds({
      projectId: data.projectId,
      commentIds: [data.commentThreadId],
      type: "standard",
    });

    if (newComment) {
      handleCommentCreatedAction(newComment);
    }
  });

  useDittoEventListener(DittoEvents.projectCommentThreadUpdated, async function handleCommentUpdated(data) {
    const [newComment] = await client.comments.getCommentsByCommentIds({
      projectId: data.projectId,
      commentIds: [data.commentThreadId],
      type: "standard",
    });

    if (newComment) {
      handleCommentUpdatedAction(newComment);
    }
  });

  useDittoEventListener(
    DittoEvents.projectCommentThreadResolutionUpdated,
    async function handleCommentResolutionUpdated(data) {
      handleCommentResolutionUpdatedAction({ commentThreadId: data.commentThreadId, isResolved: data.isResolved });
    }
  );

  // MARK: - Blocks updated

  const handleUpdateBlocksAction = useSetAtom(handleUpdateBlocksActionAtom);

  useDittoEventListener(DittoEvents.projectBlocksUpdated, async function handleBlocksUpdated(data) {
    handleUpdateBlocksAction(data);
  });

  // MARK: - Text Items created

  const newTextItemsCreatedAction = useSetAtom(newTextItemsCreatedActionAtom);

  useDittoEventListener(DittoEvents.textItemsCreated, async function handleTextItemsCreated(data) {
    newTextItemsCreatedAction(data);
  });

  // MARK: - Text Items updated
  const textItemsUpdatedAction = useSetAtom(textItemsUpdatedActionAtom);

  useDittoEventListener(DittoEvents.textItemsUpdated, async function handleTextItemsUpdated(data) {
    textItemsUpdatedAction(data);
  });

  // MARK: - Text Items delete
  const textItemsDeletedAction = useSetAtom(textItemsDeletedActionAtom);

  useDittoEventListener(DittoEvents.textItemsDeleted, async function handleTextItemsDeleted(data) {
    textItemsDeletedAction(data);
  });

  // MARK: - Text item variant removed

  useDittoEventListener(DittoEvents.textItemVariantRemoved, async function handleTextItemVariantRemoved(data) {
    const { textItemId, application, userId } = data;
    textItemsUpdatedAction({
      textItemIds: [textItemId],
      userObjectId: userId,
      application,
    });
  });

  // MARK: Unlinking Figma Nodes

  const handleTextItemInstancesSplitAction = useSetAtom(handleTextItemInstancesSplitActionAtom);

  useDittoEventListener(DittoEvents.textItemInstancesSplit, async function handleTextItemInstancesSplit(data) {
    handleTextItemInstancesSplitAction(data);
  });

  // MARK: Disconnecting Figma Nodes

  const handleFigmaTextNodesUnlinkedAction = useSetAtom(handleFigmaTextNodesUnlinkedActionAtom);

  useDittoEventListener(DittoEvents.figmaTextNodesUnlinked, async function handleUnlinkNodes(data) {
    handleFigmaTextNodesUnlinkedAction(data);
  });

  const handleFigmaSyncErrorAction = useSetAtom(handleFigmaSyncErrorActionAtom);
  const handleFigmaSyncCompletedAction = useSetAtom(handleFigmaSyncCompletedActionAtom);

  // MARK: Background job updates
  useDittoEventListener(DittoEvents.backgroundJobUpdated, async function handleBackgroundJobUpdated(data) {
    if (data.projectId !== props.projectId) return;

    if (data.status === "active") {
      logger.info(`Job [${data.jobName}] started`, { context: { ...data } });
    } else if (data.status === "completed") {
      logger.info(`Job [${data.jobName}] completed`, { context: { ...data } });

      if (data.jobName === JobNames.DittoProjectFigmaPreviewsJob) {
        handleFigmaSyncCompletedAction();
      }
    } else if (data.status === "failed") {
      logger.warn(`Job [${data.jobName}] failed`, { context: { ...data } }, {});

      if (data.jobName === JobNames.FigmaCacheRefreshJob) {
        if (data.failedReason === "Invalid token") {
          handleFigmaSyncErrorAction("INVALID_TOKEN");
        } else {
          handleFigmaSyncErrorAction("UNKNOWN");
        }
      }
    }
  });

  const handleNewFigmaSyncActions = useSetAtom(handleNewFigmaSyncActionsAtom);

  useDittoEventListener(DittoEvents.newFigmaSyncActions, async function newFigmaSyncActionsHandler(data) {
    if (data.projectId !== props.projectId) {
      return;
    }

    handleNewFigmaSyncActions(data.actions);
  });

  // MARK: - Variant created

  const refreshVariants = useSetAtom(refreshVariantsAtom);

  useDittoEventListener(DittoEvents.variantCreated, async function handleVariantCreated(data) {
    refreshVariants();
  });

  // NOTE: DO NOT listen for LibraryComponent / LibraryComponentFolder events here unless you need to for a reason that is very
  // specific to the project page
  // Info related to the library should be subscribed to here:
  // file://./../../Library/components/LibraryDittoEventsHandler/index.tsx

  return null;
}
