import { libraryComponentFamilyAtom } from "@/stores/Components";
import { componentIsSelectedAtomFamily, handleComponentClickActionAtom } from "@/stores/Library";
import LibraryComponentIcon from "@ds/icons/LibraryComponentIcon";
import NavItem from "@ds/molecules/NavItem";
import { ILibraryComponentItem } from "@shared/types/Library";
import { useAtomValue, useSetAtom } from "jotai";
import { memo, useCallback } from "react";
import style from "./style.module.css";

const LibraryComponentNavItem = memo(function LibraryComponentNavItem(props: { item: ILibraryComponentItem }) {
  const component = useAtomValue(libraryComponentFamilyAtom(props.item._id));
  const handleComponentClickAction = useSetAtom(handleComponentClickActionAtom);
  const isSelected = useAtomValue(componentIsSelectedAtomFamily(props.item._id));

  const handleComponentClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      handleComponentClickAction({
        event,
        componentId: props.item._id,
        skipInlineEditing: true,
      });
    },
    [handleComponentClickAction, props.item._id]
  );

  if (!component) return null;

  return (
    <NavItem
      id={props.item._id}
      type="item"
      label={component.name}
      labelOptions={{ color: "purple" }}
      icon={<LibraryComponentIcon className={style.componentIcon} />}
      dragAndDrop={{ isDragDisabled: true }}
      selected={isSelected}
      onClick={handleComponentClick}
    />
  );
});

export default LibraryComponentNavItem;
