import React from "react";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export function AddToLibraryIcon(props: IProps) {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M9.096 8.40757C8.62133 8.51957 8.14933 8.68757 7.68 8.91157V2.60757C8.11733 2.35157 8.58133 2.15957 9.072 2.03157C9.56267 1.90357 10.0587 1.83957 10.56 1.83957C10.944 1.83957 11.3253 1.8769 11.704 1.95157C12.0827 2.02624 12.448 2.13824 12.8 2.28757V4.39957H14.08V1.90357C14.08 1.78624 14.0507 1.67424 13.992 1.56757C13.9333 1.4609 13.8453 1.3809 13.728 1.32757C13.2267 1.08224 12.712 0.892904 12.184 0.75957C11.656 0.626237 11.1147 0.55957 10.56 0.55957C9.94133 0.55957 9.336 0.63957 8.744 0.79957C8.152 0.95957 7.584 1.19957 7.04 1.51957C6.496 1.19957 5.928 0.95957 5.336 0.79957C4.744 0.63957 4.13867 0.55957 3.52 0.55957C2.96533 0.55957 2.424 0.626237 1.896 0.75957C1.368 0.892904 0.853333 1.08224 0.352 1.32757C0.234667 1.3809 0.146667 1.4609 0.088 1.56757C0.0293334 1.67424 0 1.78624 0 1.90357V9.61557C0 9.86091 0.104 10.0476 0.312 10.1756C0.52 10.3036 0.736 10.3089 0.96 10.1916C1.35467 9.97824 1.768 9.81291 2.2 9.69557C2.632 9.57824 3.072 9.51957 3.52 9.51957C4.16 9.51957 4.77867 9.63157 5.376 9.85557C5.97333 10.0796 6.528 10.3942 7.04 10.7996C7.552 10.3942 8.10667 10.0796 8.704 9.85557C8.99752 9.7455 9.29618 9.66248 9.6 9.60649V8.31044C9.43036 8.33594 9.26236 8.36832 9.096 8.40757Z"
        fill="currentColor"
      />
      <path
        d="M10.88 9.51957H12.8V11.4396H14.08V9.51957H16V8.23957H14.08V6.31957H12.8V8.23957H10.88V9.51957Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AddToLibraryIcon;
