import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  title: string;
  closeAction: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export function DetailsPanelTitleRow(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.DetailsPanelTitleRowWrapper, props.className)}
      data-testid="details-panel-title-row"
    >
      <Text weight="strong" color="primary">
        {props.title}
      </Text>
      <Button onClick={props.closeAction} level="subtle" type="icon" size="base">
        <CloseIcon className={style.closeButton} />
      </Button>
    </div>
  );
}
export default DetailsPanelTitleRow;
