import { atom } from "jotai";

export interface Toast {
  message: string; // The string to display
  timeout?: number; // How long to display the toast for, defaults to DEFAULT_TIMEOUT
  showCloseButton?: boolean; // Whether to show a close button, defaults to true
  onClickBody?: () => void; // Optional callback when the body is clicked
  action?: string; // Optionally, the string to display in the action button - must also provide onClickAction
  onClickAction?: () => void; // Optionally, the callback when the action button is clicked - must also provide action
}

const DEFAULT_TIMEOUT = 3000;

const toastTimerAtom = atom<NodeJS.Timeout | null>(null);

const setToastTimerActionAtom = atom(null, (get, set, timeout: number) => {
  const timer = get(toastTimerAtom);
  if (timer) {
    clearTimeout(timer);
  }
  const newTimer = setTimeout(() => {
    set(currentToastAtom, null);
  }, timeout);
  set(toastTimerAtom, newTimer);
});

const clearToastTimerActionAtom = atom(null, (get, set) => {
  const timer = get(toastTimerAtom);
  if (timer) {
    clearTimeout(timer);
  }
  set(toastTimerAtom, null);
});

export const currentToastAtom = atom<Toast | null>(null);

export const showToastActionAtom = atom(null, (_get, set, toast: Toast) => {
  const toastToShow: Toast = {
    ...toast,
    timeout: toast.timeout || DEFAULT_TIMEOUT, // Uses default timeout unless otherwise specified
    showCloseButton: toast.showCloseButton ?? true, // Includes close button by default, pass in false to override
  };

  set(currentToastAtom, toastToShow);
  set(setToastTimerActionAtom, toastToShow.timeout);
});

export const hideToastActionAtom = atom(null, (_get, set) => {
  set(currentToastAtom, null);
  set(clearToastTimerActionAtom);
});
