import { generateHTML } from "@tiptap/react";
import classnames from "classnames";
import parse from "html-react-parser";
import React, { useMemo, useRef } from "react";
import prepareRichTextNodes from "../../../../shared/common/richText/prepareRichTextNodes";
import getExtensions from "../../../../shared/frontend/richText/templates";
import { ITipTapRichText } from "../../../../shared/types/TextItem";

import style from "./index.module.css";

interface IProps {
  richText: ITipTapRichText;
  renderProps?: React.HTMLProps<HTMLDivElement>;
  highlightBrackets?: boolean;
  size?: "base" | "small" | "micro";
  className?: string;
}

export const RichTextRender = (props: IProps) => {
  const { richText, renderProps, className } = props;
  const divRef = useRef<HTMLDivElement>(null);

  const size = props.size ?? "base";

  const extensions = useMemo(() => getExtensions({}), []);

  const richTextHTML = useMemo(() => {
    try {
      return parse(
        generateHTML(
          prepareRichTextNodes(richText, {
            highlightBrackets: props.highlightBrackets,
          }),
          extensions
        )
      );
    } catch (e) {
      console.error("Failed to parse " + JSON.stringify(richText), e);
      return <></>;
    }
  }, [richText, props.highlightBrackets, extensions]);

  return (
    <div
      ref={divRef}
      {...renderProps}
      className={classnames(style.RichTextRenderWrapper, renderProps?.className, className, style[size])}
    >
      {richTextHTML}
    </div>
  );
};
