import z from "zod";
import { ZCreatableId, ZObjectId } from "./lib";

import { filterableFields as TextItemFilterableFields } from "./TextItem";

export const ZLibraryComponent = z.object({
  _id: ZObjectId,
  instances: z.array(ZObjectId),
  workspaceId: ZCreatableId,
  name: z.string(),
  apiId: z.string(),
  commentThreads: z.array(ZObjectId),
  folderId: ZObjectId.nullable(),
  assignedAt: z.date().nullable().optional(),
  editedAt: z.date().nullable().optional(),
  editedBy: ZObjectId.nullable().optional(),
  characterLimit: z.number().nullable().optional(),
  sortKey: z.string(),
  ...TextItemFilterableFields,
});
export type ILibraryComponent = z.infer<typeof ZLibraryComponent>;

export const ZCreateTestLibraryComponentsArgs = z.object({
  count: z.number(),
  workspaceId: z.string(),
  projectId: z.string().optional(),
  groupsCount: z.number().optional(),
  blocksCount: z.number().optional(),
  distributeBetweenFolders: z.number().optional(),
  maxInstanceCount: z.number().optional(),
});

export type ICreateTestLibraryComponentsArgs = z.infer<typeof ZCreateTestLibraryComponentsArgs>;

export const ZSearchScoreBreakdown = z.object({
  name: z.string(),
  fullComponent: ZLibraryComponent,
  totalScore: z.number(),
  searchFactors: z.array(
    z.object({
      debugName: z.string(),
      score: z.number(),
      matched: z.boolean(),
    })
  ),
});
export type ISearchScoreBreakdown = z.infer<typeof ZSearchScoreBreakdown>;

export const ZSearchMatcherName = z.enum([
  "Exact name match",
  "Exact text match",
  "Exact tag match",
  "Partial name match",
  "Partial text match",
  "Name autocomplete",
  "Text autocomplete",
]);
export type ISearchMatcherName = z.infer<typeof ZSearchMatcherName>;
