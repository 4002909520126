import { IDittoProject } from "@shared/types/DittoProject";
import { atom, Atom, useAtomValue } from "jotai";
import { selectAtom, unwrap } from "jotai/utils";
import isEqual from "lodash.isequal";
import React, { useCallback, useMemo, useRef } from "react";
import BaseCombobox, { ComboboxOption } from "../BaseCombobox";

type Project = Pick<IDittoProject, "_id" | "name">;

export interface IProps {
  className?: string;
  style?: React.CSSProperties;

  size?: "base" | "small" | "micro";
  selectedProjectId: string | null;
  onChange: (projectId: string | null) => void;
  projectsAtom: Atom<Project[] | Promise<Project[]>>;
  handleRemoveFilter?: () => void;
  autoFocus?: boolean;
}

export function ProjectFilterDropdown(props: IProps) {
  const { selectedProjectId, onChange, handleRemoveFilter, autoFocus, projectsAtom, size } = props;

  const { current: optionsAtom } = useRef(
    atom(async (get) => {
      const projects = await get(projectsAtom);
      return projects.map((project) => ({
        value: project._id,
        label: project.name,
      }));
    })
  );

  const optionSelectedAtom = useMemo(() => {
    const baseAtom = unwrap(
      atom(async (get) => {
        if (!selectedProjectId) return null;
        const options = await get(optionsAtom);
        const found = options.find((option) => option.value === selectedProjectId) ?? null;
        return found ? [found] : [];
      }),
      () => []
    );

    return selectAtom(baseAtom, (option) => option, isEqual);
  }, [optionsAtom, selectedProjectId]);

  const optionSelected = useAtomValue(optionSelectedAtom) ?? [];

  const setSelectedItem = useCallback(
    (options: ComboboxOption[]) => onChange(options[0] ? options[0].value : null),
    [onChange]
  );

  return (
    <BaseCombobox
      size={size}
      hideDownArrow
      optionsAtom={optionsAtom}
      selectedItems={optionSelected}
      setSelectedItems={setSelectedItem}
      placeholder="Choose a project..."
      autoFocus={autoFocus}
    />
  );
}

export default ProjectFilterDropdown;
