import classnames from "classnames";
import { Change, diffWords } from "diff";
import isEqual from "lodash.isequal";
import React, { useMemo } from "react";
import { EMPTY_RICH_TEXT } from "../../../../shared/frontend/constants";
import { ITipTapRichText } from "../../../../shared/types/TextItem";
import { isRichTextStyled } from "../../../../shared/utils/richText";
import { RichTextRender } from "../../molecules/RichTextRender/index";
import Text, { TextSize } from "../Text";
import style from "./index.module.css";

interface IProps {
  size?: TextSize;
  className?: string;
  textBefore: string;
  textAfter: string;
  richTextBefore?: ITipTapRichText | null;
  richTextAfter?: ITipTapRichText | null;
}

/**
 * This component is used to render a diff between two strings, to show what has been edited.
 * To render a rich text diff, pass in richTextBefore (if available) and richTextAfter
 */
export function EditedText({ size, className, textBefore, textAfter, richTextBefore, richTextAfter }: IProps) {
  const plaintextDiff = useMemo(() => {
    if (textBefore != undefined && textAfter != undefined) {
      return diffWords(textBefore, textAfter);
    }
  }, [textBefore, textAfter]);

  const hasRichTextStyling = useMemo(
    () => !!richTextAfter && (isRichTextStyled(richTextBefore) || isRichTextStyled(richTextAfter)),
    [richTextAfter, richTextBefore]
  );

  // If there is a meaningful plaintext diff, render the plaintext diff
  // TODO: Implement this kind of diff with rich text + variable styling
  if (plaintextDiff && plaintextDiff.length > 1) {
    return <PlainTextDiff diff={plaintextDiff!} className={className} size={size} />;
  }

  // If the plaintext hasn't changed but we have rich text, render as rich text
  if (hasRichTextStyling) {
    return (
      <RichTextDiff
        className={className}
        size={size}
        richTextBefore={richTextBefore || EMPTY_RICH_TEXT}
        richTextAfter={richTextAfter!} // This was already checked as part of hasValidRichText but TS doesn't know that
      />
    );
  }

  // Render the (likely unchanged) plaintext
  if (plaintextDiff) {
    return <PlainTextDiff diff={plaintextDiff} className={className} size={size} />;
  }

  return <></>;
}

interface IPlainTextDiffProps {
  diff: Change[];
  className?: string;
  size?: TextSize;
}

function PlainTextDiff({ diff, className, size }: IPlainTextDiffProps) {
  return (
    <div className={className} data-testid="edited-text">
      {diff.map((section, key) => {
        return (
          <Text
            key={key}
            className={classnames({
              [style.textAfter]: section.added,
              [style.textBefore]: section.removed,
            })}
            color={section.removed ? "secondary" : undefined}
            size={size}
            inline
          >
            {section.value}
          </Text>
        );
      })}
    </div>
  );
}

interface RichTextDiffProps {
  className?: string;
  size?: TextSize;
  richTextBefore: ITipTapRichText;
  richTextAfter: ITipTapRichText;
}

function RichTextDiff({ className, size, richTextBefore, richTextAfter }: RichTextDiffProps) {
  const isDiff = useMemo(() => !isEqual(richTextBefore, richTextAfter), [richTextAfter, richTextBefore]);

  const richTextSize = useMemo(() => {
    switch (size) {
      case "base":
      case "small":
      case "micro":
        return size;
      default:
        return undefined;
    }
  }, [size]);

  if (isDiff) {
    return (
      <div className={classnames(style.richDiff, className)}>
        <div className={style.textBefore}>
          <RichTextRender richText={richTextBefore} className={style.inline} size={richTextSize} />
        </div>
        <div className={style.textAfter}>
          <RichTextRender richText={richTextAfter} className={style.inline} size={richTextSize} />
        </div>
      </div>
    );
  }

  return (
    <div className={classnames(style.richDiff, className)}>
      <div>
        <RichTextRender richText={richTextAfter} size={richTextSize} />
      </div>
    </div>
  );
}

export default EditedText;
