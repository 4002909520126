import classNames from "classnames";
import { Atom, useAtomValue } from "jotai";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getBoldedMatchingText from "../../../helpers/getBoldedMatchingText";
import Badge from "../../atoms/Badge";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import style from "./index.module.css";

// Right now, tags are really just strings. At some point I expect this to change -- adding this type as a reminder
// of where that will need to be accounted for.
type Tag = string;

interface IProps {
  selectedTags: Tag[];
  setSelectedTags: (value: Tag[]) => void;
  tagsAtom: Atom<Tag[] | Promise<Tag[]>>;

  autoFocus?: boolean;
  onRemoveFilter?: () => void;
  className?: string;
  triggerClassName?: string;
  style?: React.CSSProperties;
  closeOnSelect?: boolean;
}

export function TagsFilterDropdown(props: IProps) {
  const tags = useAtomValue(props.tagsAtom);
  function onChange(selectedOptions: { value: Tag; label: string }[]) {
    props.setSelectedTags(selectedOptions.map((v) => v.value));
  }

  const formattedSelectedOptions = useMemo(
    () =>
      props.selectedTags?.map((tag) => ({
        value: tag,
        label: tag,
      })),
    [props.selectedTags]
  );

  const formattedAllTags = useMemo(
    () =>
      tags
        ?.map((tag) => ({
          value: tag,
          label: tag,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [tags]
  );

  return (
    <DropdownCombobox
      className={classNames(style.tagsFilterDropdown, props.className)}
      triggerClassName={classNames(style.triggerButton, props.triggerClassName)}
      style={props.style}
      options={formattedAllTags}
      selectedOptions={formattedSelectedOptions}
      onChange={onChange}
      multiple
      TriggerComponent={TriggerComponent}
      OptionComponent={OptionComponent}
      onRemoveFilter={props.onRemoveFilter}
      inputPlaceholder="Choose tags..."
      autoFocus={props.autoFocus}
      closeOnSelect={props.closeOnSelect}
    />
  );
}

interface ITriggerProps {
  selectedOptions: { value: Tag; label: string }[];
}

function TriggerComponent(props: ITriggerProps) {
  const [visibleTags, setVisibleTags] = useState<{ value: Tag; label: string }[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenTagRefs = useRef<HTMLDivElement[]>([]);

  const calculateVisibleTags = useCallback(() => {
    const containerWidth = containerRef.current?.offsetWidth;
    if (!containerWidth || props.selectedOptions.length === 0) {
      setVisibleTags(props.selectedOptions);
      return;
    }

    let sumWidth = 0;
    let numTagsToShow = 0;

    // Account for the "+X tags" element width if we have more than one tag
    const extraTagsElement = hiddenTagRefs.current[props.selectedOptions.length];
    const extraTagsWidth = props.selectedOptions.length > 1 ? extraTagsElement?.offsetWidth || 0 : 0;
    const usableWidth = containerWidth - extraTagsWidth;

    for (let i = 0; i < props.selectedOptions.length; i++) {
      const tagWidth = hiddenTagRefs.current[i]?.offsetWidth;
      if (!tagWidth) continue;

      if (sumWidth + tagWidth > usableWidth) {
        break;
      }

      numTagsToShow++;
      sumWidth += tagWidth;
    }

    setVisibleTags(props.selectedOptions.slice(0, numTagsToShow));
  }, [props.selectedOptions]);

  useEffect(() => {
    calculateVisibleTags();

    const resizeObserver = new ResizeObserver(calculateVisibleTags);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateVisibleTags]);

  return (
    <div className={style.triggerContent} ref={containerRef}>
      {props.selectedOptions.length === 0 && (
        <Text color="secondary" weight="light" size="micro">
          Add tags...
        </Text>
      )}
      {props.selectedOptions.length > 0 && (
        <>
          <div className={style.hiddenMeasurements}>
            {props.selectedOptions.map((tag, idx) => (
              <div
                key={tag.value}
                ref={(el) => {
                  if (el) hiddenTagRefs.current[idx] = el;
                }}
              >
                <Badge color="black" size="xs" borderRadius="xs" className={style.tag}>
                  {tag.label}
                </Badge>
              </div>
            ))}
            <div
              ref={(el) => {
                if (el) hiddenTagRefs.current[props.selectedOptions.length] = el;
              }}
            >
              <Text color="secondary" weight="light" size="micro" className={style.extraTags}>
                +{props.selectedOptions.length} tag{props.selectedOptions.length === 1 ? "" : "s"}
              </Text>
            </div>
          </div>

          <div className={style.selected}>
            {visibleTags.map((tag) => (
              <Badge key={tag.value} color="black" size="xs" borderRadius="xs" className={style.tag}>
                {tag.label}
              </Badge>
            ))}
            {visibleTags.length < props.selectedOptions.length && (
              <Text color="secondary" weight="light" size="micro" className={style.extraTags}>
                +{props.selectedOptions.length - visibleTags.length} tag
                {props.selectedOptions.length - visibleTags.length === 1 ? "" : "s"}
              </Text>
            )}
          </div>
        </>
      )}
    </div>
  );
}

interface IOptionProps {
  option: { value: Tag; label: string };
  query: string;
  selected: boolean;
}

function OptionComponent(props: IOptionProps) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <span>{getBoldedMatchingText(props.option.label, props.query)}</span>
    </Text>
  );
}

export default TagsFilterDropdown;
