import { CharacterLimit } from "@shared/types/RichText";
import { ITextItem, ITextItemStatus } from "@shared/types/TextItem";
import { getMergedTags } from "./getMergedTags";

/**
 * Used for NS metadata panel auto-save values
 */
export const calculateAutoSaveDiffFromState = (
  originalTextItem: ITextItem,
  selectedTextItems: ITextItem[],
  currentTags: string[] | { mixed: true },
  currentStatus: ITextItemStatus | "MIXED",
  currentAssignee: {
    id: string;
    name: string;
  } | null,
  currentNotes: string | null | { mixed: true },
  currentCharacterLimit: CharacterLimit
) => {
  const mergedTags = getMergedTags(selectedTextItems);
  /**
   * Tags are considered to have changed if the tags were set in the input, and either the original
   * value was a merged set, or the new set of tags is different than the user input value.
   */
  const tagsChanged =
    Array.isArray(currentTags) &&
    (!Array.isArray(mergedTags) ||
      JSON.stringify(currentTags.map((t) => t.toLowerCase())) !==
        JSON.stringify(mergedTags.map((t) => t.toLowerCase())));

  return {
    statusChanged: currentStatus !== "MIXED" && currentStatus !== originalTextItem.status,
    assigneeChanged: currentAssignee?.id !== "MIXED" && (currentAssignee?.id || null) !== originalTextItem.assignee,
    tagsChanged,
    notesChanged:
      currentNotes !== null && currentNotes !== (originalTextItem.notes ?? "") && typeof currentNotes === "string",
    characterLimitChanged: currentCharacterLimit !== (originalTextItem.characterLimit ?? null),
  };
};
