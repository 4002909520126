import { z } from "zod";
import { ZObjectId } from "../lib";

// MARK: - Moving Components

export const ZMoveLibraryComponentsAction = z.object({
  componentIds: z.array(ZObjectId),
  folderId: ZObjectId.optional().nullable(),
  before: ZObjectId.optional(),
  after: ZObjectId.optional(),
  moveToStart: z.boolean().optional(),
});

export type IMoveLibraryComponentsAction = z.infer<typeof ZMoveLibraryComponentsAction>;

export const ZMoveLibraryComponentsProps = z.object({
  actions: z.array(ZMoveLibraryComponentsAction),
});

export type IMoveLibraryComponentsProps = z.infer<typeof ZMoveLibraryComponentsProps>;
