import { createTextPatternIterator } from "../lib/text";
import { ActualComponentVariableSchema, VariableData } from "../types/TextItem";

export const VARIABLE_REGEX = /\{\{([a-z0-9_]+)\}\}/gi;

export const forEachVariable = createTextPatternIterator(VARIABLE_REGEX, {
  valueTransformer: (value) => value.replace(/(\{\{|\}\})/g, ""),
});

export const getVariable = <T extends { name: string }>(variableName: string, variables: T[]): T | null => {
  const variable = (variables || []).find((v) => v && v.name === variableName);
  if (!variable) {
    return null;
  }

  return variable;
};

export const getVariablePlaceholder = (variable: VariableData | null) => {
  if (!(variable && variable.data)) {
    return null;
  }

  if (variable.type === "map") {
    return Object.values(variable.data)[0];
  }

  if (variable.type === "list" && Array.isArray(variable.data)) {
    return variable.data[0];
  }

  if (variable.type === "number" || variable.type === "string") {
    return String(variable.data.example || variable.data.fallback) || null;
  }

  if (variable.type === "hyperlink") {
    return variable.data.text || null;
  }

  return null;
};

export const getVariableDefault = (variable: VariableData | null): string | undefined => {
  if (!(variable && variable.data)) {
    return undefined;
  }

  if (variable.type === "number" || variable.type === "string") {
    return variable.data.fallback ? String(variable.data.fallback) : undefined;
  }

  return undefined;
};

export const getVariableICUString = (variable: ActualComponentVariableSchema) => {
  if (!(variable && variable.type)) {
    return null;
  }

  if (variable.type === "number" || variable.type === "string" || variable.type === "hyperlink") {
    return `{${variable.name}}`;
  }

  const values =
    variable.type === "list"
      ? variable.data.map((d) => ({ key: d, value: d }))
      : Object.entries(variable.data).map(([key, value]) => ({ key, value }));
  const valueOther = values[0].value;

  const valueText = values.map(({ key, value }) => `${key} {${value}}`).join(" ");

  return `{${variable.name}, select, ${valueText} other {${valueOther}}}`;
};

export const getVariableDataWithType = (variable: VariableData | null) => {
  if (!variable) return null;

  if (variable.type === "list") {
    return variable.data;
  }

  return {
    ...variable.data,
    __type: variable.type,
  };
};

const _generateVariableText = (
  text: string,
  getValueToAppend: (args: { name: string; start: number; end: number; lastIndex: number }) => string
) => {
  let lastIndex = 0;
  let updatedText = "";
  forEachVariable(text, ({ value: name, start, end }) => {
    updatedText += getValueToAppend({ name, start, end, lastIndex });
    lastIndex = end;
  });

  const remainder = text.substring(lastIndex);
  if (remainder) {
    updatedText += remainder;
  }
  return updatedText;
};

export const generateVariableText = (text: string | null, variables: ActualComponentVariableSchema[]) => {
  if (!text) return "";
  if (!variables?.length) {
    return text;
  }

  return _generateVariableText(text, ({ name, start, end, lastIndex }) => {
    const variable = getVariable(name, variables);
    const variableValue = getVariablePlaceholder(variable);
    if (variableValue) {
      return text.substring(lastIndex, start) + variableValue;
    } else {
      return text.substring(lastIndex, end);
    }
  });
};
