import { useCallback, useState } from "react";

type LocalStorageValue = string | number | object | boolean;

const getInitialValue = <T extends LocalStorageValue>(key: string, initialValue: T) => {
  try {
    const valueFromLocalStorageRaw = localStorage.getItem(key);
    if (!valueFromLocalStorageRaw) return initialValue;
    const valueFromLocalStorage = JSON.parse(valueFromLocalStorageRaw);
    return valueFromLocalStorage || initialValue;
  } catch {
    return initialValue;
  }
};

export default function useBetterLocalStorage<T extends LocalStorageValue>(key: string, initialValue: T) {
  const [_value, _setValue] = useState<T>(() => {
    const value = getInitialValue(key, initialValue);
    localStorage.setItem(key, value);
    return value;
  });

  const setValue = useCallback<typeof _setValue>(
    (valOrFunc) => {
      if (typeof valOrFunc === "function") {
        return _setValue((prev) => {
          const newValue = valOrFunc(prev);
          localStorage.setItem(key, JSON.stringify(newValue));
          return newValue;
        });
      } else {
        const newValue = valOrFunc;
        localStorage.setItem(key, JSON.stringify(newValue));
        return _setValue(valOrFunc);
      }
    },
    [_setValue]
  );

  const remove = useCallback(() => {
    localStorage.removeItem(key);
  }, []);

  return [_value, setValue, remove] as const;
}
