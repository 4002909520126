import { useWorkspace } from "@/store/workspaceContext";
import Breadcrumbs from "@ds/molecules/Breadcrumbs";
import React, { Suspense, useCallback, useMemo } from "react";

import { libraryComponentFoldersListAtom } from "@/stores/ComponentFolders";
import { selectedLibraryFolderIdAtom } from "@/stores/Library";
import { BreadcrumbLinkText, IBreadcrumbLinkProps } from "@ds/atoms/BreadcrumbLink";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import { useAtomValue, useSetAtom } from "jotai";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";

const CRUMB_VALUE_LIBRARY = "__LIBRARY__";
const CRUMB_VALUE_WORKSPACE = "__WORKSPACE__";

export default function LibraryBreadcrumbs() {
  return (
    <Suspense fallback={<React.Fragment />}>
      <LibraryBreadcrumbsInner />
    </Suspense>
  );
}

function LibraryBreadcrumbsInner() {
  const workspace = useWorkspace();

  const selectedLibraryFolderId = useAtomValue(selectedLibraryFolderIdAtom);

  const libraryComponentFolders = useAtomValue(libraryComponentFoldersListAtom);
  const libraryComponentFoldersById = useMemo(
    () =>
      new Map<string | null, ILibraryComponentFolder>(libraryComponentFolders.map((folder) => [folder._id, folder])),
    [libraryComponentFolders]
  );

  const folderCrumbs = useMemo(() => {
    // no crumbs if no selected folder
    if (!selectedLibraryFolderId) return [];

    // no crumbs if selected folder couldn't be found
    const currentFolder = libraryComponentFoldersById.get(selectedLibraryFolderId);
    if (!currentFolder) return [];

    // important to note the name of this variable: only _ancestor_ folder crumbs are included
    // here because a crumb for the current folder is rendered separately
    //
    // this is important to understand so that it's less confusing when reading the code below that indexes on the array
    let ancestorFolderCrumbs: { label: string; value: string }[] = [];

    let ancestorFolder = libraryComponentFoldersById.get(currentFolder.parentId);
    while (ancestorFolder) {
      ancestorFolderCrumbs.unshift({ label: ancestorFolder.name, value: ancestorFolder._id });
      ancestorFolder = libraryComponentFoldersById.get(ancestorFolder.parentId);
    }

    if (ancestorFolderCrumbs.length >= 4) {
      ancestorFolderCrumbs = [
        ancestorFolderCrumbs[0],
        { label: "...", value: ancestorFolderCrumbs[ancestorFolderCrumbs.length - 1].value },
        ...ancestorFolderCrumbs.slice(ancestorFolderCrumbs.length - 1),
      ];
    }

    return ancestorFolderCrumbs;
  }, [libraryComponentFoldersById, selectedLibraryFolderId]);

  const selectedFolder = useMemo(
    () => libraryComponentFoldersById.get(selectedLibraryFolderId ?? null),
    [selectedLibraryFolderId, libraryComponentFoldersById]
  );

  const previousCrumbs = useMemo(() => {
    if (!selectedFolder) return [{ label: workspace.workspaceInfo.name, value: CRUMB_VALUE_WORKSPACE }];
    else
      return [
        { label: workspace.workspaceInfo.name, value: CRUMB_VALUE_WORKSPACE },
        { label: "Library", value: CRUMB_VALUE_LIBRARY },
        ...folderCrumbs,
      ];
  }, [selectedFolder, workspace.workspaceInfo.name, folderCrumbs]);

  const finalCrumb = useMemo(() => (selectedFolder ? selectedFolder.name : "Library"), [selectedFolder]);

  return (
    <Breadcrumbs
      className={style.breadcrumbs}
      previousCrumbs={previousCrumbs}
      finalCrumb={finalCrumb}
      BreadcrumbLink={LibraryBreadcrumbLink}
    />
  );
}

function LibraryBreadcrumbLink(props: Omit<IBreadcrumbLinkProps, "onClick">) {
  const history = useHistory();
  const setSelectedLibraryFolderId = useSetAtom(selectedLibraryFolderIdAtom);

  const handleClick = useCallback(() => {
    // Since the root of the app still routes with React Router, we need to
    // use React Router's history object to make the route change to trigger
    // a proper re-render of the top-level routes. Eventually, when the root
    // of the app also uses Jotai-based routing, this can be another set call to
    // `locationAtom`
    if (props.value === CRUMB_VALUE_WORKSPACE) {
      history.push("/");
      return;
    }

    if (props.value === CRUMB_VALUE_LIBRARY) {
      setSelectedLibraryFolderId(null);
      return;
    }

    setSelectedLibraryFolderId(props.value);
  }, [history, props.value, setSelectedLibraryFolderId]);

  return <BreadcrumbLinkText {...props} handleClick={handleClick} />;
}
