import { client } from "@/http/lib/dittoClient";
import { libraryComponentFolderFamilyAtom, libraryComponentFoldersListAtom } from "@/stores/ComponentFolders";
import { linkComponentActionAtom, unwrappedSuggestedComponentIdsAtom } from "@/stores/ComponentLinkingFlow";
import { libraryComponentFamilyAtom } from "@/stores/Components";
import { derivedSelectedTextItemsAtom } from "@/stores/ProjectSelection";
import { allComponentTagsAtom, projectsAtom, usersByIdAtom } from "@/stores/Workspace";
import { CompactLibraryComponentList, useCompactTextList } from "@ds/organisms/CompactLibraryComponentList";
import { useAtomValue } from "jotai";
import style from "./style.module.css";

export default function LinkComponent() {
  const selectedTextItems = useAtomValue(derivedSelectedTextItemsAtom);

  const props = useCompactTextList({
    clientMethod: client.libraryComponent.searchLibraryComponents,
    suggestedItemIdsAtom: unwrappedSuggestedComponentIdsAtom,
    foldersListAtom: libraryComponentFoldersListAtom,
    selectedTextItems,
    itemActionClickAtom: linkComponentActionAtom,
    actionText: "Link",
    tagsAtom: allComponentTagsAtom,
    listItemFamilyAtom: libraryComponentFamilyAtom,
    listItemFolderFamilyAtom: libraryComponentFolderFamilyAtom,
    libraryComponentFamilyAtom,
    usersByIdAtom,
    projectsAtom,
  });

  return (
    <div className={style.linkComponentContainer}>
      <CompactLibraryComponentList {...props} />
    </div>
  );
}

export function LinkComponentFallback() {
  return (
    <div className={style.linkComponentContainer}>
      <CompactLibraryComponentList.Fallback />
    </div>
  );
}

LinkComponent.Fallback = LinkComponentFallback;
